import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import ModalCenter from '../../Shared/ModalCenter'
import React from 'react'
import { View } from 'react-native'

interface ConfirmCancelSmartPaymentModalProps {
  isModalVisible: boolean
  onPressCancel: () => void
  onPressSubmit: () => void
}

export default function ConfirmCancelSmartPaymentModal({
  isModalVisible,
  onPressCancel,
  onPressSubmit,
}: ConfirmCancelSmartPaymentModalProps) {
  const { width, sm } = useResponsive()
  return (
    <ModalCenter
      title={t('スマート決済の利用をやめますか？')}
      isModalVisible={isModalVisible}
      width={600}
      onClose={onPressCancel}
    >
      <View style={{ padding: 24, rowGap: 24 }}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '300',
            lineHeight: 27,
            textAlign: 'left',
          }}
        >
          {t(
            'スマート決済の利用をやめる場合、それ以外のお支払い方法でお会計をする必要があります。また、一度スマート決済の利用をやめた場合は元に戻すことができません。よろしいですか？'
          )}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 16,
            paddingVertical: 24,
            borderTopWidth: 0.5,
            borderColor: Colors.border,
          }}
        >
          <Button
            mode="outline"
            style={{
              width: width >= sm ? 200 : undefined,
              height: 48,
            }}
            onPress={onPressCancel}
          >
            {t('キャンセル')}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.caution,
              height: 48,
              borderWidth: 0,
            }}
            textStyle={{ color: Colors.white }}
            onPress={onPressSubmit}
          >
            {t('スマート決済をやめる')}
          </Button>
        </View>
      </View>
    </ModalCenter>
  )
}
