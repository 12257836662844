import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { View } from 'react-native'
import {
  rejectionRequestReservation,
  useRestaurantRequestReservation,
} from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'
import { useRestaurantId } from 'modules/useRestaurantId'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DetailModalContent } from '@hello-ai/for_r_app/src/components/Reservation/RequestView/DetailModalContent'
import RejectModal from '@hello-ai/for_r_app/src/components/Reservation/RequestView/RejectModal'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import { useTableSeats } from '@hello-ai/for_r_app/src/models/TableSeat'

function RequestReservationShowPage() {
  const token = useToken()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(restaurantId, {})
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { restaurantRequestReservationId } = useParams<{
    restaurantRequestReservationId: string
  }>()
  const { requestReservation: data } = useRestaurantRequestReservation(
    restaurantId,
    parseInt(restaurantRequestReservationId!)
  )

  const isInPossibleRange =
    data != null
      ? data.start_at === data.start_at_min &&
        data.start_at === data.start_at_max
      : false
  const shouldSkipSeatSelection =
    restaurant?.reservation_book_plan_type === 'entry' && tableSeats.length <= 1

  const onPressApproval = () => {
    const skipSteps = isInPossibleRange ? [1, 3] : [3]
    if (shouldSkipSeatSelection) {
      skipSteps.push(2)
      skipSteps.sort()
    }
    navigate(
      `/restaurants/${restaurantId}/reservations/requests/${restaurantRequestReservationId}/form?skipSteps=${skipSteps.join(
        ','
      )}`
    )
  }

  if (restaurant == null) return null

  // entry プランで席が取得できていない場合は null を返す
  if (restaurant.reservation_book_plan_type === 'entry' && tableSeats == null)
    return null

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <DetailModalContent
          restaurantId={restaurantId}
          data={data}
          isInPossibleRange={isInPossibleRange}
          onPressApproval={onPressApproval}
          onPressRejection={() => setIsModalVisible(true)}
        />
      </View>
      <RejectModal
        isModalVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false)
        }}
        onPressRejection={async ({ cancelReason, otherCancelReason }) => {
          if (data == null || token == null) return
          console.log(cancelReason, otherCancelReason)
          await rejectionRequestReservation(token, restaurantId, data.id, {
            date: dayjs(data.start_at).format('YYYY-MM-DD'),
            cancel_reason:
              cancelReason ||
              t('{{reason}}ため', { reason: otherCancelReason }),
          })
          setIsModalVisible(false)
          navigate(-1)
        }}
      />
    </View>
  )
}

export default RequestReservationShowPage
