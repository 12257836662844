import React, { useCallback, useMemo, useState } from 'react'
import { FlatList, Platform, StyleProp, View, ViewStyle } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import {
  Customer as CustomerModel,
  useCustomersBulk,
  useCustomerSearch,
} from '../../../models/Customer'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import {
  ReservationStepper,
  ReservationStepperBackButton,
  ReservationStepperNextButton,
} from '@hello-ai/ar_shared/src/components/ReservationStepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Customer, SelectedCustomer } from '../../Customers/ReservationCustomer'
import Loading from '../../Shared/Loading'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import {
  ReservationFormState,
  ReservationFormStepProps,
  ReservationRepState,
} from './types'

import ModalCenter from '../../Shared/ModalCenter'
import { displayToastSuccess } from '../../Shared/Toast'
import { useReservationBookRestaurantCourses } from '../../../models/RestaurantCourse'
import produce from 'immer'
import QuantityButton from '../../Shared/QuantityButton'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { RestaurantReservationCourse as RestaurantReservationCourseModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservationCourse'
import { PhoneNumberInput } from '../../Shared/PhoneNumberInput'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RestaurantCourseResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_resource'
import { useAllRestaurantCrewMembers } from '../../..//models/RestaurantCrewMembers'
import { useRestaurantAttributes } from '../../../models/CustomerAttributes'
import { useRestaurant } from '../../../models/Restaurant'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import {
  getDefaultDialCode,
  getPhoneNumber,
} from '../../../modules/phoneNumber'
import { useRestaurantCurrency } from '../../../modules/useRestaurantCurrency'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  createRestaurantCustomer,
  CreateRestaurantCustomerParams,
} from '../../../models/RestaurantCustomer'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'

const emptyComponentPcHeight = 250
const emptyComponentSpHeight = 100

function NumberInput({
  value,
  disabledLeft,
  disabledRight,
  onChangeValue,
  style,
  children,
}: {
  value: number
  disabledLeft?: boolean
  disabledRight?: boolean
  onChangeValue: (value: number) => void
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        style,
      ]}
    >
      <QuantityButton
        disabled={disabledLeft}
        icon={faMinus}
        onPress={() => {
          onChangeValue(value - 1)
        }}
      />
      <View
        style={{
          marginHorizontal: 8,
        }}
      >
        {children}
      </View>
      <QuantityButton
        disabled={disabledRight}
        icon={faPlus}
        onPress={() => {
          onChangeValue(value + 1)
        }}
      />
    </View>
  )
}

function SelectReservationRepModal({
  restaurantId,
  isModalVisible,
  onClose,
  onPressNext,
  state,
}: {
  restaurantId: number
  isModalVisible: boolean
  onClose: () => void
  onPressNext: (value: Partial<ReservationFormState>) => void
  state: ReservationFormState
}) {
  const { width, sm } = useResponsive()
  const [reservationRep, setReservationRep] = useState<
    ReservationRepState | null | undefined
  >(state.reservationRep)
  const { restaurantCrewMembers } = useAllRestaurantCrewMembers(restaurantId)
  const items: SelectItem[] = restaurantCrewMembers
    ? restaurantCrewMembers.map((restaurantCrewMember) => {
        return {
          label: restaurantCrewMember.name,
          value: restaurantCrewMember.id,
        }
      })
    : []
  items.unshift({
    label: t('選択してください'),
    value: null,
  })
  const onPress = () => {
    onClose()
    onPressNext({ reservationRep })
  }
  return (
    <ModalCenter
      isModalVisible={isModalVisible}
      onClose={onClose}
      title={t('予約担当者を選択')}
      width="70%"
    >
      <ShadowBox>
        <FormGroup formLabel={<FormLabel value={t('担当者')} required />}>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <SelectInput
              style={width < sm && { marginLeft: 20 }}
              items={items}
              selectedValue={reservationRep ? reservationRep.id : null}
              setValue={(value) => {
                const businessUser = restaurantCrewMembers?.find(
                  (bu) => bu.id === value
                )
                setReservationRep(
                  businessUser
                    ? {
                        id: businessUser.id,
                        name: businessUser.name,
                      }
                    : null
                )
              }}
            />
          </View>
        </FormGroup>
      </ShadowBox>
      <View
        style={{
          borderTopWidth: 1,
          borderColor: Colors.border,
        }}
      >
        <Button
          style={{
            alignSelf: 'center',
            marginTop: 24,
          }}
          onPress={onPress}
          disabled={reservationRep == null}
        >
          {t('決定して確認に進む')}
        </Button>
      </View>
    </ModalCenter>
  )
}

export function Details({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressGoBack,
  onPressNext,
  additionalModal,
  setAdditionalModal,
  onPressNextForReservationRep,
}: ReservationFormStepProps & { restaurantId: number }) {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null ||
    restaurant.reservation_book_plan_type === 'free' ||
    restaurant.reservation_book_plan_type === 'entry'
  const { width, sm } = useResponsive()
  const [currentPage, setCurrentPage] = useState(1)
  const [firstNameKana, setFirstNameKana] = useFormState('')
  const [lastNameKana, setLastNameKana] = useFormState('')
  const [firstName, setFirstName] = useFormState('')
  const [lastName, setLastName] = useFormState('')
  const [dialCode, setDialCode] = useFormState(getDefaultDialCode())
  const [phoneNumber, setPhoneNumber] = useFormState('')
  const [selectedCustomerIds, setSelectedCustomerIds] = useFormState<
    Array<CustomerModel['id']>
  >(state.selectedCustomerIds)
  const [allergy, setAllergy] = useFormState(state.allergy)
  const [memo, setMemo] = useFormState(state.memo)
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  const { data } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const attributes = useMemo(
    () => data?.filter((e) => e.display_in_list) ?? [],
    [data]
  )

  const isCustomerInfoInputted = useMemo(() => {
    return (
      lastNameKana !== '' ||
      firstNameKana !== '' ||
      lastName !== '' ||
      firstName !== '' ||
      phoneNumber !== ''
    )
  }, [lastNameKana, firstNameKana, lastName, firstName, phoneNumber])

  const [selectedReservationCourses, setSelectedReservationCourses] =
    useFormState<
      Array<{
        id?: RestaurantReservationCourseModel['id'] | null
        partySize: number
        restaurantCourseId: RestaurantCourseResource['id'] | null
        remainingQuantity?: number | null
      }>
    >(() => {
      const selectedReservationCourses = state.selectedReservationCourses
      if (
        selectedReservationCourses.length === 0 ||
        selectedReservationCourses[selectedReservationCourses.length - 1]
          .restaurantCourseId != null
      ) {
        return [
          ...state.selectedReservationCourses,
          {
            id: null,
            partySize: state.adultPartySize + state.childPartySize,
            restaurantCourseId: null,
          },
        ]
      }
      return selectedReservationCourses
    })

  const reservedAt = dayjs(state.dateString, 'YYYY-MM-DD')
    .add(state.startTime, 'seconds')
    .toISOString()
  const { restaurantCourses } = useReservationBookRestaurantCourses(
    restaurantId,
    state.adultPartySize + state.childPartySize,
    reservedAt
  )

  const courseItems = useMemo(() => {
    const items: SelectItem[] = [
      {
        label: t('コースを選択'),
        value: null,
      },
    ]

    if (restaurantCourses != null) {
      items.push(
        ...restaurantCourses.map(({ id, title, price, stock_status }) => ({
          label: `${title} ${formatCurrency(price)}${stock_status?.remaining_quantity != null ? t('（残り在庫：{{quantity}}個）', { quantity: stock_status.remaining_quantity }) : ''}`,
          value: id,
        }))
      )
    }

    return items
  }, [restaurantCourses, formatCurrency])

  const {
    customers: customersSearch,
    mutate: mutateSearch,
    setSearchParams,
    headerData,
  } = useCustomerSearch(restaurantId, currentPage, 10)

  const { customers, mutate } = useCustomersBulk(restaurantId, {
    ids: selectedCustomerIds,
  })

  const onCustomerUpdated = () => {
    mutateSearch()
    mutate()
  }

  const onCustomerAdded = (customerId: CustomerModel['id']) => {
    setSelectedCustomerIds((selectedCustomerIds) =>
      selectedCustomerIds.includes(customerId)
        ? selectedCustomerIds
        : [...selectedCustomerIds, customerId]
    )
    onCustomerUpdated()
    displayToastSuccess(t('新規顧客を登録し予約に追加しました'))
  }

  const onChangeSelectedReservationCourseRestaurantCourseId = (
    index: number,
    value: RestaurantCourseResource['id'] | null
  ) => {
    setSelectedReservationCourses((selectedRestaurantCourses) =>
      produce(selectedRestaurantCourses, (draft) => {
        draft[index].restaurantCourseId = value
        draft[index].remainingQuantity = restaurantCourses?.find(
          (course) => course.id === value
        )?.stock_status?.remaining_quantity
        if (value != null && index === draft.length - 1) {
          draft.push({
            partySize: 0,
            restaurantCourseId: null,
          })
        }
      })
    )
  }

  const onChangeSelectedReservationCoursePartySize = (
    index: number,
    value: number
  ) => {
    setSelectedReservationCourses((selectedRestaurantCourses) =>
      produce(selectedRestaurantCourses, (draft) => {
        draft[index].partySize = value
      })
    )
  }

  const onPressRemoveCustomer = (customerId: CustomerModel['id']) => {
    setSelectedCustomerIds((selectedCustomerIds) =>
      selectedCustomerIds.filter((id) => id !== customerId)
    )
    displayToastSuccess(t('予約からお客様を削除しました'))
  }

  const search = useCallback(() => {
    /**
     * 検索条件を設定すると、検索が実行されます。
     */
    setSearchParams({
      phoneNumber,
      dialCode,
      name: `${lastName} ${firstName}`.trim(),
      reservationLastName: lastNameKana,
      reservationFirstName: firstNameKana,
      lastName,
      firstName,
    })
    setCurrentPage(1)
  }, [
    phoneNumber,
    dialCode,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    setSearchParams,
    setCurrentPage,
  ])

  const addCustomer = async (): Promise<{
    id?: string
    hasError?: boolean
  }> => {
    const params: CreateRestaurantCustomerParams = {
      reservation_first_name: firstNameKana,
      reservation_last_name: lastNameKana,
      phone_number: getPhoneNumber(phoneNumber, dialCode),
      allergy,
    }
    if (firstName !== '') {
      params.first_name = firstName
    }
    if (lastName !== '') {
      params.last_name = lastName
    }

    const { restaurantCustomer, error } = await createRestaurantCustomer(
      token,
      restaurantId,
      params
    )
    if (error != null) {
      return { hasError: true }
    }

    onCustomerAdded(restaurantCustomer!.id)
    return { id: restaurantCustomer!.id }
  }

  const onPressNextWithParams = async () => {
    // コースの人数の初期値を予約人数とする仕様があるが、予約idがnullの場合にもPartySizeを予約人数で送るとエラーになってしまう
    // 予約人数の仕様: https://github.com/hello-ai/auto_reserve/issues/19760
    // Form側で予約idがnullかつPatrySizeが0の場合はリクエストに含めない処理が入っているため、予約idがnullの場合はPartySizeを0にする
    // https://github.com/hello-ai/auto_reserve_front/blob/master/apps/for_r_native/app/screens/Restaurant/Reservations/Form.tsx#L191
    const selectedReservationCoursesValue = selectedReservationCourses.map(
      (selectedReservationCourse) =>
        selectedReservationCourse.restaurantCourseId == null
          ? {
              id: selectedReservationCourse.id,
              partySize: 0,
              restaurantCourseId: null,
              remainingQuantity: undefined,
            }
          : selectedReservationCourse
    )

    const customer =
      isCustomerInfoInputted && selectedCustomerIds.length === 0
        ? await addCustomer()
        : {}

    if (customer.hasError) {
      return
    }

    onPressNext({
      selectedCustomerIds:
        customer.id !== undefined ? [customer.id] : selectedCustomerIds,
      selectedReservationCourses: selectedReservationCoursesValue,
      allergy,
      memo,
    })
  }

  const onPressNextButton = (
    <ReservationStepperNextButton
      onPressNext={onPressNextWithParams}
      text={t('登録内容を確認する')}
    />
  )

  const onPressGoBackButton = (
    <ReservationStepperBackButton
      onPressGoBack={onPressGoBack}
      text={t('席の選択に戻る')}
    />
  )

  return (
    <ReservationStepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={onPressNextWithParams}
      onPressGoBackButton={onPressGoBackButton}
      onPressNextButton={onPressNextButton}
      isDisabledNextPress={false}
    >
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: Colors.white }}
        contentContainerStyle={{
          paddingTop: 24,
          paddingBottom: 60,
          flexGrow: 1,
        }}
      >
        <View
          style={[width > sm && { flexDirection: 'row', marginBottom: 50 }]}
        >
          <View
            style={[
              width > sm && {
                width: '50%',
                borderRightWidth: 1,
                borderColor: Colors.border,
                minHeight: '100%',
              },
            ]}
          >
            <View
              style={[
                width > sm
                  ? { paddingTop: 24, paddingHorizontal: 24 }
                  : { paddingHorizontal: 16 },
              ]}
            >
              <Text
                style={{ fontSize: 18, fontWeight: '600', color: Colors.black }}
              >
                {t('顧客情報を入力する')}
              </Text>
              <View style={{ marginTop: 24, flexGrow: 1 }}>
                <FormGroup
                  mode={'vertical'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 22,
                      }}
                      value={t('名前')}
                    />
                  }
                  containerStyle={{ marginTop: 8 }}
                >
                  <View style={{ flexDirection: 'row', height: 48, gap: 8 }}>
                    <TextInput
                      placeholder={t('せい')}
                      style={{
                        flex: 1,
                        height: 48,
                        width:
                          width < sm && Platform.OS === 'web'
                            ? '50%'
                            : undefined,
                      }}
                      value={lastNameKana}
                      onChangeText={(text) => setLastNameKana(text)}
                      returnKeyType={'done'}
                      onEndEditing={search}
                      onBlur={() => {
                        if (Platform.OS === 'web') {
                          search()
                        }
                      }}
                    />
                    <TextInput
                      placeholder={t('めい')}
                      style={{
                        flex: 1,
                        height: 48,
                        width:
                          width < sm && Platform.OS === 'web'
                            ? '50%'
                            : undefined,
                      }}
                      value={firstNameKana}
                      onChangeText={(text) => setFirstNameKana(text)}
                      returnKeyType={'done'}
                      onEndEditing={search}
                      onBlur={() => {
                        if (Platform.OS === 'web') {
                          search()
                        }
                      }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 8,
                      flexDirection: 'row',
                      height: 48,
                      gap: 8,
                    }}
                  >
                    <TextInput
                      placeholder={t('姓')}
                      style={{
                        flex: 1,
                        height: 48,
                        width:
                          width < sm && Platform.OS === 'web'
                            ? '50%'
                            : undefined,
                      }}
                      value={lastName}
                      onChangeText={(text) => setLastName(text)}
                      returnKeyType={'done'}
                      onEndEditing={search}
                      onBlur={() => {
                        if (Platform.OS === 'web') {
                          search()
                        }
                      }}
                    />
                    <TextInput
                      placeholder={t('名')}
                      style={{
                        flex: 1,
                        height: 48,
                        width:
                          width < sm && Platform.OS === 'web'
                            ? '50%'
                            : undefined,
                      }}
                      value={firstName}
                      onChangeText={(text) => setFirstName(text)}
                      returnKeyType={'done'}
                      onEndEditing={search}
                      onBlur={() => {
                        if (Platform.OS === 'web') {
                          search()
                        }
                      }}
                    />
                  </View>
                </FormGroup>
                <FormGroup
                  mode={'vertical'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 22,
                      }}
                      value={t('電話番号')}
                    />
                  }
                  style={{ marginTop: 24 }}
                  containerStyle={{ marginTop: 8 }}
                >
                  <PhoneNumberInput
                    dialCode={dialCode}
                    onChangeDialCode={setDialCode}
                  >
                    <TextInput
                      style={[{ flex: 1, height: 48 }]}
                      placeholder={t('ハイフンなしで入力')}
                      value={phoneNumber}
                      onChangeText={(text) => setPhoneNumber(text)}
                      keyboardType="phone-pad"
                      textContentType="telephoneNumber"
                      clearButtonMode="always"
                      returnKeyType={'done'}
                      onEndEditing={search}
                      onBlur={() => {
                        if (Platform.OS === 'web') {
                          search()
                        }
                      }}
                    />
                  </PhoneNumberInput>
                </FormGroup>
                <View
                  style={{
                    width: '100%',
                    borderWidth: 0.5,
                    borderColor: Colors.border,
                    marginTop: 16,
                  }}
                />
              </View>
            </View>
            {!isCustomerInfoInputted && selectedCustomerIds.length === 0 && (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height:
                    width < sm
                      ? emptyComponentSpHeight
                      : emptyComponentPcHeight,
                  marginBottom: width < sm ? -24 : 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '600',
                    color: Colors.black,
                    marginBottom: 8,
                  }}
                >
                  入力結果から顧客情報を検索できます
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: '300',
                    color: Colors.black,
                  }}
                >
                  上記の顧客情報を入力してください
                </Text>
              </View>
            )}
            {isCustomerInfoInputted &&
              selectedCustomerIds.length === 0 &&
              restaurant && (
                <View style={{ flex: 1 }}>
                  {customersSearch === undefined ? (
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height:
                          width < sm
                            ? emptyComponentSpHeight
                            : emptyComponentPcHeight,
                        marginBottom: width < sm ? -24 : 0,
                      }}
                    >
                      <Loading />
                    </View>
                  ) : (
                    <View>
                      <FlatList
                        scrollEnabled={false}
                        data={customersSearch}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={{
                          paddingVertical: 16,
                          paddingHorizontal: width > sm ? 24 : 16,
                        }}
                        ListEmptyComponent={() => (
                          <View
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              height:
                                width < sm
                                  ? emptyComponentSpHeight
                                  : emptyComponentPcHeight,
                              marginBottom: width < sm ? -24 : 0,
                            }}
                          >
                            <Text
                              style={{
                                color: Colors.black,
                                fontSize: 16,
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                            >
                              {t('検索結果がありません')} {width < sm && '\n'}
                              {t('新規顧客として登録されます')}
                            </Text>
                          </View>
                        )}
                        renderItem={({ item: customer }) => {
                          return (
                            <View key={customer.id}>
                              <Customer
                                customer={customer}
                                onPressInfo={() => {
                                  if (Platform.OS === 'web') {
                                    navigate(
                                      `/restaurants/${restaurantId}/customers/${customer.id}`
                                    )
                                  } else {
                                    navigation.navigate('CustomerModal', {
                                      customerId: customer.id,
                                    })
                                  }
                                }}
                                restaurant={restaurant}
                                selection={{
                                  selected: selectedCustomerIds.includes(
                                    customer.id
                                  ),
                                  onPress: () => {
                                    setSelectedCustomerIds(
                                      (prevSelectedCustomerIds) => {
                                        if (
                                          prevSelectedCustomerIds.includes(
                                            customer.id
                                          )
                                        ) {
                                          return prevSelectedCustomerIds.filter(
                                            (id) => id !== customer.id
                                          )
                                        }
                                        return [
                                          ...prevSelectedCustomerIds,
                                          customer.id,
                                        ]
                                      }
                                    )
                                    displayToastSuccess(
                                      selectedCustomerIds.includes(customer.id)
                                        ? t('予約からお客様を削除しました')
                                        : t('予約にお客様を追加しました')
                                    )
                                  },
                                }}
                                attributes={attributes}
                              />
                            </View>
                          )
                        }}
                      />
                      {customersSearch.length > 0 && (
                        <View
                          style={{
                            borderTopWidth: 0.5,
                            borderColor: Colors.border,
                            paddingTop: 24,
                            marginHorizontal: width > sm ? 24 : 16,
                            marginBottom: width > sm ? 32 : 24,
                          }}
                        >
                          <Pagination
                            currentPage={currentPage}
                            totalPage={headerData?.totalPages ?? 0}
                            setPage={(page) => setCurrentPage(page)}
                          />
                        </View>
                      )}
                    </View>
                  )}
                </View>
              )}
            {customers === undefined ? (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height:
                    width < sm
                      ? emptyComponentSpHeight
                      : emptyComponentPcHeight,
                  marginBottom: width < sm ? -24 : 0,
                }}
              >
                <Loading />
              </View>
            ) : (
              <FlatList
                data={customers}
                keyExtractor={(item) => item.id}
                contentContainerStyle={{
                  paddingVertical: 16,
                  paddingHorizontal: width > sm ? 24 : 16,
                }}
                renderItem={({ item: customer, index }) => {
                  return (
                    <View
                      key={customer.id}
                      style={{
                        marginTop: index === 0 ? 0 : width < sm ? 16 : 24,
                        padding: 0,
                      }}
                    >
                      <SelectedCustomer
                        customer={customer}
                        attributes={attributes}
                      />
                      <TouchableOpacity
                        onPress={() => onPressRemoveCustomer(customer.id)}
                        style={{
                          position: 'absolute',
                          top: -12,
                          right: width > sm ? -16 : -8,
                          width: 32,
                          height: 32,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          color={Colors.black50}
                          size={26}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                }}
              />
            )}
          </View>
          {width < sm && (
            <View style={{ height: 24, backgroundColor: Colors.bgBlack }} />
          )}
          <View
            style={[
              { paddingVertical: 24 },
              width > sm
                ? { width: '50%', paddingHorizontal: 24 }
                : { paddingHorizontal: 16 },
            ]}
          >
            <View>
              <Text
                style={{ fontSize: 18, fontWeight: '600', color: Colors.black }}
              >
                {t('予約の詳細を入力する')}
              </Text>
              <FormGroup
                mode="vertical"
                style={{ marginTop: 24 }}
                containerStyle={{ marginTop: 0 }}
              >
                {selectedReservationCourses.map(
                  (
                    {
                      partySize,
                      restaurantCourseId: tableCourseId,
                      remainingQuantity,
                    },
                    index
                  ) => {
                    return (
                      <View key={`${index}`}>
                        <FormLabel
                          value={`${t('コース')}${index + 1}`}
                          labelStyle={[
                            { fontSize: 14, fontWeight: '600' },
                            index > 0 && { marginTop: 16 },
                          ]}
                        />
                        <View
                          key={`${index}`}
                          style={[
                            width < sm
                              ? {
                                  flexDirection: 'column',
                                  gap: 12,
                                }
                              : {
                                  flexDirection: 'row',
                                },
                            {
                              marginTop: 8,
                            },
                          ]}
                        >
                          <SelectInput
                            items={courseItems}
                            selectedValue={tableCourseId}
                            setValue={(value) =>
                              onChangeSelectedReservationCourseRestaurantCourseId(
                                index,
                                value
                              )
                            }
                            style={{ height: 48 }}
                          />
                          {width < sm &&
                            remainingQuantity != null &&
                            remainingQuantity < partySize && (
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: Colors.caution,
                                }}
                              >
                                {t(
                                  'コースの在庫数を超える人数が登録されています'
                                )}
                              </Text>
                            )}
                          <NumberInput
                            style={[
                              width < sm
                                ? {
                                    alignSelf: 'flex-end',
                                  }
                                : {
                                    marginLeft: 12,
                                  },
                            ]}
                            value={partySize}
                            disabledLeft={partySize <= 0}
                            onChangeValue={(value) =>
                              onChangeSelectedReservationCoursePartySize(
                                index,
                                value
                              )
                            }
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                backgroundColor: Colors.field,
                                borderRadius: 8,
                                height: 48,
                                paddingHorizontal: 16,
                                minWidth: 74,
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 18,
                                  }}
                                >
                                  {partySize}
                                </Text>
                                <Text
                                  style={{
                                    marginLeft: 6,
                                    fontSize: 14,
                                    color: Colors.secondaryBlack,
                                  }}
                                >
                                  /{state.adultPartySize + state.childPartySize}
                                </Text>
                              </View>
                            </View>
                          </NumberInput>
                        </View>
                        {width >= sm &&
                          remainingQuantity != null &&
                          remainingQuantity < partySize && (
                            <Text
                              style={{
                                fontSize: 14,
                                color: Colors.caution,
                                marginTop: 8,
                              }}
                            >
                              {t(
                                'コースの在庫数を超える人数が登録されています'
                              )}
                            </Text>
                          )}
                      </View>
                    )
                  }
                )}
              </FormGroup>
              <FormGroup
                mode="vertical"
                style={{
                  marginTop: 16,
                }}
                containerStyle={{ marginTop: 8 }}
                formLabel={
                  <FormLabel
                    value={t('アレルギー')}
                    labelStyle={{ fontSize: 14, fontWeight: '600' }}
                  />
                }
              >
                <TextInput
                  value={allergy}
                  style={{ height: 48 }}
                  onChangeText={setAllergy}
                  placeholder={t('アレルギー、苦手なもの')}
                />
              </FormGroup>

              <FormGroup
                mode="vertical"
                style={{
                  marginTop: 16,
                }}
                containerStyle={{ marginTop: 8 }}
                formLabel={
                  <FormLabel
                    value={t('メモ')}
                    labelStyle={{ fontSize: 14, fontWeight: '600' }}
                  />
                }
              >
                <TextInput
                  value={memo ?? ''}
                  onChangeText={setMemo}
                  placeholder={t('メモ')}
                  multiline
                  numberOfLines={3}
                  style={{ minHeight: 108 }}
                />
              </FormGroup>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
      <SelectReservationRepModal
        restaurantId={restaurantId}
        isModalVisible={additionalModal === 'select_reservation_rep'}
        onClose={() => setAdditionalModal?.(null)}
        onPressNext={(v) => onPressNextForReservationRep?.(v)}
        state={state}
      />
    </ReservationStepper>
  )
}
