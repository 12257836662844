import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import React from 'react'
import { View, ViewStyle } from 'react-native'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'

export function ChangeStatusButtons({
  visit_status,
  onPressChangeVisitStatus,
}: {
  visit_status: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations'][number]['visit_status']
  onPressChangeVisitStatus: (
    status: NonNullable<
      ReturnType<
        typeof useRestaurantReservations
      >['restaurantReservations'][number]['visit_status']
    >
  ) => void
}) {
  const buttonStyle: ViewStyle = {
    flex: 1,
    height: 33,
    padding: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <View style={{ flexDirection: 'row', gap: 8, marginTop: 16 }}>
      {visit_status === 'reserved' && (
        <>
          <AsyncButton
            mode="outline"
            variant="secondary"
            style={buttonStyle}
            textStyle={{ fontSize: 12 }}
            onPress={() => onPressChangeVisitStatus('some_visited')}
          >
            {t('一部来店')}
          </AsyncButton>
          <AsyncButton
            mode="outline"
            variant="secondary"
            style={buttonStyle}
            textStyle={{ fontSize: 12 }}
            onPress={() => onPressChangeVisitStatus('all_visited')}
          >
            {t('来店済みにする')}
          </AsyncButton>
        </>
      )}
      {visit_status === 'some_visited' && (
        <AsyncButton
          mode="outline"
          variant="secondary"
          style={buttonStyle}
          textStyle={{ fontSize: 12 }}
          onPress={() => onPressChangeVisitStatus('all_visited')}
        >
          {t('来店済みにする')}
        </AsyncButton>
      )}
      {(visit_status === 'all_visited' || visit_status === 'eaten') && (
        <>
          <AsyncButton
            mode="outline"
            variant="secondary"
            style={buttonStyle}
            textStyle={{ fontSize: 12 }}
            onPress={() => onPressChangeVisitStatus('paid')}
          >
            {t('会計済みにする')}
          </AsyncButton>
          <AsyncButton
            mode="outline"
            variant="secondary"
            style={buttonStyle}
            textStyle={{ fontSize: 12 }}
            onPress={() => onPressChangeVisitStatus('departed')}
          >
            {t('退店済みにする')}
          </AsyncButton>
        </>
      )}
      {visit_status === 'paid' && (
        <AsyncButton
          mode="outline"
          variant="secondary"
          style={buttonStyle}
          textStyle={{ fontSize: 12 }}
          onPress={() => onPressChangeVisitStatus('departed')}
        >
          {t('退店済みにする')}
        </AsyncButton>
      )}
      {/* TODO: no show */}
    </View>
  )
}
