import React, { useMemo, useState } from 'react'
import { View } from 'react-native'

import { SelectRestaurantsInput } from '../Shared/SelectRestaurantsForm'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import invariant from 'invariant'
import {
  SelectInput,
  SelectInputProps,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import times from 'lodash/times'
import { PeriodType, SalesType } from '../../modules/sales'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

const START_YEAR = 2017
export const getYears = (): Array<SelectItem<number>> => {
  const thisYear = dayjs().year()
  const diffYears = thisYear - START_YEAR
  return times(diffYears + 1, (i) => {
    return {
      label: t('{{year}}年', { year: thisYear - i }),
      value: thisYear - i,
    }
  })
}

export const months: Array<SelectItem<number>> = times(12, (i) => {
  const date = dayjs().month(i)
  return {
    label: date.format('MMM'),
    value: i + 1,
  }
})

export const getDays = (
  year: number,
  month: number
): Array<SelectItem<number>> => {
  const target = dayjs(
    `${year}-${month.toString().padStart(2, '0')}-01`,
    'YYYY-MM-DD'
  )

  return times(target.daysInMonth(), (i) => {
    return {
      label: t('{{day}}日', { day: i + 1 }),
      value: i + 1,
    }
  })
}

const salesTypes: Array<SelectItem<SalesType>> = [
  {
    label: t('全ての販売方法'),
    value: 'all',
  },
  {
    label: t('イートイン'),
    value: 'table',
  },
  {
    label: t('オンラインストア'),
    value: 'shop',
  },
]

const periodTypes: Array<SelectItem<PeriodType>> = [
  {
    label: t('月別'),
    value: 'monthly',
  },
  {
    label: t('日別'),
    value: 'daily',
  },
  {
    label: t('時間別'),
    value: 'hourly',
  },
]

export function getSalesTypeLabel(value: SalesType): string {
  const salesType = salesTypes.find((salesType) => salesType.value === value)
  invariant(salesType !== undefined, 'salesType cannot be undefined')
  return salesType.label
}

export function getPeriodTypeLabel(value: string): string {
  const periodType = periodTypes.find(
    (periodType) => periodType.value === value
  )
  invariant(periodType !== undefined, 'periodType cannot be undefined')
  return periodType.label
}

export function WrappedSelectedInput<T>({
  disabled,
  selectedValue,
  setValue,
  items,
}: SelectInputProps<T>) {
  return (
    <SelectInput
      disabled={disabled}
      selectedValue={selectedValue}
      setValue={setValue}
      items={items}
      labelStyle={{
        fontSize: 16,
        fontWeight: '300',
      }}
      style={{
        backgroundColor: 'white',
      }}
      indicatorIcon={
        <FontAwesomeIcon icon={faChevronDown} size={10} color={Colors.black} />
      }
    />
  )
}

export function getDateFormat(date: string, periodType: PeriodType): string {
  switch (periodType) {
    case 'monthly':
      return dayjs(date).format('YYYY/MM')
    case 'daily': {
      const dow = dayjs(date).format('d')
      const label = dayjs(date).format('MM/DD')

      return `${label}(${dayjs.weekdaysShort()[Number(dow)]})`
    }
    case 'hourly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('HH時')
  }
}

export function SalesByPeriodForm({
  restaurants,
  disabled = false,
  onPressApply,
}: {
  restaurants: { id: number; name: string }[]
  disabled: boolean
  onPressApply: (params: {
    restaurantIds: number[]
    periodType: PeriodType
    salesType: SalesType
    year: number
    month: number
    day: number
  }) => void
}) {
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [selectedItems, setSelectedItems] = useFormState<number[]>(
    restaurants.map((r) => r.id)
  )

  const today = dayjs()

  const [selectedYear, setSelectedYear] = useState(today.year())
  const [selectedMonth, setSelectedMonth] = useState(today.month() + 1)
  const [selectedDay, setSelectedDay] = useState(today.date())
  const [selectedPeriodType, setSelectedPeriodType] = useState<PeriodType>(
    periodTypes[0].value
  )
  const [selectedSaleType, setSelectedSaleType] = useState<SalesType>(
    salesTypes[0].value
  )

  const years = useMemo(() => {
    return getYears()
  }, [])

  const days = useMemo(() => {
    const newDays = getDays(selectedYear, selectedMonth)
    const maxDay = Math.max(...newDays.map((day) => day.value))

    // 選択している日付が存在しない月になった場合、選択した月の最大値にしておく
    // eslint-disable-next-line react-compiler/react-compiler
    setSelectedDay((prevState) => {
      if (prevState > maxDay) {
        setSelectedDay(maxDay)
      }
      return prevState
    })
    return newDays
  }, [selectedYear, selectedMonth])

  return (
    <View
      style={{
        flexDirection: 'row',
        gap: 24,
        alignItems: 'center',
      }}
    >
      <View style={{ flex: 1, zIndex: 9999, gap: 16 }}>
        <SelectRestaurantsInput
          isOpenForm={isOpenForm}
          restaurants={restaurants}
          disabled={disabled}
          selectedItems={selectedItems}
          onChangeSelectedItems={setSelectedItems}
          onChangeIsOpenForm={setIsOpenForm}
        />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flex: 1,
            flexWrap: 'wrap',
            zIndex: -1,
          }}
        >
          <View
            style={{
              minWidth: 200,
              marginRight: 16,
              marginBottom: 10,
            }}
          >
            <WrappedSelectedInput
              disabled={disabled}
              selectedValue={selectedSaleType}
              setValue={setSelectedSaleType}
              items={salesTypes}
            />
          </View>
          <View
            style={{
              minWidth: 140,
              marginRight: 16,
              marginBottom: 10,
            }}
          >
            <WrappedSelectedInput
              disabled={disabled}
              selectedValue={selectedPeriodType}
              setValue={setSelectedPeriodType}
              items={periodTypes}
            />
          </View>
          <View
            style={{
              minWidth: 120,
              marginRight: 16,
              marginBottom: 10,
            }}
          >
            <WrappedSelectedInput
              disabled={disabled}
              selectedValue={selectedYear}
              setValue={setSelectedYear}
              items={years}
            />
          </View>
          <View
            style={{
              minWidth: 100,
              marginRight: 16,
              marginBottom: 10,
            }}
          >
            {selectedPeriodType !== 'monthly' && (
              <WrappedSelectedInput
                disabled={disabled}
                selectedValue={selectedMonth}
                setValue={setSelectedMonth}
                items={months}
              />
            )}
          </View>
          <View
            style={{
              minWidth: 80,
              marginRight: 16,
              marginBottom: 10,
            }}
          >
            {selectedPeriodType === 'hourly' && (
              <WrappedSelectedInput
                disabled={disabled}
                selectedValue={selectedDay}
                items={days}
                setValue={setSelectedDay}
              />
            )}
          </View>
        </View>
      </View>
      <TouchableOpacity
        style={{
          width: 110,
          height: 48,
          borderRadius: 24,
          backgroundColor: Colors.white,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disabled={disabled}
        onPress={() => {
          setIsOpenForm(false)
          onPressApply({
            restaurantIds: selectedItems,
            periodType: selectedPeriodType,
            salesType: selectedSaleType,
            year: selectedYear,
            month: selectedMonth,
            day: selectedDay,
          })
        }}
      >
        <Text
          style={{ color: Colors.primary, fontWeight: '600', fontSize: 14 }}
        >
          {t('表示する')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
