import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { RadioIcon } from '@hello-ai/ar_shared/src/components/Radio'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Trans } from 'react-i18next'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'

const DEFAULT_CANCEL_REASONS = [
  { value: t('席の空きがなかったため') },
  { value: t('希望のコースを提供できないため') },
  { value: t('臨時休業のため') },
]

export function RejectModalContent({
  onPressRejection,
}: {
  onPressRejection: (param: {
    cancelReason: string
    otherCancelReason: string
  }) => Promise<void>
}) {
  const [cancelReason, setCancelReason] = useState('')
  const [otherCancelReason, setOtherCancelReason] = useState('')

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <ScrollView contentContainerStyle={{ padding: 24 }}>
        <Text style={{ paddingBottom: 24 }}>
          {t(
            'リクエストを拒否する理由を選択してください。理由がお客様に通知されます。'
          )}
        </Text>
        <View>
          {DEFAULT_CANCEL_REASONS.map((item, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setCancelReason(item.value)
                setOtherCancelReason('')
              }}
              style={{
                paddingBottom: 16,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <RadioIcon selected={cancelReason === item.value} />
              <Text style={{ fontSize: 18, lineHeight: 27, marginLeft: 6 }}>
                {item.value}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={{ marginTop: 8 }}>
          <Text style={{ fontSize: 18, fontWeight: '600', paddingBottom: 8 }}>
            {t('その他')}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {/* eslint-disable ar-i18n/require-translation-ja */}
            <Trans i18nKey={'<0></0><1>ため</1>'}>
              <TextInput
                style={{ flex: 1, marginRight: 8 }}
                placeholder={t('その他の理由')}
                value={otherCancelReason}
                onChangeText={(text) => {
                  setCancelReason('')
                  setOtherCancelReason(text)
                }}
                multiline
              />
              <Text>{'ため'}</Text>
            </Trans>
            {/* eslint-enable ar-i18n/require-translation-ja */}
          </View>
        </View>
      </ScrollView>
      <View>
        <Divider />
        <View
          style={{
            paddingVertical: 24,
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <AsyncButton
            mode="outline"
            variant="danger"
            onPress={async () => {
              await onPressRejection({ cancelReason, otherCancelReason })
            }}
          >
            {t('リクエスト拒否を確定する')}
          </AsyncButton>
        </View>
      </View>
    </View>
  )
}
