import React from 'react'
import { View, TextStyle } from 'react-native'
import { SyncFailedReservation } from '../../../../types/ForR/SiteControllerSeat'
import dayjs from '../../../../modules/dayjs'
import { Text } from '../../../Text'
import { t } from '../../../../modules/i18n'
import { Colors } from '../../../../constants/Colors'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
} from '../../../../types/ForR/GourmetSiteSetting'
import { FontAwesomeIcon } from '../../../FontAwesomeIcon'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import AsyncButton from '../../../AsyncButton'

function getActionLabel(failedReservation: SyncFailedReservation) {
  switch (failedReservation.action) {
    case 'create':
      return t('新規作成')
    case 'update':
      return t('予約更新')
    case 'cancel':
      return t('キャンセル')
    default:
      return ''
  }
}

const BaseTextStyle: TextStyle = {
  width: 100,
  fontSize: 10,
  fontWeight: '600',
  lineHeight: 10,
  textAlign: 'center',
  paddingVertical: 2,
  borderRadius: 2,
}
const AutoReserveTextStyle = {
  color: Colors.primary,
  borderColor: Colors.primary,
  borderWidth: 1,
  paddingVertical: 2,
}

const AutoReserveLabel = 'Respo'

function ReservedRelationProviderView({
  failedReservation,
}: {
  failedReservation: SyncFailedReservation
}) {
  return (
    <View style={{ flexDirection: 'row', columnGap: 16, alignItems: 'center' }}>
      <Text
        style={[
          BaseTextStyle,
          failedReservation.from === 'autoreserve'
            ? AutoReserveTextStyle
            : GOURMET_SITE_PROVIDER_TEXT_STYLE[failedReservation.from],
        ]}
      >
        {failedReservation.from === 'autoreserve'
          ? AutoReserveLabel
          : GOURMET_SITE_PROVIDER_SOURCE[failedReservation.from].label}
      </Text>
      <FontAwesomeIcon icon={faArrowRight} color={Colors.black60} size={16} />
      <Text
        style={[
          BaseTextStyle,
          failedReservation.to === 'autoreserve'
            ? AutoReserveTextStyle
            : GOURMET_SITE_PROVIDER_TEXT_STYLE[failedReservation.to],
        ]}
      >
        {failedReservation.to === 'autoreserve'
          ? AutoReserveLabel
          : GOURMET_SITE_PROVIDER_SOURCE[failedReservation.to].label}
      </Text>
    </View>
  )
}
interface FailedListItemProps {
  index: number
  failedReservation: SyncFailedReservation
  onPressDelete: () => Promise<void>
}

export default function FailedListItem({
  index,
  failedReservation,
  onPressDelete,
}: FailedListItemProps) {
  return (
    <View
      style={[
        index % 2 !== 0 && {
          borderTopWidth: 0.6,
          borderBottomWidth: 0.6,
        },
        {
          rowGap: 8,
          borderColor: Colors.border,
          paddingVertical: 16,
        },
      ]}
    >
      <View style={{ flexDirection: 'row', columnGap: 16 }}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            lineHeight: 27,
            color: Colors.black,
            flex: 1,
          }}
        >
          {t('{{reserved_at}} {{count}}名 {{seat_name}}', {
            ...failedReservation,
            count: failedReservation.party_size,
            reserved_at: dayjs(failedReservation.reserved_at)
              .tz()
              .format(t('YYYY年MM月DD日 HH:mm')),
          })}
        </Text>
        <View
          style={{
            width: 90,
            backgroundColor: Colors.cautionBg,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              color: Colors.caution,
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
            }}
          >
            {failedReservation.recovery_status_translated}
          </Text>
        </View>
        <View
          style={{
            width: 90,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              width: 90,
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              color: Colors.black,
            }}
          >
            {getActionLabel(failedReservation)}
          </Text>
        </View>
      </View>
      <View
        style={{
          padding: 16,
          backgroundColor: Colors.black6,
          borderRadius: 4,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={{ flex: 1 }}>
          <ReservedRelationProviderView failedReservation={failedReservation} />
          <Text style={{ fontSize: 14, fontWeight: '300', lineHeight: 21 }}>
            {failedReservation.error_reason_translated}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '300',
              lineHeight: 18,
              textAlign: 'left',
              color: Colors.black60,
            }}
          >
            {t('発生日時    ：{{datetime}}', {
              datetime: dayjs(failedReservation.created_at)
                .tz()
                .format(t('YYYY年MM月DD日(ddd) HH:mm')),
            })}
          </Text>
        </View>
        <View>
          <AsyncButton
            mode="outline"
            variant="danger-secondary"
            style={{
              paddingHorizontal: 16,
              paddingVertical: 6,
            }}
            textStyle={{
              fontSize: 14,
            }}
            onPress={onPressDelete}
          >
            {t('連携エラーを無視')}
          </AsyncButton>
        </View>
      </View>
    </View>
  )
}
