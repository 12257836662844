import { Text } from '@hello-ai/ar_shared/src/components/Text'
import React, { useState } from 'react'
import { View, Platform, StyleProp, ViewStyle, ScrollView } from 'react-native'
import {
  SmartPayment,
  useRestaurantSmartPaymentHistory,
} from '../../models/SmartPayment'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { YearMonthPicker } from '../Shared/YearMonthPicker'

const DATE_FORMAT = t('YYYY年M月')

interface SummaryTileProps {
  title: string
  value: string
  unit: string
  unitPlace: 'left' | 'right'
}
function SummaryTile(props: SummaryTileProps) {
  const { width, sm } = useResponsive()
  const UnitComponent = (
    <Text
      style={{
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 24,
      }}
    >
      {props.unit}
    </Text>
  )
  return (
    <View
      style={{
        flex: 1,
        flexDirection: width >= sm ? 'row' : 'column',
        alignItems: 'center',
        backgroundColor: Colors.white,
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: Colors.border,
        paddingVertical: width >= sm ? 24 : 8,
        paddingHorizontal: 16,
      }}
    >
      <Text
        style={{ flexGrow: 1, fontSize: 18, fontWeight: '600', lineHeight: 27 }}
      >
        {props.title}
      </Text>
      <View
        style={{ flexDirection: 'row', columnGap: 4, alignItems: 'flex-end' }}
      >
        {props.unitPlace === 'left' && UnitComponent}
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            lineHeight: 27,
            marginRight: 4,
          }}
        >
          {props.value}
        </Text>
        {props.unitPlace === 'right' && UnitComponent}
      </View>
    </View>
  )
}

interface TableItemProps {
  smartPayment: SmartPayment
  style?: StyleProp<ViewStyle>
  onPress: (smartPayment: SmartPayment) => void
}

function TableItemForPC({ smartPayment, style, onPress }: TableItemProps) {
  return (
    <TouchableOpacity
      onPress={() => onPress(smartPayment)}
      key={smartPayment.id}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        style,
      ]}
    >
      <View style={{ width: 160 }}>
        <Text style={{ fontWeight: '300', fontSize: 16, lineHeight: 24 }}>
          {dayjs(smartPayment.paid_at).format('YYYY/MM/DD HH:mm')}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ fontWeight: '300', fontSize: 16, lineHeight: 24 }}>
          {smartPayment.reservation.reservation_courses
            .map((course) => course.restaurant_course.title)
            .join('\n')}
        </Text>
      </View>
      <View style={{ width: 160 }}>
        <Text style={{ fontWeight: '300', fontSize: 16, lineHeight: 24 }}>
          {smartPayment.reservation.customers
            .map(
              (customer) =>
                `${customer.reservation_last_name} ${customer.reservation_first_name}`
            )
            .join(',')}
        </Text>
      </View>
      <View style={{ width: 100 }}>
        <Text
          style={{
            fontWeight: '300',
            fontSize: 16,
            lineHeight: 24,
            textAlign: 'right',
          }}
        >
          ¥{numberWithDelimiter(smartPayment.amount)}
        </Text>
      </View>
      <View style={{ marginLeft: 16 }}>
        <FontAwesomeIcon icon={faChevronRight} size={16} />
      </View>
    </TouchableOpacity>
  )
}

function TableItemForSP({ smartPayment, style, onPress }: TableItemProps) {
  return (
    <TouchableOpacity
      onPress={() => onPress(smartPayment)}
      key={smartPayment.id}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: 16,
        },
        style,
      ]}
    >
      <View>
        <View style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontWeight: '300',
              fontSize: 18,
              lineHeight: 27,
            }}
          >
            {smartPayment.reservation.reservation_courses
              .map((course) => course.restaurant_course.title)
              .join('\n')}
          </Text>
        </View>
        <View>
          {[
            {
              label: t('決済日付'),
              value: dayjs(smartPayment.paid_at).format('YYYY/MM/DD HH:mm'),
            },
            {
              label: t('予約者名'),
              value: smartPayment.reservation.customers
                .map(
                  (customer) =>
                    `${customer.reservation_last_name} ${customer.reservation_first_name}`
                )
                .join(','),
            },
            {
              label: t('金額'),
              value: `¥${numberWithDelimiter(smartPayment.amount)}`,
            },
          ].map((item, index) => (
            <View key={index} style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '600',
                  lineHeight: 24,
                  width: 80,
                  marginRight: 16,
                }}
              >
                {item.label}
              </Text>
              <Text style={{ fontSize: 14, fontWeight: '300', lineHeight: 24 }}>
                {item.value}
              </Text>
            </View>
          ))}
        </View>
      </View>
      <FontAwesomeIcon icon={faChevronRight} size={16} />
    </TouchableOpacity>
  )
}

interface SmartPaymentHistoryViewProps {
  restaurantId: number
}

export default function SmartPaymentHistoryView({
  restaurantId,
}: SmartPaymentHistoryViewProps) {
  const { width, sm } = useResponsive()
  const [params, setParams] = useFormState({
    target_date: dayjs(),
  })
  const [searchParams, setSearchParams] = useState(params)
  const { data, pagination } = useRestaurantSmartPaymentHistory(
    restaurantId,
    searchParams
  )
  const navigate = useNavigate()
  const navigation = useNavigation()

  const onPressSmartPaymentHistoryItem = (
    item: NonNullable<(typeof data)['smart_payments']>[0]
  ) => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${item.reservation.uuid}`
      )
    } else {
      navigation.navigate('ReservationsShow', {
        restaurantReservationId: item.reservation.uuid,
      })
    }
  }

  return (
    <View style={{ flex: 1, padding: width >= sm ? 48 : 24 }}>
      <View
        style={{
          flexDirection: 'row',
          columnGap: 16,
          marginBottom: width >= sm ? 32 : 24,
        }}
      >
        <View style={{ flex: 1 }}>
          <YearMonthPicker
            value={params.target_date}
            onChange={(value) => {
              setParams({ target_date: value })
            }}
            style={{
              backgroundColor: Colors.white,
            }}
            renderLabel={(value) => (
              <Text>{value.tz().format(DATE_FORMAT)}</Text>
            )}
          />
        </View>
        <Button
          variant="secondary"
          onPress={() => {
            setSearchParams(params)
          }}
          style={{ height: 48, borderRadius: 24 }}
          textStyle={{ fontWeight: '600' }}
        >
          {t('表示する')}
        </Button>
      </View>
      <View style={{ marginBottom: width >= sm ? 16 : 8 }}>
        <Text
          style={{ fontSize: 22, fontWeight: '600', lineHeight: 33 }}
        >{`${params.target_date.tz().format(DATE_FORMAT)}`}</Text>
      </View>
      <View style={{ flexDirection: 'row', columnGap: 8, marginBottom: 8 }}>
        <SummaryTile
          title={t('合計金額')}
          value={numberWithDelimiter(data?.amount ?? 0)}
          unit={'¥'}
          unitPlace="left"
        />
        <SummaryTile
          title={t('決済回数')}
          value={numberWithDelimiter(data?.smart_payments?.length ?? 0)}
          unit={t('回')}
          unitPlace="right"
        />
      </View>
      <ScrollView
        contentContainerStyle={{
          backgroundColor: Colors.white,
          borderRadius: 8,
        }}
      >
        {width >= sm && (
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 24,
              paddingVertical: 16,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 21,
                width: 160,
              }}
            >
              {t('決済日付')}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 21,
                flex: 1,
              }}
            >
              {t('コース')}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 21,
                width: 160,
              }}
            >
              {t('予約者名')}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 21,
                width: 100,
                textAlign: 'right',
                marginRight: 32,
              }}
            >
              {t('金額')}
            </Text>
          </View>
        )}
        {data?.smart_payments?.map((smartPayment, index, array) => {
          const Component = width >= sm ? TableItemForPC : TableItemForSP
          return (
            <View
              key={smartPayment.id}
              style={{
                paddingHorizontal: width >= sm ? 24 : 16,
              }}
            >
              <View
                style={[
                  { paddingVertical: width >= sm ? 20 : 16 },
                  array.length - 1 !== index && {
                    borderColor: Colors.border,
                    borderBottomWidth: 0.5,
                  },
                ]}
              >
                <Component
                  smartPayment={smartPayment}
                  onPress={onPressSmartPaymentHistoryItem}
                />
              </View>
            </View>
          )
        })}
        <View
          style={{
            paddingVertical: 16,
            borderColor: Colors.border,
            borderTopWidth: 0.5,
          }}
        >
          <Pagination
            currentPage={pagination.currentPage ?? 1}
            totalPage={pagination.totalPages ?? 1}
            setPage={pagination.setPage}
          />
        </View>
      </ScrollView>
    </View>
  )
}
