import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { View, TouchableOpacity, Platform } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { RadioIcon } from '@hello-ai/ar_shared/src/components/Radio'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { ResponsiveProvider } from '@hello-ai/ar_shared/src/modules/useResponsive'
import SelectDateTimeInput from '@hello-ai/for_r_app/src/components/Shared/SelectDateTimeInput'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons/faTrashCan'
import {
  createBroadcastMessage,
  deleteBroadcastMessage,
  updateBroadcastMessage,
  useBroadcastMessage,
  useBroadcastNumberOfCustomers,
} from '../../../models/BroadcastMessage'
import {
  MessageContent,
  RequestBroadcastMessageData,
} from '@hello-ai/ar_shared/src/types/ForR/BroadcastMessage'
import {
  APP_MESSAGE_BUTTON_STYLE,
  ButtonMessageItem,
  MessageItem,
  PreviewData,
} from '@hello-ai/for_r_app/src/components/Message/Broadcast/MessagePreviewContent'
import { faText } from '@fortawesome/pro-solid-svg-icons/faText'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { selectImageFromMediaLibrary } from '@hello-ai/ar_shared/src/components/SelectImage'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import ConfirmSubmitModal from './ConfirmSubmitModal'
import {
  ModalCenter,
  ModalHeader,
} from '@hello-ai/ar_shared/src/components/ModalCenter'

function MessageContentImage({
  value,
  onPressDeleteIcon,
}: {
  value: string
  onPressDeleteIcon: () => void
}) {
  return (
    <View style={{ position: 'relative' }}>
      <Image
        contentFit="cover"
        style={{
          width: 100,
          height: 100,
          position: 'absolute',
          top: 10,
          left: 10,
        }}
        source={{ uri: value }}
      />
      <TouchableOpacity
        style={{
          position: 'absolute',
          left: 105,
          top: 5,
          backgroundColor: 'white',
          borderRadius: 10,
        }}
        onPress={onPressDeleteIcon}
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          size={20}
          color={Colors.secondaryBlack}
        />
      </TouchableOpacity>
    </View>
  )
}

const MAIL_BUTTON_STYLE = { flex: 1, minWidth: 100 }

function ButtonCreateMessageFormModal({
  isModalVisible,
  defaultValue,
  onClose,
  onSubmit,
}: {
  isModalVisible: boolean
  defaultValue: ButtonMessageItem['value']
  onClose: () => void
  onSubmit: (newValue: NonNullable<ButtonMessageItem['value']>) => void
}) {
  const [newValue, setNewValue] = useState(defaultValue)

  useEffect(() => {
    setNewValue(defaultValue)
  }, [defaultValue])

  return (
    <ModalCenter
      style={{ width: 600 }}
      isVisible={isModalVisible}
      onClose={onClose}
    >
      <View style={{ backgroundColor: Colors.white }}>
        <ModalHeader onClose={onClose}>{t('ボタンを作成')}</ModalHeader>
        <View
          style={{
            paddingVertical: 32,
            paddingHorizontal: 24,
            borderBottomWidth: 1,
            borderColor: Colors.border,
          }}
        >
          <View style={{ flexDirection: 'row', marginBottom: 40 }}>
            <Button variant="secondary" style={APP_MESSAGE_BUTTON_STYLE}>
              {newValue?.text}
            </Button>
            <View
              style={{
                borderWidth: 1,
                borderColor: Colors.border,
                height: '90%',
                alignSelf: 'center',
                marginHorizontal: 36,
              }}
            />
            <Button style={MAIL_BUTTON_STYLE}>{newValue?.text}</Button>
          </View>

          <FormGroup
            style={{ marginBottom: 24 }}
            formLabel={<FormLabel value={t('ボタンテキスト')} />}
          >
            <TextInput
              value={newValue?.text ?? ''}
              onChangeText={(text) =>
                setNewValue((prev) => {
                  if (prev == null) {
                    return { text, url: null }
                  }
                  return { ...prev, text }
                })
              }
              style={{ marginBottom: 8 }}
            />
            <Text style={{ color: Colors.black60 }}>
              {t('12文字以内で入力してください')}
            </Text>
          </FormGroup>
          <FormGroup formLabel={<FormLabel value="URL" />}>
            <TextInput
              keyboardType="url"
              value={newValue?.url ?? ''}
              onChangeText={(url) =>
                setNewValue((prev) => {
                  if (prev == null) {
                    return { text: null, url }
                  }
                  return { ...prev, url }
                })
              }
              placeholder="https://"
            />
          </FormGroup>
        </View>

        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 24,
          }}
        >
          <Button
            onPress={() => {
              if (newValue == null) return
              onSubmit(newValue)
            }}
          >
            {t('保存する')}
          </Button>
        </View>
      </View>
    </ModalCenter>
  )
}

interface MessageContentInputProps {
  messageContent: MessageItem
  onChangeMessageContent: (newMessageContent: MessageItem) => void
}

function MessageContentInput({
  messageContent,
  onChangeMessageContent,
}: MessageContentInputProps) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <View
      style={{ borderColor: Colors.border, borderWidth: 1, borderRadius: 4 }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 18,
          paddingVertical: 12,
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        }}
      >
        <TouchableOpacity
          onPress={() => onChangeMessageContent({ type: 'text', value: '' })}
        >
          <FontAwesomeIcon
            icon={faText}
            size={22}
            color={
              messageContent.type === 'text'
                ? Colors.primary
                : Colors.textDisabled
            }
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={{ marginHorizontal: 18 }}
          onPress={() => onChangeMessageContent({ type: 'photo', value: '' })}
        >
          <FontAwesomeIcon
            icon={faImage}
            size={22}
            color={
              messageContent.type === 'photo'
                ? Colors.primary
                : Colors.textDisabled
            }
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            onChangeMessageContent({
              type: 'button',
              value: null,
            })
          }
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            size={22}
            color={
              messageContent.type === 'button'
                ? Colors.primary
                : Colors.textDisabled
            }
          />
        </TouchableOpacity>
      </View>
      <View style={{ height: 120, backgroundColor: Colors.field }}>
        {messageContent.type === 'text' && (
          <TextInput
            style={{ borderRadius: 0, height: '100%' }}
            placeholder={t('メッセージを入力')}
            multiline
            value={messageContent.value}
            onChangeText={(value) => {
              onChangeMessageContent({ type: 'text', value })
            }}
          />
        )}
        {messageContent.type === 'photo' &&
          (messageContent.value === '' ? (
            <Button
              mode="text"
              height="100%"
              onPress={async () => {
                const value = await selectImageFromMediaLibrary()
                if (value == null) return
                onChangeMessageContent({ type: 'photo', value })
              }}
            >
              {t('画像をアップロード')}
            </Button>
          ) : (
            <MessageContentImage
              value={messageContent.value}
              onPressDeleteIcon={() => {
                onChangeMessageContent({ type: 'photo', value: '' })
              }}
            />
          ))}
        {messageContent.type === 'button' && (
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            {messageContent.value === null ? (
              <Button mode="text" onPress={() => setIsModalVisible(true)}>
                {t('ボタンを作成')}
              </Button>
            ) : (
              <View
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignSelf: 'center',
                    marginBottom: 16,
                    paddingHorizontal: 16,
                  }}
                >
                  <Button variant="secondary" style={APP_MESSAGE_BUTTON_STYLE}>
                    {messageContent.value.text}
                  </Button>
                  <View
                    style={{
                      width: 1,
                      height: '80%',
                      borderColor: Colors.border,
                      borderWidth: 1,
                      marginHorizontal: 18,
                      alignSelf: 'center',
                    }}
                  />
                  <Button style={MAIL_BUTTON_STYLE}>
                    {messageContent.value.text}
                  </Button>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    mode="text"
                    onPress={() => {
                      setIsModalVisible(true)
                    }}
                    style={{
                      marginRight: 24,
                      alignSelf: 'flex-start',
                      paddingHorizontal: 0,
                    }}
                  >
                    {t('編集')}
                  </Button>
                  <Button
                    mode="text"
                    onPress={() => {
                      onChangeMessageContent({
                        type: 'button',
                        value: null,
                      })
                    }}
                    style={{ alignSelf: 'flex-start', paddingHorizontal: 0 }}
                  >
                    {t('削除')}
                  </Button>
                </View>
              </View>
            )}
            <ButtonCreateMessageFormModal
              isModalVisible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              defaultValue={messageContent.value}
              onSubmit={(value) => {
                onChangeMessageContent({ type: 'button', value })
                setIsModalVisible(false)
              }}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const FormGroupStyle: Parameters<typeof FormGroup>[0]['style'] = {
  paddingVertical: 12,
}

interface IFormContext {
  downloadImageAsBase64String: (
    url: string
  ) => Promise<{ error?: any; data?: string }>
  showPreview: (previewData: PreviewData) => void
}
export const FormContext = createContext<IFormContext | undefined>(undefined)
export const useFormContext = () => {
  const context = useContext(FormContext)
  if (context == null) {
    throw new Error('FormContext is not provided')
  }
  return context
}

export const convertImageWithinMessageList = (
  list: MessageItem[],
  downloadImageAsBase64String: IFormContext['downloadImageAsBase64String']
) =>
  Promise.all(
    list.map(async (i): Promise<RequestBroadcastMessageData['contents'][0]> => {
      let image = null
      if (i.type === 'photo') {
        if (i.value.startsWith('data:image')) {
          image = i.value
        } else {
          const { data } = await downloadImageAsBase64String(i.value)
          if (data != null) {
            image = data
          }
        }
      }
      return {
        body: i.type === 'text' ? i.value : '',
        image: image ?? '',
        button: i.type === 'button' ? (i.value as any) : undefined,
      }
    })
  )

const initBroadcastMessageForm = (original: MessageContent[]): MessageItem[] =>
  original.map((i) => {
    if (i.image !== null) {
      return {
        type: 'photo',
        value: i.image.url_resized,
      }
    }
    if (i.button != null) {
      return {
        type: 'button',
        value: i.button,
      }
    }
    return {
      type: 'text',
      value: i.body,
    }
  })

interface BroadcastFormProps {
  restaurantId: number
  broadcastMessageId?: string
}

export default function BroadcastForm({
  restaurantId,
  broadcastMessageId,
}: BroadcastFormProps) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()
  const { broadcastMessage } = useBroadcastMessage(
    restaurantId,
    broadcastMessageId
  )

  const { numberOfCustomers } = useBroadcastNumberOfCustomers(restaurantId)

  const [dateTime, setDateTime] = useFormState(
    dayjs(broadcastMessage?.delivery_at)
  )
  const [subject, setSubject] = useFormState(broadcastMessage?.subject ?? '')
  const [formValues, setFormValues] = useFormState<MessageItem[]>(
    broadcastMessage != null
      ? initBroadcastMessageForm(broadcastMessage.message_contents)
      : []
  )
  const [finished, setFinished] = useState(false)
  const [isImmediately, setIsImmediately] = useFormState(
    !!(broadcastMessage != null && broadcastMessage.delivery_at == null)
  )
  const [isConfirmSubmitVisible, setConfirmSubmitVisible] = useState(false)
  const isWeb = Platform.OS === 'web'

  const isEditMode = broadcastMessageId != null

  const formValuesErrors = useMemo(() => {
    if (formValues.length === 0) return []
    return formValues.map((item, index, array) => {
      if (item.type === 'button') {
        if (index > 0 && array[index - 1]?.type !== 'button') return null
        return {
          message: t('ボタンの前にはテキストまたは画像が必要です。'),
        }
      }
      return null
    })
  }, [formValues])

  const { downloadImageAsBase64String, showPreview } = useFormContext()

  const doBroadcast = async () => {
    if (token == null) return
    setFinished(false)

    try {
      const data = {
        subject,
        delivery_at: isImmediately ? null : dateTime.format('YYYY-MM-DD HH:mm'),
        contents: await convertImageWithinMessageList(
          formValues,
          downloadImageAsBase64String
        ),
      }
      if (isEditMode) {
        const { error } = await updateBroadcastMessage(
          token,
          restaurantId,
          broadcastMessageId,
          data
        )
        if (error != null) {
          onError(error)
          return
        }
      } else {
        const { error } = await createBroadcastMessage(
          token,
          restaurantId,
          data
        )
        if (error != null) {
          onError(error)
          return
        }
      }
      setFinished(true)
    } catch (error) {
      onError(error)
    } finally {
      setConfirmSubmitVisible(false)
    }
  }

  const onPressSubmit = () => {
    setConfirmSubmitVisible(true)
  }

  const goBack = () => {
    if (isWeb) {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  const onPressDelete = async () => {
    if (broadcastMessage == null) return
    const { error } = await deleteBroadcastMessage(
      restaurantId,
      broadcastMessage.id
    )
    if (error != null) {
      onError(error)
      return
    }
    goBack()
  }

  const onPressPreview = async () => {
    const previewData = {
      delivery_at: isImmediately ? null : dateTime.format('YYYY-MM-DD HH:mm'),
      contents: formValues,
    }
    showPreview(previewData)
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <KeyboardAwareScrollView
        contentContainerStyle={{ paddingHorizontal: 48, paddingTop: 24 }}
      >
        <View
          style={{ backgroundColor: 'white', padding: 32, marginBottom: 48 }}
        >
          <ResponsiveProvider>
            <FormGroup
              formLabel={<FormLabel value={t('配信対象')} />}
              mode="inline"
              style={FormGroupStyle}
            >
              <Text>
                {t('全ての顧客（{{text}}人）', { text: numberOfCustomers })}
              </Text>
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value={t('配信日時')} />}
              mode="inline"
              style={FormGroupStyle}
            >
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 24,
                }}
                onPress={() => setIsImmediately(true)}
              >
                <RadioIcon selected={isImmediately} />
                <Text style={{ marginLeft: 6 }}>{t('今すぐ')}</Text>
              </TouchableOpacity>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 60,
                  }}
                  onPress={() => {
                    setIsImmediately(false)
                  }}
                >
                  <RadioIcon selected={!isImmediately} />
                  <Text style={{ marginLeft: 6 }}>{t('予約')}</Text>
                </TouchableOpacity>
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <SelectDateTimeInput
                    mode="date"
                    dateTime={dateTime}
                    onChangeDateTime={(day) => {
                      setIsImmediately(false)
                      setDateTime(day)
                    }}
                    style={{ marginRight: 15, flex: 1 }}
                    // eslint-disable-next-line ar-i18n/require-translation-ja
                    customFormatLabel={(_, date) => date.format('YYYY年M月D日')}
                    showIndicatorIcon
                  />
                  <SelectDateTimeInput
                    mode="time"
                    dateTime={dateTime}
                    style={{ flex: 1 }}
                    onChangeDateTime={(day) => {
                      setIsImmediately(false)
                      setDateTime(day)
                    }}
                    showIndicatorIcon
                  />
                </View>
              </View>
            </FormGroup>
            <FormGroup style={FormGroupStyle}>
              <Button
                mode="text"
                style={{ alignSelf: 'flex-end', paddingRight: 0 }}
                onPress={onPressPreview}
              >
                {t('プレビュー')}
              </Button>
            </FormGroup>
            <FormGroup
              style={{ marginBottom: 24 }}
              formLabel={<FormLabel value={t('件名')} />}
            >
              <TextInput value={subject} onChangeText={setSubject} />
              <Text style={{ color: Colors.black60 }}>
                {t('メール、アプリのプッシュ通知で使用します。')}
              </Text>
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value={t('メッセージ')} />}
              mode="inline-expanded"
              style={FormGroupStyle}
            >
              {formValues.map((messageContent, index) => {
                const error = formValuesErrors[index]
                return (
                  <View
                    key={index}
                    style={{ marginBottom: 25, flexDirection: 'row' }}
                  >
                    <View style={{ flex: 1 }}>
                      <MessageContentInput
                        messageContent={messageContent}
                        onChangeMessageContent={(newMessageContent) => {
                          setFormValues((prev) => {
                            prev[index] = newMessageContent
                            return [...prev]
                          })
                        }}
                      />
                      {error != null ? (
                        <Text style={{ color: Colors.caution }}>
                          {error.message}
                        </Text>
                      ) : null}
                    </View>
                    <View style={{ alignSelf: 'center', marginLeft: 20 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setFormValues((prev) => {
                            return prev.filter(
                              (_, thisIndex) => index !== thisIndex
                            )
                          })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          size={20}
                          color={Colors.black60}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )
              })}
              <View style={{ flexDirection: 'row' }}>
                <Button
                  mode="outline"
                  onPress={() => {
                    setFormValues((prev) => [
                      ...prev,
                      { type: 'text', value: '' },
                    ])
                  }}
                >
                  {t('追加する')}
                </Button>
              </View>
            </FormGroup>
          </ResponsiveProvider>
        </View>
        {!isEditMode && (
          <Button mode="contained" onPress={onPressSubmit} height={48}>
            {t('配信する')}
          </Button>
        )}
        {isEditMode && (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Button
              mode="outline"
              variant="danger"
              onPress={onPressDelete}
              width="49%"
              height={48}
            >
              {t('削除する')}
            </Button>
            <Button
              mode="contained"
              width="49%"
              height={48}
              onPress={onPressSubmit}
            >
              {t('更新する')}
            </Button>
          </View>
        )}
      </KeyboardAwareScrollView>
      <ModalCenter
        isVisible={finished}
        onClose={goBack}
        style={{
          width: 400,
          minHeight: 230,
        }}
      >
        <View>
          <View style={{ paddingHorizontal: 24, paddingVertical: 32 }}>
            <Text
              style={{
                fontWeight: '600',
                textAlign: 'center',
                paddingBottom: 16,
              }}
            >
              {t('配信完了しました')}
            </Text>
            <Text style={{ textAlign: 'center' }}>
              {t('{{text}}人の顧客へメッセージの配信が完了しました。', {
                text: numberOfCustomers,
              })}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              borderTopWidth: 0.5,
              paddingVertical: 24,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={goBack}
          >
            <Text>{t('閉じる')}</Text>
          </TouchableOpacity>
        </View>
      </ModalCenter>
      <ConfirmSubmitModal
        isModalVisible={isConfirmSubmitVisible}
        onPressCancel={() => setConfirmSubmitVisible(false)}
        onPressSubmit={doBroadcast}
      />
    </View>
  )
}
