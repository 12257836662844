import React, { useState } from 'react'
import {
  InteractionManager,
  ScrollView,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Platform,
} from 'react-native'
import RejectModal from './RequestView/RejectModal'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons/faCalendarDays'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { getCustomerDisplayName } from '../Customers/Customer'
import {
  getParsedPhoneNumber,
  isPossibleParsePhoneNumber,
} from '../../modules/phoneNumber'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { getRestaurantCourseText } from './common'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { rejectionReservationChangeRequest } from '../../models/RestaurantRequestReservation'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { ReservationChangeRequest } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { RestaurantCourse } from '../../models/RestaurantCourse'
import { Customer } from '../../models/Customer'
import { RestaurantReservation } from '../../models/RestaurantReservation'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useRestaurant } from '../../models/Restaurant'
import { useTableSeats } from '../../models/TableSeat'

const dateLabelTextStyle: StyleProp<TextStyle> = {
  fontSize: 14,
  fontWeight: '300',
  lineHeight: 21,
  letterSpacing: 0,
  color: Colors.black60,
}
function DateLabel({ label, value }: { label: string; value: string }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text style={[{ width: 84 }, dateLabelTextStyle]}>{label}</Text>
      <Text style={dateLabelTextStyle}>
        :
        {dayjs(value).tz().format(
          // eslint-disable-next-line ar-i18n/require-translation-ja
          'YYYY年MM月DD日 HH:mm'
        )}
      </Text>
    </View>
  )
}

function Row({
  mode = 'inline',
  style,
  icon,
  children,
}: {
  mode?: 'inline' | 'inline-expanded'
  icon?: IconDefinition
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: mode === 'inline' ? 'center' : 'flex-start',
        },
        style,
      ]}
    >
      <View style={{ width: 18, marginRight: 14 }}>
        {icon && (
          <FontAwesomeIcon icon={icon} size={18} color={Colors.black50} />
        )}
      </View>
      {children}
    </View>
  )
}

function ChangeInfoForCourse({
  restaurant_course,
  reservation_change_request,
}: {
  restaurant_course?: RestaurantCourse
  reservation_change_request: ReservationChangeRequest
}) {
  const fromRestaurantCourseText =
    restaurant_course == null
      ? t('コースが見つかりません')
      : getRestaurantCourseText({
          title: restaurant_course.title,
          price: restaurant_course.price,
          partySize: reservation_change_request.to_party_size,
        })

  const toRestaurantCourseText =
    reservation_change_request.restaurant_courses.length === 0
      ? null
      : getRestaurantCourseText({
          title: reservation_change_request.restaurant_courses[0].title,
          price: reservation_change_request.restaurant_courses[0].price,
          partySize: reservation_change_request.to_party_size,
        })

  return (
    <View style={{ flex: 1 }}>
      <View>
        <View>
          <Text
            style={{
              fontWeight: '600',
              fontSize: 18,
              lineHeight: 27,
              textDecorationLine:
                fromRestaurantCourseText !== toRestaurantCourseText
                  ? 'line-through'
                  : undefined,
            }}
          >
            {fromRestaurantCourseText}
          </Text>
        </View>
      </View>
      {toRestaurantCourseText == null ? (
        <Text
          style={{
            fontSize: 18,
            lineHeight: 27,
          }}
        >
          {t('コースなし')}
        </Text>
      ) : (
        fromRestaurantCourseText !== toRestaurantCourseText && (
          <View
            style={{
              marginTop: 8,
            }}
          >
            <View>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 27,
                  color: Colors.caution,
                }}
              >
                {toRestaurantCourseText}
              </Text>
            </View>
          </View>
        )
      )}
      {reservation_change_request.restaurant_course_question_answers.map(
        (restaurantCourseQuestionAnswer, index) => {
          return (
            <View
              key={`${index}`}
              style={{
                marginTop: 16,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: Colors.black60,
                    fontWeight: '600',
                  }}
                >
                  {t('質問')}
                </Text>
                <Text
                  style={{
                    marginLeft: 8,
                    flex: 1,
                  }}
                >
                  {
                    restaurantCourseQuestionAnswer.restaurant_course_question
                      .description
                  }
                </Text>
              </View>
              <View
                style={{
                  marginTop: 8,
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: Colors.black60,
                    fontWeight: '600',
                  }}
                >
                  {t('回答')}
                </Text>
                <Text
                  style={{
                    marginLeft: 8,
                    fontWeight: '600',
                  }}
                >
                  {(restaurantCourseQuestionAnswer.text ?? '') !== ''
                    ? restaurantCourseQuestionAnswer.text
                    : restaurantCourseQuestionAnswer.restaurant_course_question_selections
                        .map((selection) => selection.description)
                        .join(', ')}
                </Text>
              </View>
            </View>
          )
        }
      )}
    </View>
  )
}

export function ChangeRequestReservation({
  restaurantId,
  data,
  customer,
  restaurantReservation,
}: {
  restaurantId: number
  data: {
    id: RestaurantReservation['id']
    restaurant_course?: RestaurantCourse
    reservation_change_request: ReservationChangeRequest
  }
  customer: Customer | null
  restaurantReservation: RestaurantReservation
}) {
  const { width, sm } = useResponsive()
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [visibleModal, setVisibleModal] = useState<
    'detail' | 'rejection' | null
  >('detail')
  const { data: restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(restaurantId, {})

  const shouldSkipSeatSelection =
    restaurant?.reservation_book_plan_type === 'entry' && tableSeats.length <= 1
  const onPressApproval = () => {
    setVisibleModal(null)
    const skipSteps = [1, 3]
    if (shouldSkipSeatSelection) {
      skipSteps.push(2)
      skipSteps.sort()
    }
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/change_requests/${data.id}/form?skipSteps=${skipSteps.join(
          ','
        )}`
      )
    } else {
      navigation.navigate('ChangeRequestForm', {
        data: data.reservation_change_request,
        customer,
        restaurantReservation,
        skipSteps,
      })
    }
  }

  const onPressRejection = async () => {
    setVisibleModal(null)
    if (token == null) return
    await rejectionReservationChangeRequest(
      token,
      data.reservation_change_request.id
    )
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  const fromReservedAt = dayjs(
    data.reservation_change_request.from_reserved_at
  ).format(
    // eslint-disable-next-line ar-i18n/require-translation-ja
    'YYYY年MM月DD日（dd）HH:mm'
  )
  const toReservedAt = dayjs(
    data.reservation_change_request.to_reserved_at
  ).format(
    // eslint-disable-next-line ar-i18n/require-translation-ja
    'YYYY年MM月DD日（dd）HH:mm'
  )

  if (restaurant == null) return null

  // entry プランで席が取得できていない場合は null を返す
  if (restaurant.reservation_book_plan_type === 'entry' && tableSeats == null)
    return null

  return (
    <View
      style={[
        { flex: 1 },
        Platform.OS === 'web' ? { backgroundColor: Colors.bgBlack } : {},
      ]}
    >
      <ScrollView
        contentContainerStyle={{
          padding: width < sm ? 24 : 48,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            lineHeight: 27,
            textAlign: 'left',
          }}
        >
          {t('予約変更を承認してください')}
        </Text>
        <Text style={{ marginBottom: width < sm ? 24 : 48 }}>
          {data.reservation_change_request.is_auto_cancel_on_change_failure
            ? t(
                '※この変更リクエストを拒否した場合、元の予約もキャンセルされます'
              )
            : t('※この変更リクエストを拒否した場合でも、元の予約は残ります')}
        </Text>
        <View
          style={{
            flexDirection: width < sm ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: width < sm ? 'flex-start' : 'flex-end',
            marginBottom: 16,
          }}
        >
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
              lineHeight: 33,
              textAlign: 'left',
              marginBottom: width < sm ? 16 : 0,
            }}
          >
            {t('リクエスト内容')}
          </Text>
          <View>
            <DateLabel
              label={t('受付日')}
              value={data.reservation_change_request.created_at}
            />
            <DateLabel
              label={t('変更日')}
              value={data.reservation_change_request.created_at}
            />
          </View>
        </View>

        <View style={{ backgroundColor: Colors.white, borderRadius: 8 }}>
          <View
            style={{
              padding: width < sm ? 16 : 32,
              rowGap: 8,
              borderBottomColor: Colors.border,
              borderBottomWidth: 0.5,
            }}
          >
            <Row icon={faCalendarDays}>
              <View>
                <Text
                  style={[
                    {
                      fontSize: 18,
                      fontWeight: '600',
                      textDecorationLine:
                        fromReservedAt !== toReservedAt
                          ? 'line-through'
                          : undefined,
                    },
                  ]}
                >
                  {fromReservedAt}
                </Text>
                {fromReservedAt !== toReservedAt && (
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: '600',
                      color: Colors.caution,
                    }}
                  >
                    {toReservedAt}
                  </Text>
                )}
              </View>
            </Row>
            <Row icon={faUserFriends}>
              <View>
                <Text
                  style={[
                    {
                      fontSize: 18,
                      fontWeight: '600',
                      textDecorationLine:
                        data.reservation_change_request.to_party_size !==
                        data.reservation_change_request.from_party_size
                          ? 'line-through'
                          : undefined,
                    },
                  ]}
                >
                  {t('{{party_size}}名様', {
                    party_size: data.reservation_change_request.from_party_size,
                  })}
                </Text>
                {data.reservation_change_request.to_party_size !==
                  data.reservation_change_request.from_party_size && (
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: '600',
                      color: Colors.caution,
                    }}
                  >
                    {t('{{party_size}}名様', {
                      party_size: data.reservation_change_request.to_party_size,
                    })}
                  </Text>
                )}
              </View>
            </Row>
            <Row icon={faChair}>
              <View>
                <Text
                  style={[
                    {
                      fontSize: 18,
                      fontWeight: '600',
                      textDecorationLine:
                        data.reservation_change_request.to_restaurant_seat
                          .id ===
                        data.reservation_change_request.from_restaurant_seat.id
                          ? undefined
                          : 'line-through',
                    },
                  ]}
                >
                  {data.reservation_change_request.from_restaurant_seat.name}
                </Text>
                {data.reservation_change_request.to_restaurant_seat.name !==
                  data.reservation_change_request.from_restaurant_seat.name && (
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: '600',
                      color: Colors.caution,
                    }}
                  >
                    {data.reservation_change_request.to_restaurant_seat.name}
                  </Text>
                )}
              </View>
            </Row>
          </View>
          <View style={{ padding: width < sm ? 16 : 32, rowGap: 16 }}>
            <Row mode="inline-expanded" icon={faUtensils}>
              <ChangeInfoForCourse
                restaurant_course={data.restaurant_course}
                reservation_change_request={data.reservation_change_request}
              />
            </Row>
          </View>
        </View>

        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
            lineHeight: 33,
            textAlign: 'left',
            marginTop: 32,
            marginBottom: 16,
          }}
        >
          {t('予約者情報')}
        </Text>
        <View
          style={{
            backgroundColor: Colors.white,
            borderRadius: 8,
            padding: width < sm ? 16 : 32,
            rowGap: 16,
          }}
        >
          <Row icon={faAddressCard}>
            <Text>
              {t('{{name}}様', { name: getCustomerDisplayName(customer) })}
            </Text>
          </Row>
          <Row icon={faPhone}>
            <Text>
              {isPossibleParsePhoneNumber(customer?.phone_number!)
                ? getParsedPhoneNumber(
                    customer?.phone_number!
                  ).phone.replaceAll('-', '')
                : ''}
            </Text>
          </Row>
          <Row icon={faEnvelope}>
            <Text>{customer?.email}</Text>
          </Row>
          <Row icon={faFrown}>
            <Text>
              {(customer?.allergy ?? '') === ''
                ? t('アレルギーはありません。')
                : customer?.allergy}
            </Text>
          </Row>
        </View>
        <RejectModal
          isModalVisible={visibleModal === 'rejection'}
          onClose={() => {
            setVisibleModal(null)
            InteractionManager.runAfterInteractions(() => {
              setVisibleModal('detail')
            })
          }}
          onPressRejection={async () => {
            await rejectionReservationChangeRequest(
              token,
              data.reservation_change_request.id
            )
            if (Platform.OS === 'web') {
              navigate(-1)
            } else {
              navigation.goBack()
            }
          }}
        />
      </ScrollView>
      <View
        style={{
          flexDirection: 'row',
          columnGap: 16,
          padding: 16,
          paddingBottom: 24,
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: Colors.white,
          paddingVertical: 12,
        }}
      >
        <Button
          style={{ flex: 1, maxWidth: 280 }}
          mode="outline"
          variant="danger"
          onPress={onPressRejection}
        >
          拒否する
        </Button>
        <Button onPress={onPressApproval} style={{ flex: 1, maxWidth: 280 }}>
          承認する
        </Button>
      </View>
    </View>
  )
}
