import React, { ReactNode } from 'react'
import { View, GestureResponderEvent } from 'react-native'

import { StepperErrors } from '../Stepper/Errors'
import { StepperList } from '../Stepper/List'
import { Step, Stepper } from '../Stepper'
import { useResponsive } from '../../modules/useResponsive'
import { Colors } from '../../constants/Colors'

type Props = {
  steps?: Step[]
  currentStepNumber?: number
  errors: string[]
  isDisabledNextPress: boolean
  skipStepNumbers?: number[]
  children: ReactNode
  onPressNext: (event: GestureResponderEvent) => void
  onPressGoBack?: (event: GestureResponderEvent) => void
  onPressNextButton?: ReactNode
  onPressSkipButton?: ReactNode
  onPressGoBackButton?: ReactNode
}

export function ReservationStepper({
  steps,
  currentStepNumber,
  errors,
  isDisabledNextPress,
  skipStepNumbers,
  children,
  onPressNext,
  onPressGoBack,
  onPressNextButton,
  onPressSkipButton,
  onPressGoBackButton,
}: Props) {
  const { width, sm } = useResponsive()
  const isListExist = currentStepNumber !== undefined && steps !== undefined

  if (width <= sm) {
    return (
      <Stepper
        steps={steps}
        currentStepNumber={currentStepNumber}
        errors={errors}
        isDisabledNextPress={isDisabledNextPress}
        skipStepNumbers={skipStepNumbers}
        onPressNext={onPressNext}
        onPressGoBack={onPressGoBack}
      >
        {children}
      </Stepper>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      {isListExist && (
        <View
          style={[[2, 3].includes(currentStepNumber) && { marginBottom: -23 }]}
        >
          <StepperList
            steps={steps}
            currentStepNumber={currentStepNumber}
            skipStepNumbers={skipStepNumbers}
          />
        </View>
      )}
      {children}
      {errors.length > 0 && (
        <StepperErrors errors={errors} isListExist={isListExist} />
      )}
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 96,
          borderTopWidth: 1,
          borderColor: Colors.border,
          paddingHorizontal: 24,
          paddingBottom: 16,
          backgroundColor: 'white',
        }}
      >
        <View>{onPressGoBackButton}</View>
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 40 }}>
          {onPressSkipButton}
          {onPressNextButton}
        </View>
      </View>
    </View>
  )
}
