import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React from 'react'

import { goBack } from 'modules/history'
import { View } from 'react-native'

import LinkOpacity from '../LinkOpacity'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'

export const headerHeight = 64
export const headerButtonWidth = 24
export function HeaderButton({
  icon,
  ...props
}: Pick<
  React.ComponentProps<typeof TouchableOpacity>,
  'onPress' | 'disabled' | 'onPressMinInterval'
> & {
  icon: IconDefinition
}) {
  return (
    <TouchableOpacity {...props}>
      <FontAwesomeIcon icon={icon} size={20} color={Colors.primary} />
    </TouchableOpacity>
  )
}

export function HeaderBack({ fallback = '..' }: { fallback?: string }) {
  return (
    <HeaderButton
      icon={faChevronLeft}
      onPressMinInterval={0}
      onPress={() => {
        goBack(fallback)
      }}
    />
  )
}

export function HeaderRightPlusButton({
  to,
  children,
}: Pick<React.ComponentProps<typeof LinkOpacity>, 'to'> & {
  children: string
}) {
  return (
    <LinkOpacity
      to={to}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon icon={faPlus} color={Colors.primary} size={20} />
      <Text style={{ marginLeft: 8, color: Colors.primary }}>{children}</Text>
    </LinkOpacity>
  )
}

export function HeaderRightButton({
  icon,
  label,
  onPress,
}: {
  label: string | null
  onPress: () => void
  icon?: React.ReactNode
}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {icon != null && icon}
        {label != null && (
          <Text
            style={[
              {
                color: Colors.primary,
                fontSize: 16,
              },
              icon != null && {
                marginLeft: 8,
              },
            ]}
          >
            {label}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

export function Header({
  title,
  headerLeft,
  headerRight,
  headerTransparent = false,
}: {
  title: string | React.ReactNode
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  headerTransparent?: boolean
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          height: headerHeight,

          justifyContent: 'center',
        },
        !headerTransparent && {
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        },
        headerTransparent && {
          position: 'absolute',
          width: '100%',
          zIndex: 1,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          flexGrow: 2,
          marginLeft: 16,
          alignItems: 'flex-start',
        }}
      >
        {headerLeft}
      </View>
      {typeof title === 'string' ? (
        <Text
          style={{
            flex: 4,
            marginHorizontal: 16,
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '600',
            color: Colors.primary,
          }}
          numberOfLines={1}
        >
          {title}
        </Text>
      ) : (
        title
      )}

      <View
        style={{
          flex: 1,
          flexGrow: 2,
          marginRight: 16,
          alignItems: 'flex-end',
        }}
      >
        {headerRight}
      </View>
    </View>
  )
}
