import React, { useEffect, useState } from 'react'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Text } from '../../Text'
import { Popover } from '../../Popover'
import { TouchableOpacity } from '../../Touchables'
import { Colors } from '../../../constants/Colors'

interface TitleProps {
  title: string
  helperText?: string
  containerStyle?: StyleProp<ViewStyle>
  titleTextStyle?: StyleProp<TextStyle>
  helperTextStyle?: StyleProp<TextStyle>
}

export default function Title(props: TitleProps) {
  const [isOpenPop, setIsOpenPop] = useState(false)

  useEffect(() => {
    if (isOpenPop === false) return
    const timer = setTimeout(() => {
      setIsOpenPop(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [isOpenPop])

  return (
    <View
      style={[
        { flexDirection: 'row', alignItems: 'center' },
        props.containerStyle,
      ]}
    >
      <Text
        style={[
          {
            fontSize: 22,
            fontWeight: '600',
            lineHeight: 33,
            color: Colors.black,
          },
          props.helperText != null && { marginRight: 16 },
          props.titleTextStyle,
        ]}
      >
        {props.title}
      </Text>
      {props.helperText != null && (
        <Popover
          isOpen={isOpenPop}
          onClose={() => setIsOpenPop(false)}
          anchor={
            <TouchableOpacity onPress={() => setIsOpenPop(true)}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size={24}
                color={Colors.primary}
              />
            </TouchableOpacity>
          }
        >
          <Text
            style={[
              {
                width: 400,
                fontWeight: '300',
                color: Colors.black,
                padding: 24,
                backgroundColor: Colors.white,
              },
              props.helperTextStyle,
            ]}
          >
            {props.helperText}
          </Text>
        </Popover>
      )}
    </View>
  )
}
