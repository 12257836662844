import React from 'react'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Text } from '@hello-ai/ar_shared/src/components/Text'

export function ReservationStepperBackButton({
  onPressGoBack,
  text,
}: {
  onPressGoBack?: () => void
  text: string
}) {
  return (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: Colors.border,
        width: 202,
        height: 45,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={onPressGoBack}
    >
      <Text
        style={{
          color: Colors.black,
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
