import React from 'react'
import { View, ColorValue, StyleProp, ViewStyle } from 'react-native'

import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Customer as CustomerModel } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { isAvailableShowVisitCount } from '../../models/Restaurant'
import { labelColors } from './Attributes'
import { LabelColor } from '../../models/CustomerAttributes'

const customerHeight = 80
const customerImageSize = 40

// レストランがお客さんの名前を見て呼ぶことがあるので、表示系は基本的に予約名などを使う必要がある
export function getCustomerDisplayName(
  customer: CustomerModel | null,
  suffix?: string
) {
  if (
    (customer?.last_name ?? '') !== '' ||
    (customer?.first_name ?? '') !== ''
  ) {
    return (
      [customer?.last_name, customer?.first_name]
        .filter((value) => value != null)
        .join(' ') + (suffix ?? '')
    )
  }

  const joinedName = [
    customer?.reservation_last_name,
    customer?.reservation_first_name,
  ]
    .filter((value) => value != null)
    .join(' ')

  return joinedName ? `${joinedName}${suffix ?? ''}` : t('未設定')
}

export function getReservationName(customer: CustomerModel) {
  return [[customer.reservation_last_name, customer.reservation_first_name]]
    .filter((value) => value != null)
    .join(' ')
}

export function CustomerTypeLabel({
  style,
  backgroundColor,
  color,
  children,
}: {
  style?: StyleProp<ViewStyle>
  backgroundColor: ColorValue
  color: ColorValue
  children: string
}) {
  return (
    <View
      style={[
        {
          paddingHorizontal: 8,
          height: 20,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor,
        },
        style,
      ]}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: '600',

          color,
        }}
      >
        {children}
      </Text>
    </View>
  )
}

export function Customer({
  onPress,
  onPressInfo,
  onPressMessage,
  selection,
  customer,
  attributes,
  restaurant,
}: {
  onPress?: () => void
  onPressInfo?: () => void
  onPressMessage?: () => void
  selection?: {
    selected: boolean
    onPress: () => void
  }
  customer: CustomerModel
  attributes?: {
    id: string
    display_in_list: boolean
    label_color: LabelColor
    name: string
  }[]
  restaurant?: Pick<Restaurant, 'reservation_book_plan_type'>
}) {
  const { width, sm } = useResponsive()
  const attributeIds =
    customer.profiles?.slice(-1)[0]?.custom_attributes?.split(',') ?? []
  const selectedAttributes = attributes?.filter((i) =>
    attributeIds.includes(i.id)
  )

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={onPress == null}
      style={[
        {
          display: 'flex',
          paddingVertical: 16,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: customerHeight,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Image
            source={{
              uri: customer.image_url,
            }}
            style={{
              width: customerImageSize,
              height: customerImageSize,
              borderRadius: customerImageSize / 2,
            }}
          />
          <View
            style={{
              marginLeft: 8,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 14,
                lineHeight: 22,
              }}
            >
              {getCustomerDisplayName(customer)}
            </Text>
            <Text
              style={[
                {
                  fontSize: 12,
                  lineHeight: 18,
                  color: Colors.secondaryBlack,
                },
              ]}
            >
              {width >= sm ? getReservationName(customer) : customer.username}
            </Text>
            {((width >= sm &&
              customer.username != null &&
              customer.username !== '') ||
              customer.user != null ||
              customer.favoriting) && (
              <View
                style={[
                  {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                  width < sm && { marginTop: 4 },
                ]}
              >
                {width >= sm &&
                  customer.username != null &&
                  customer.username !== '' && (
                    <Text
                      style={{
                        fontSize: 12,
                        lineHeight: 18,
                        color: Colors.secondaryBlack,
                      }}
                    >
                      {customer.username}
                    </Text>
                  )}
                {customer.user != null && (
                  <CustomerTypeLabel
                    backgroundColor={Colors.primaryBg}
                    color={Colors.primary}
                    style={width >= sm && { marginLeft: 8 }}
                  >
                    {t('ユーザー')}
                  </CustomerTypeLabel>
                )}
                {customer.favoriting && (
                  <CustomerTypeLabel
                    backgroundColor={Colors.accentBg}
                    color={Colors.accent}
                    style={{ marginLeft: width >= sm ? 8 : 4 }}
                  >
                    {t('フォロー中')}
                  </CustomerTypeLabel>
                )}
              </View>
            )}
          </View>
        </View>
        {(selectedAttributes?.length ?? 0) > 0 && (
          <View
            style={{
              flexDirection: 'row',
              gap: 8,
              marginTop: 16,
              flexWrap: 'wrap',
            }}
          >
            {selectedAttributes!.map((a) => (
              <View
                key={a.id}
                style={{
                  height: 20,
                  borderRadius: 2,
                  backgroundColor: labelColors[a.label_color].backgroundColor,
                  paddingHorizontal: 8,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: labelColors[a.label_color].text,
                    fontWeight: '600',
                  }}
                >
                  {a.name}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {onPressInfo != null && (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: width >= sm ? 16 : 8,
          }}
          onPress={onPressInfo}
        >
          {width >= sm ? (
            <Text
              style={{
                marginLeft: 4,
                fontSize: 14,
                lineHeight: 21,
                color: Colors.primary,
              }}
            >
              {t('情報を見る')}
            </Text>
          ) : (
            <FontAwesomeIcon
              icon={faAddressBook}
              size={24}
              color={Colors.primary}
            />
          )}
        </TouchableOpacity>
      )}
      {selection != null && (
        <TouchableOpacity
          style={{
            borderRadius: 4,
            borderColor: selection.selected ? Colors.success : Colors.primary,
            borderWidth: 1,
            width: width >= sm ? 88 : 60,
            height: 36,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: selection.selected ? Colors.success : Colors.white,
          }}
          onPress={selection.onPress}
        >
          {width >= sm ? (
            <Text
              style={{
                fontSize: 14,
                lineHeight: 24,
                color: selection.selected ? Colors.white : Colors.primary,
              }}
            >
              {selection.selected ? t('選択済み') : t('選択する')}
            </Text>
          ) : selection.selected ? (
            <FontAwesomeIcon icon={faCheck} size={16} color={Colors.white} />
          ) : (
            <Text
              style={{
                fontSize: 14,
                lineHeight: 24,
                color: Colors.primary,
              }}
            >
              {t('選択')}
            </Text>
          )}
        </TouchableOpacity>
      )}
      {onPressMessage != null && (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 216,
            height: 48,
            borderRadius: 48,
            borderColor: Colors.primary,
            borderWidth: 1,
            marginRight: 20,
          }}
          onPress={onPressMessage}
        >
          <FontAwesomeIcon icon={faEnvelope} size={20} color={Colors.primary} />
          <Text
            style={{
              fontSize: 16,
              lineHeight: 24,
              fontWeight: '600',
              color: Colors.primary,
              marginLeft: 6,
            }}
          >
            {t('メッセージ')}
          </Text>
        </TouchableOpacity>
      )}
      {isAvailableShowVisitCount(restaurant) && (
        <Text
          style={{
            width: width >= sm ? 128 : 50,
            fontSize: 14,
            fontWeight: '300',
            lineHeight: 21,
          }}
        >
          {t('{{count}}回', { count: customer.visit_count })}
        </Text>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {onPress != null && (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faChevronRight}
              size={12}
              color={Colors.black}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}
