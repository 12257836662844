import React from 'react'
import { ScrollView, View } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { useBroadcastMessage } from '../../../models/BroadcastMessage'
import {
  getStatusDisplayLabel,
  getStatusTextStyle,
} from '@hello-ai/for_r_app/src/components/Message/Broadcast/Table'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Image } from '@hello-ai/ar_shared/src/components/Image'

const FormGroupStyle: Parameters<typeof FormGroup>[0]['style'] = {
  paddingVertical: 12,
}

interface BroadcastHistory {
  restaurantId: number
  broadcastMessageId: string
}

export default function BroadcastHistoryView({
  restaurantId,
  broadcastMessageId,
}: BroadcastHistory) {
  const { broadcastMessage } = useBroadcastMessage(
    restaurantId,
    broadcastMessageId
  )

  if (broadcastMessage == null) {
    return null
  }

  return (
    <View style={{ backgroundColor: Colors.bgBlack, flex: 1 }}>
      <View style={{ paddingHorizontal: 48, paddingVertical: 24 }}>
        <View
          style={{ backgroundColor: 'white', padding: 32, borderRadius: 8 }}
        >
          <FormGroup
            formLabel={<FormLabel value={t('ステータス')} />}
            mode="inline"
            style={FormGroupStyle}
          >
            <Text style={getStatusTextStyle(broadcastMessage?.status)}>
              {getStatusDisplayLabel(broadcastMessage?.status)}
            </Text>
          </FormGroup>
          <FormGroup
            formLabel={<FormLabel value={t('配信対象')} />}
            mode="inline"
            style={FormGroupStyle}
          >
            <Text>
              {t('全ての顧客（{{text}}人）', {
                text: broadcastMessage?.number_of_sent ?? '',
              })}
            </Text>
          </FormGroup>
          <FormGroup
            formLabel={<FormLabel value={t('配信人数')} />}
            mode="inline"
            style={FormGroupStyle}
          >
            <Text>
              {t('{{text}}人', { text: broadcastMessage?.success_count ?? '' })}
            </Text>
          </FormGroup>
          <FormGroup
            formLabel={<FormLabel value={t('配信日時')} />}
            mode="inline"
            style={FormGroupStyle}
          >
            <Text>
              {dayjs(broadcastMessage?.delivery_at).format(
                // eslint-disable-next-line ar-i18n/require-translation-ja
                'YYYY年M月DD日HH:mm'
              )}
            </Text>
          </FormGroup>
          <FormGroup formLabel={<FormLabel value={t('件名')} />}>
            <Text>{broadcastMessage?.subject}</Text>
          </FormGroup>
          <FormGroup
            formLabel={<FormLabel value={t('本文')} />}
            mode="inline-expanded"
            style={[
              FormGroupStyle,
              {
                height: 400,
              },
            ]}
          >
            <ScrollView>
              {broadcastMessage?.message_contents.map((content) => (
                <View key={content.id}>
                  {content.button != null && (
                    <Text style={{ color: Colors.primary, fontWeight: '600' }}>
                      {content.button.text}
                    </Text>
                  )}
                  {content.image == null && content.button == null && (
                    <Text style={{ marginBottom: 14 }}>{content.body}</Text>
                  )}
                  {content.image != null && (
                    <Image
                      contentFit="cover"
                      style={{ width: 100, height: 100, marginBottom: 14 }}
                      source={{ uri: content.image.url }}
                    />
                  )}
                </View>
              ))}
            </ScrollView>
          </FormGroup>
        </View>
      </View>
    </View>
  )
}
