import React from 'react'
import { Platform, View, ScrollView } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  updateRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import { getCustomerDisplayName } from '../../Customers/Customer'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { ChangeStatusButtons } from './ChangeStatusButtons'
import { ReservationIcons } from './ReservationIcons'
import { getVisitStatusStyle } from './common'

function ReservationCard({
  reservation,
  onPress,
  onPressChangeVisitStatus,
}: {
  reservation: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations'][number]
  onPress: () => void
  onPressChangeVisitStatus: (
    visitStatus: NonNullable<
      ReturnType<
        typeof useRestaurantReservations
      >['restaurantReservations'][number]['visit_status']
    >
  ) => Promise<void>
}) {
  const { backgroundColor, color } = getVisitStatusStyle(
    reservation.visit_status
  )
  const visit_status = reservation.visit_status

  return (
    <TouchableOpacity
      style={{ backgroundColor, borderRadius: 8, padding: 8 }}
      onPress={onPress}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
        <View style={{ flex: 1 }}>
          <Text
            numberOfLines={1}
            style={{ color, fontWeight: '600', fontSize: 14, lineHeight: 21 }}
          >
            {reservation.table_seats
              .map((tableSeat) => tableSeat.name)
              .join(',')}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <Text style={{ color, fontSize: 12 }}>
              {dayjs(reservation.start_at).format('HH:mm')}-
            </Text>
            <Text style={{ color, fontSize: 12 }}>
              {t(
                '{{party_size}} ({{adult_party_size}}、{{child_party_size}})',
                {
                  party_size: t('{{count}}人', {
                    count: reservation.party_size,
                  }),
                  adult_party_size: t('大人{{count}}', {
                    count: reservation.adult_party_size,
                  }),
                  child_party_size: t('子供{{count}}', {
                    count: reservation.child_party_size,
                  }),
                }
              )}
            </Text>
          </View>
          <Text
            style={{ color, marginTop: 4, fontWeight: '600', fontSize: 14 }}
          >
            {getCustomerDisplayName(reservation.customers[0], t('様'))}
          </Text>
        </View>
        <FontAwesomeIcon icon={faAngleRight} size={16} color={Colors.white} />
      </View>
      <ReservationIcons reservation={reservation} />
      <View />
      {/* 退店済みが追加されたらその場合ボタン表示を消す */}
      <ChangeStatusButtons
        visit_status={visit_status}
        onPressChangeVisitStatus={onPressChangeVisitStatus}
      />
    </TouchableOpacity>
  )
}

export function ReservationListView({
  restaurantId,
  reservations,
  restaurantReservationBlocks,
  onUpdateVisitStatus,
}: {
  restaurantId: number
  reservations: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations']
  restaurantReservationBlocks: NonNullable<
    ReturnType<typeof restaurantReservationBlockService.useListPeriod>['data']
  >['restaurantReservationBlockPeriods']
  onUpdateVisitStatus: () => void
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()

  const sectioned: (
    | {
        type: 'reservation'
        reservation: (typeof reservations)[number]
      }
    | {
        type: 'block'
        block: (typeof restaurantReservationBlocks)[number] & {
          startAt: Timestamp
        }
      }
  )[][] = new Array(24).fill(0).map(() => [])

  reservations.forEach((reservation) => {
    const hour = dayjs(reservation.start_at).hour()
    sectioned[hour] = [...sectioned[hour], { type: 'reservation', reservation }]
  })
  restaurantReservationBlocks.forEach((block) => {
    if (block.startAt == null) {
      return
    }
    const date = dayjs(block.startAt.seconds * 1000)
    const hour = date.hour()
    sectioned[hour] = [
      ...sectioned[hour],
      { type: 'block', block: { ...block, startAt: block.startAt } },
    ]
  })
  const startIndex = sectioned.findIndex((i) => i.length > 0)
  const renderItems = sectioned.slice(startIndex).map((items) => {
    return items.sort((one, another) => {
      const oneTime =
        one.type === 'block'
          ? dayjs(one.block.startAt.seconds * 1000)
          : dayjs(one.reservation.start_at)
      const anotherTime =
        another.type === 'block'
          ? dayjs(another.block.startAt.seconds * 1000)
          : dayjs(another.reservation.start_at)
      return oneTime.isBefore(anotherTime) ? -1 : 1
    })
  })

  return (
    <ScrollView>
      {renderItems.length === 1 && renderItems[0].length === 0 ? (
        <View
          style={{
            marginTop: 8,
            marginLeft: 8,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: Colors.black,
            }}
          >
            {t('このフロアの予約はありません')}
          </Text>
        </View>
      ) : (
        <>
          {renderItems.map((items, index) => (
            <View
              key={`reservations-${index + startIndex}`}
              style={{ padding: 16 }}
            >
              <Text
                style={{ fontWeight: '600', fontSize: 14, color: Colors.black }}
              >
                {index + startIndex}:00-
              </Text>
              <View style={{ marginTop: 12, gap: 12 }}>
                {items.map((item) =>
                  item.type === 'reservation' ? (
                    <ReservationCard
                      key={item.reservation.id}
                      reservation={item.reservation}
                      onPress={() => {
                        if (Platform.OS === 'web') {
                          navigate(
                            `/restaurants/${restaurantId}/reservations/${item.reservation.id}`
                          )
                        } else {
                          navigation.navigate('ReservationsShow', {
                            restaurantReservationId: item.reservation.id,
                          })
                        }
                      }}
                      onPressChangeVisitStatus={async (visitStatus) => {
                        if (token == null) return
                        const { error } = await updateRestaurantReservation(
                          token,
                          restaurantId,
                          item.reservation.id,
                          {
                            visit_status: visitStatus,
                          }
                        )
                        if (error != null) return
                        onUpdateVisitStatus()
                      }}
                    />
                  ) : (
                    <TouchableOpacity
                      key={item.block.id}
                      style={{
                        backgroundColor: Colors.black60,
                        borderRadius: 8,
                        padding: 8,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onPress={() => {
                        if (Platform.OS === 'web') {
                          navigate(
                            `/restaurants/${restaurantId}/reservations/blocks/${item.block.id}`
                          )
                        } else {
                          navigation.navigate('ReservationBlocksShow', {
                            restaurantReservationBlockId:
                              item.block.restaurantReservationBlock?.id,
                            startAt: item.block.startAt,
                            endAt: item.block.endAt,
                          })
                        }
                      }}
                    >
                      <View>
                        <Text style={{ color: Colors.white, fontSize: 12 }}>
                          {dayjs(item.block.startAt.seconds * 1000).format(
                            'HH:mm'
                          )}
                          -
                        </Text>
                        <Text
                          style={{
                            color: Colors.white,
                            fontSize: 12,
                            fontWeight: '600',
                          }}
                        >
                          {t('ブロック')}
                        </Text>
                      </View>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        size={16}
                        color={Colors.white}
                      />
                    </TouchableOpacity>
                  )
                )}
              </View>
            </View>
          ))}
        </>
      )}
    </ScrollView>
  )
}
