import React, { useEffect, useState } from 'react'
import { StyleProp, ViewStyle, TextStyle, View } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { SelectItem } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { times } from 'lodash'

import { Modal } from '../Shared/Modal'

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import DatePicker from 'components/Shared/DatePicker'

const START_YEAR = 2017

interface SelectPeriodInputProps {
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  selectedStartValue: dayjs.Dayjs
  selectedEndValue: dayjs.Dayjs
  onChangeValues: (values: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => void
}

export const getYears = (): Array<SelectItem<number>> => {
  const thisYear = dayjs().year()
  const diffYears = thisYear - START_YEAR
  return times(diffYears + 1, (i) => {
    return {
      label: t('{{year}}年', { year: thisYear - i }),
      value: thisYear - i,
    }
  })
}

export const months: Array<SelectItem<number>> = times(12, (i) => {
  const date = dayjs().month(i)
  return {
    label: date.format('MMM'),
    value: i + 1,
  }
})

export const getDays = (
  year: number,
  month: number
): Array<SelectItem<number>> => {
  const target = dayjs(
    `${year}-${month.toString().padStart(2, '0')}-01`,
    'YYYY-MM-DD'
  )

  return times(target.daysInMonth(), (i) => {
    return {
      label: t('{{day}}日', { day: i + 1 }),
      value: i + 1,
    }
  })
}

export function getFormatLabel(date: dayjs.Dayjs): string {
  const dow = date.format('d')
  const dowLabel = dayjs.weekdaysShort()[Number(dow)]
  // eslint-disable-next-line ar-i18n/require-translation-ja
  return date.format('YYYY年M月DD日') + `(${dowLabel})`
}

export function SelectPeriodInput({
  disabled,
  style,
  labelStyle,
  selectedStartValue,
  selectedEndValue,
  onChangeValues,
}: SelectPeriodInputProps) {
  const { width, sm, md } = useResponsive()
  const [isModalVisible, setModalVisible] = useState(false)

  const [startValue, setStartValue] = useState(selectedStartValue)
  const [endValue, setEndValue] = useState(selectedEndValue)

  useEffect(() => {
    setStartValue(selectedStartValue)
  }, [selectedStartValue])

  useEffect(() => {
    setEndValue(selectedEndValue)
  }, [selectedEndValue])

  const onChangeStart = (start: dayjs.Dayjs) => {
    setStartValue(start)
  }

  const onChangeEnd = (end: dayjs.Dayjs) => {
    setEndValue(end)
  }
  const onPress = () => {
    onChangeValues({ start: startValue, end: endValue })
    setModalVisible(false)
  }

  const onPressReset = () => {
    setStartValue(selectedStartValue)
    setEndValue(selectedEndValue)
  }

  const onClose = () => {
    onPressReset()
    setModalVisible(false)
  }

  return (
    <View style={{ flex: 1 }}>
      <TouchableOpacity
        disabled={disabled}
        onPress={() => setModalVisible(true)}
        style={[
          {
            backgroundColor: Colors.field,
            borderRadius: 8,
            height: 48,
            paddingHorizontal: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: disabled ? 0.3 : 1,
          },
          style,
        ]}
      >
        <Text style={[labelStyle]}>
          {getFormatLabel(selectedStartValue)} ~{' '}
          {getFormatLabel(selectedEndValue)}
        </Text>
      </TouchableOpacity>
      <Modal
        isVisible={isModalVisible}
        onBackButtonPress={onClose}
        onBackdropPress={onClose}
        style={{ justifyContent: 'flex-end', margin: 0 }}
      >
        <View
          style={{
            flex: 1,
            maxHeight: '50%',
            backgroundColor: 'white',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              height: 65,
              justifyContent: 'center',
              alignItems: 'center',
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
            }}
          >
            <View
              style={{
                position: 'absolute',
                left: 24,
              }}
            >
              <TouchableOpacity onPress={() => setModalVisible(false)}>
                <FontAwesomeIcon
                  icon={faTimes}
                  size={26}
                  color={Colors.primary}
                />
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
                color: Colors.primary,
              }}
            >
              {t('表示期間を設定')}
            </Text>
          </View>
          <View
            style={{
              padding: 24,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* start */}
            <DatePicker
              format="YYYY/MM/DD"
              maxDate={new Date(endValue.format('YYYY/MM/DD'))}
              value={new Date(startValue.format('YYYY/MM/DD'))}
              onChange={(d) => {
                if (!d) {
                  return
                }
                onChangeStart(dayjs(d.toDate()))
              }}
              style={{
                flex: 1,
              }}
              DialogProps={{
                style: {
                  zIndex: 10000,
                },
              }}
            />
            <View
              style={{
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 40,
                  fontWeight: '300',
                }}
              >
                ~
              </Text>
            </View>
            {/* end */}
            <DatePicker
              format="YYYY/MM/DD"
              minDate={new Date(startValue.format('YYYY/MM/DD'))}
              maxDate={new Date()}
              value={new Date(endValue.format('YYYY/MM/DD'))}
              onChange={(d) => {
                if (!d) {
                  return
                }
                onChangeEnd(dayjs(d.toDate()))
              }}
              style={{ flex: 1 }}
              DialogProps={{
                style: {
                  zIndex: 10000,
                },
              }}
            />
          </View>
          <View
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: width > md ? 'row' : 'column',
              gap: 8,
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingVertical: 48,
              paddingHorizontal: 24,
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 26,
                  fontWeight: '600',
                  color: Colors.black,
                }}
              >
                {getFormatLabel(startValue)} ~ {getFormatLabel(endValue)}
              </Text>
            </View>
            <View
              style={{
                flexDirection: width > sm ? 'row' : 'column',
                gap: 16,
              }}
            >
              <Button width={220} height={48} onPress={onPress}>
                <Text
                  style={{
                    color: 'white',
                    fontWeight: '600',
                  }}
                >
                  {t('確定する')}
                </Text>
              </Button>

              <Button
                height={48}
                width={220}
                style={{
                  backgroundColor: 'white',
                  borderColor: Colors.black,
                }}
                onPress={onPressReset}
              >
                <Text
                  style={{
                    color: Colors.black,
                    fontWeight: '300',
                  }}
                >
                  {t('リセット')}
                </Text>
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}
