import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { setHeader, wrapAspidaResponse } from '../modules/axios'
import useSWR, { mutate } from '../modules/swr'
import { client } from '../modules/api'
import {
  createAspidaSWRParameters,
  getAspidaSWRKey,
} from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { Methods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/floors'
import { Methods as FloorMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/floors/_floor_id@string'
import { Methods as FloorPositionsMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/floors/_floor_id@string/positions'

type CreateFloorParams = Methods['post']['reqBody']
type UpdateFloorParams = FloorMethods['put']['reqBody']
export type UpdatePositionParams = FloorPositionsMethods['put']['reqBody']
export type FloorListItem = Methods['get']['resBody'][number]

export const mutateFloors = (restaurantId: number) => {
  return mutate(
    getAspidaSWRKey(client.restaurants._restaurant_id(restaurantId).floors)
  )
}

export const mutateFloor = (restaurantId: number, floorId: string) => {
  return mutate(
    getAspidaSWRKey(
      client.restaurants._restaurant_id(restaurantId).floors._floor_id(floorId)
    )
  )
}

export function useRestaurantFloors(restaurantId: number) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error } = useSWR(
    ...createAspidaSWRParameters(
      client.restaurants._restaurant_id(restaurantId).floors
    )
  )
  return {
    floors: data,
    mutate,
    error,
  }
}

export function useRestaurantFloor(restaurantId: number, floorId?: string) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error, isValidating } = useSWR(
    ...createAspidaSWRParameters(
      floorId != null
        ? client.restaurants
            ._restaurant_id(restaurantId)
            .floors._floor_id(floorId)
        : null
    )
  )
  return {
    floor: data,
    isValidating,
    mutate,
    error,
  }
}

export function createRestaurantFloor(
  token: string,
  restaurantId: number,
  params: CreateFloorParams
) {
  setHeader({ token })
  return wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .floors.post({ body: params })
  )
}

export function updateRestaurantFloor(
  token: string,
  restaurantId: number,
  floorId: string,
  params: UpdateFloorParams
) {
  setHeader({ token })
  return wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .floors._floor_id(floorId)
      .put({ body: params })
  )
}

export function updateRestaurantFloorPosition(
  token: string,
  restaurantId: number,
  floorId: string,
  params: UpdatePositionParams
) {
  setHeader({ token })
  return wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .floors._floor_id(floorId)
      .positions.put({ body: params })
  )
}

export function archiveRestaurantFloor(
  token: string,
  restaurantId: number,
  floorId: string
) {
  setHeader({ token })
  return wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .floors._floor_id(floorId)
      .delete()
  )
}
