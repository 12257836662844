import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Header } from './Header'

function getTitle(title: string | undefined) {
  if (title === undefined) return 'Respo'
  return `${title} - Respo`
}

interface ScreenState {
  documentTitle?: string // web上で表示されるタイトル
  title?: string
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  headerTransparent?: boolean
}

const ScreenContext = React.createContext<
  [state: ScreenState, setState: (value: ScreenState) => void] | undefined
>(undefined)

export function useNavigation() {
  const context = useContext(ScreenContext)

  if (context === undefined) {
    throw new Error(
      'useNavigation() must be called inside a <Screen> component'
    )
  }
  const [, setState] = context

  return React.useMemo(
    () => ({
      setOptions: setState,
    }),
    [setState]
  )
}

export interface ScreenOptions {
  title?: string | React.ReactNode
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  headerShown?: boolean
  headerTransparent?: boolean
}

export function Screen({
  options = {},
  children,
}: {
  options?: ScreenOptions
  children?: React.ReactNode
}) {
  const [screenState, setScreenState] = useState<ScreenState>({})

  const title =
    ((screenState.title !== undefined && screenState.title) || options.title) ??
    ''

  // true by default
  const headerShown = options.headerShown === undefined || options.headerShown

  return (
    <ScreenContext.Provider value={[screenState, setScreenState]}>
      <Helmet>
        <title>{getTitle(screenState.documentTitle)}</title>
      </Helmet>
      {headerShown && (
        <Header
          title={title}
          headerLeft={screenState.headerLeft ?? options.headerLeft}
          headerRight={screenState.headerRight ?? options.headerRight}
          headerTransparent={
            screenState.headerTransparent ?? options.headerTransparent
          }
        />
      )}
      {children}
    </ScreenContext.Provider>
  )
}
