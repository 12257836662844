import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import {
  Confirmation,
  getDefaultDuration,
  ReservationFormState,
  SelectSeats,
} from './ReservationForm'
import invariant from 'tiny-invariant'
import { approveReservationChangeRequest } from '../../models/RestaurantRequestReservation'
import { getFormatTime, toSeconds } from '../../modules/time'
import {
  RestaurantReservation,
  useRestaurantReservations,
} from '../../models/RestaurantReservation'
import { ReservationChangeRequest } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { Customer } from '../../models/Customer'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useNavigation } from '../../modules/navigation/useNavigation'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'

type PartiallyPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export function ChangeRequestForm({
  restaurantId,
  data,
  customer,
  restaurantReservation,
  skipSteps,
}: {
  restaurantId: number
  data: ReservationChangeRequest
  customer: Customer | null
  restaurantReservation: RestaurantReservation
  skipSteps: number[]
}) {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [step, setStep] = useState<number>(
    Math.min(...[1, 2, 3, 4].filter((s) => !skipSteps.includes(s)))
  )

  const [state, setState] = useState<
    PartiallyPartial<ReservationFormState, 'endTime'>
  >(() => {
    const startAt = dayjs(data.to_reserved_at)
    const startTime = toSeconds(startAt.hour(), startAt.minute())
    return {
      dateString: dayjs(data.to_reserved_at).format('YYYY-MM-DD'),
      startTime,
      adultPartySize: data.to_party_size,
      childPartySize: 0,
      selectedSeatIds: [],
      selectedCustomerIds: customer == null ? [] : [customer.id],
      selectedReservationCourses: data.restaurant_courses.map((course) => ({
        partySize: data.to_party_size,
        restaurantCourseId: course.id,
      })),
      memo: '',
      allergy: '',
    }
  })

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: state.dateString,
    }
  )

  const onPressGoBack = () => {
    let prevStep = step - 1
    while (prevStep > 1 && skipSteps.includes(prevStep)) {
      prevStep = prevStep - 1
    }
    if (prevStep <= 1) {
      if (Platform.OS === 'web') {
        navigate(-1)
      } else {
        navigation.goBack()
      }
      return
    }
    setStep(prevStep)
  }

  const onPressNext = (value: Partial<ReservationFormState>) => {
    setState((state) => ({
      ...state,
      ...value,
    }))

    setStep((step) => {
      let nextStep = step + 1
      while (skipSteps.includes(nextStep)) {
        nextStep = nextStep + 1
      }
      return nextStep
    })
  }

  const { mutate } = useRestaurantReservations(restaurantId, {
    date: state.dateString,
  })

  const onPressSubmit = async () => {
    const { error } = await approveReservationChangeRequest(token, data.id, {
      table_seat_ids: state.selectedSeatIds,
      end_at:
        state.endTime !== undefined
          ? `${state.dateString} ${getFormatTime(state.endTime)}`
          : null,
    })
    if (error != null) return
    await mutate()
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?date=${state.dateString}&m=chart`
      )
    } else {
      navigation.navigate('Reservations', {
        dateString: state.dateString,
        startTime: state.startTime,
      })
    }
  }

  useEffect(() => {
    if (state.endTime != null) return

    // 席選択をスキップする場合は終了時刻を設定する必要がある
    if (skipSteps.includes(2)) {
      setState({
        ...state,
        endTime:
          state.startTime +
          getDefaultDuration({
            startTime: state.startTime,
            restaurantBusinessTimes,
          }),
      })
    }
  }, [skipSteps, state, restaurantBusinessTimes])

  switch (step) {
    case 2:
      return (
        <SelectSeats
          restaurantId={restaurantId}
          currentStep={step}
          skipSteps={skipSteps}
          state={state as any}
          restaurantReservation={restaurantReservation}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressNext}
        />
      )
    case 4:
      return (
        <Confirmation
          restaurantId={restaurantId}
          currentStep={step}
          skipSteps={skipSteps}
          state={state as any}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressSubmit}
        />
      )
    default:
      invariant(true, `unknown step ${step}`)
  }
}
