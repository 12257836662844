import React from 'react'
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { FontAwesomeIcon } from '../FontAwesomeIcon'

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { Colors } from '../../constants/Colors'
import { TouchableOpacity } from '../Touchables'
import { Text } from '../Text'
export const defaultIndicatorIcon = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCaretUp} size={10} color={Colors.black} />
      <FontAwesomeIcon icon={faCaretDown} size={10} color={Colors.black} />
    </>
  )
}

export type SelectInputContentProps = {
  disabled?: boolean
  onPress?: () => void
  label?: string
  error?: boolean
  errorText?: string
  labelStyle?: StyleProp<TextStyle>
  labelWrapperStyle?: StyleProp<ViewStyle>
  children?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  indicatorIcon?: React.ReactNode
}
export function SelectInputContent({
  onPress,
  disabled,
  error,
  errorText,
  label,
  containerStyle,
  style,
  labelStyle,
  labelWrapperStyle,
  children,
  indicatorIcon = defaultIndicatorIcon(),
}: SelectInputContentProps) {
  return (
    <View style={[{ flex: 1 }, containerStyle]}>
      <TouchableOpacity
        disabled={disabled}
        onPress={onPress}
        style={[
          {
            backgroundColor: Colors.field,
            borderRadius: 8,
            height: 48,
            paddingHorizontal: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: disabled ? 0.3 : 1,
            borderWidth: 2,
            borderColor: 'transparent',
          },
          (error || errorText != null) && {
            borderColor: Colors.caution,
          },
          style,
        ]}
      >
        <View style={[labelWrapperStyle]}>
          <Text style={[labelStyle]}>{label}</Text>
        </View>
        <View>{indicatorIcon}</View>
      </TouchableOpacity>
      {errorText != null && (
        <Text
          style={{
            marginTop: 4,
            marginLeft: 16,
            fontSize: 12,
            lineHeight: 18,
            color: Colors.caution,
            fontWeight: '300',
          }}
        >
          {errorText}
        </Text>
      )}
      {children}
    </View>
  )
}
