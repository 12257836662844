import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  ModalCenter,
  ModalHeader,
} from '@hello-ai/ar_shared/src/components/ModalCenter'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import React from 'react'
import { View } from 'react-native'

interface ConfirmSubmitModalProps {
  isModalVisible: boolean
  onPressCancel: () => void
  onPressSubmit: () => void
}

export default function ConfirmSubmitModal({
  isModalVisible,
  onPressCancel,
  onPressSubmit,
}: ConfirmSubmitModalProps) {
  return (
    <ModalCenter isVisible={isModalVisible} onClose={onPressCancel}>
      <View style={{ backgroundColor: Colors.white }}>
        <ModalHeader onClose={onPressCancel}>
          {t('本当に配信しますか？')}
        </ModalHeader>
        <View style={{ padding: 16 }}>
          <Text>{t('このメッセージは、全ての顧客に配信されます。')}</Text>
          <View style={{ flexDirection: 'row', columnGap: 24, marginTop: 16 }}>
            <Button
              mode="outline"
              variant="secondary"
              onPress={onPressCancel}
              style={{ flex: 1 }}
            >
              {t('キャンセル')}
            </Button>
            <AsyncButton onPress={onPressSubmit} style={{ flex: 1 }}>
              {t('配信する')}
            </AsyncButton>
          </View>
        </View>
      </View>
    </ModalCenter>
  )
}
