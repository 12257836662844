import React from 'react'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Text } from '@hello-ai/ar_shared/src/components/Text'

export function ReservationStepperSkipButton({
  onPressSkip,
  text,
}: {
  onPressSkip: () => void
  text: string
}) {
  return (
    <TouchableOpacity onPress={onPressSkip}>
      <Text
        style={{
          color: Colors.primary,
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
