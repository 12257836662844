import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { TableProductPricesPriceType } from '../models/TableProduct'

export const DEFAULT_TAX_RATE = 10
export const REDUCED_TAX_RATE = 8

export function getTaxRateText(price: TableProductPricesPriceType) {
  return `${price.tax_included ? t('税込') : t('税抜')}${
    price.tax_type === 'default' ? DEFAULT_TAX_RATE : REDUCED_TAX_RATE
  }%`
}
