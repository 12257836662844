import React from 'react'
import { View, Image, Platform } from 'react-native'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { createLinkComponent } from '@hello-ai/ar_shared/src/components/Link'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

const LinkButton = createLinkComponent(Button)

export function Unavailable({
  description,
  toContact,
  toService,
  onPressContact,
  onPressService,
}: {
  description: string
  toContact?: string
  toService?: string
  onPressContact?: () => void
  onPressService?: () => void
}) {
  const { width, md } = useResponsive()
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View style={{ width: width < md ? '92%' : '80%' }}>
        <Image
          style={{
            width: '100%',
            height: undefined,
            aspectRatio: width < md ? 704 / 908 : 1760 / 1040,
          }}
          resizeMode="cover"
          source={
            width < md
              ? require('../../assets/images/empty_state_sp.png')
              : require('../../assets/images/empty_state.png')
          }
        />
        <View
          style={{
            position: 'absolute',
            top: width < md ? 20 : 32,
            left: width < md ? 20 : 32,
            bottom: width < md ? 20 : 32,
            right: width < md ? 20 : 32,
            backgroundColor: 'rgba(255,255,255,0.2)',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            style={{
              width: (2167 / 768) * 45,
              height: 45,
            }}
            source={require('../../assets/images/logo.png')}
          />
          <Text
            style={{ fontWeight: '600', marginTop: 24, textAlign: 'center' }}
          >
            {t('ご利用には契約が必要です')}
          </Text>
          <Text style={{ marginTop: 12, textAlign: 'center' }}>
            {description}
          </Text>
          <View
            style={[
              { marginTop: 32 },
              width >= md && {
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <LinkButton
              textStyle={{
                fontSize: 18,
                fontWeight: '400',
                color: Colors.primary,
              }}
              style={[
                {
                  height: 56,
                  backgroundColor: 'transparent',
                  paddingHorizontal: 24,
                },
                width >= md && {
                  flex: Platform.OS === 'web' ? undefined : 1,
                  marginRight: 16,
                },
              ]}
              onPress={onPressService}
              to={toService}
            >
              {t('サービスについて知る')}
            </LinkButton>
            <LinkButton
              textStyle={{
                fontSize: 18,
                color: 'white',
              }}
              style={[
                {
                  height: 56,
                  backgroundColor: Colors.primary,
                  paddingHorizontal: 24,
                  marginTop: width < md ? 16 : 0,
                },
                width >= md && {
                  flex: Platform.OS === 'web' ? undefined : 1,
                  marginLeft: 16,
                },
              ]}
              onPress={onPressContact}
              to={toContact}
            >
              {t('お問い合わせする')}
            </LinkButton>
          </View>
        </View>
      </View>
    </View>
  )
}
