import React, { useState } from 'react'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { ReservationData } from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ListView/Content'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { Platform, StyleProp, ViewStyle, View } from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { RestaurantReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import PaymentFailedModal from './PaymentFailedModal'
import PaymentSuccessedModal from './PaymentSuccessedModal'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  button: {
    width: width >= sm ? 220 : '100%',
    height: 36,
  },
  text: {
    fontWeight: '600',
    fontSize: width >= sm ? 14 : 16,
  },
}))

export function SmartPaymentButton({
  onPressSmartPayment,
  buttonStyle,
}: {
  onPressSmartPayment: () => void
  buttonStyle?: StyleProp<ViewStyle>
}) {
  const style = useResponsiveStyle(responsiveStyle)
  return (
    <Button
      style={[style.button, buttonStyle]}
      textStyle={style.text}
      onPress={onPressSmartPayment}
    >
      {t('スマート決済で会計する')}
    </Button>
  )
}

export function SmartPaymentStatus({
  restaurantReservation,
  restaurantId,
  buttonStyle,
}: {
  restaurantReservation: ReservationData
  restaurantId: number
  buttonStyle?: StyleProp<ViewStyle>
}) {
  const style = useResponsiveStyle(responsiveStyle)

  const [isPaymentFaildModalVisible, setPaymentFaildModalVisible] =
    useState(false)
  const [isPaymentSuccessedModal, setPaymentSuccessedModal] = useState(false)

  switch (restaurantReservation?.smart_payment?.status) {
    case 'paid':
      return (
        <View style={{ height: 36 }}>
          <Button
            mode="outline"
            style={[style.button, buttonStyle]}
            onPress={() => setPaymentSuccessedModal(true)}
          >
            <FontAwesomeIcon icon={faCheck} size={16} color={Colors.primary} />
            <Text
              style={[style.text, { color: Colors.primary, marginLeft: 4 }]}
            >
              {t('支払い済み(¥{{amount}})', {
                amount: numberWithDelimiter(
                  restaurantReservation.smart_payment.amount
                ),
              })}
            </Text>
          </Button>
          <PaymentSuccessedModal
            restaurantId={restaurantId}
            reservationId={restaurantReservation.id}
            data={restaurantReservation.smart_payment}
            onClose={() => setPaymentSuccessedModal(false)}
            isModalVisible={isPaymentSuccessedModal}
          />
        </View>
      )
    case 'canceled':
      return (
        <Button disabled style={[style.button, buttonStyle]}>
          <FontAwesomeIcon
            icon={faMinusCircle}
            size={16}
            color={Colors.white}
          />
          <Text style={[style.text, { color: Colors.white, marginLeft: 4 }]}>
            {t('スマート決済中止')}
          </Text>
        </Button>
      )
    case 'failed':
    case 'require_three_d_secure':
      return (
        <>
          <Button
            mode="outline"
            style={[
              style.button,
              { backgroundColor: Colors.white, borderColor: Colors.caution },
              buttonStyle,
            ]}
            onPress={() => setPaymentFaildModalVisible(true)}
          >
            <FontAwesomeIcon
              icon={faMinusCircle}
              size={16}
              color={Colors.caution}
            />
            <Text
              style={[style.text, { marginLeft: 4, color: Colors.caution }]}
            >
              {t('エラー(¥{{amount}})', {
                amount: numberWithDelimiter(
                  restaurantReservation.smart_payment.amount
                ),
              })}
            </Text>
          </Button>
          <PaymentFailedModal
            failedAmount={restaurantReservation.smart_payment.amount}
            onClose={() => setPaymentFaildModalVisible(false)}
            isModalVisible={isPaymentFaildModalVisible}
          />
        </>
      )
  }
}

export default function SmartPaymentStatusButton({
  restaurantReservation,
  restaurantId,
  onPressSmartPayment,
  buttonStyle,
}: {
  restaurantReservation: ReservationData
  restaurantId: number
  onPressSmartPayment: () => void
  buttonStyle?: StyleProp<ViewStyle>
}) {
  const style = useResponsiveStyle(responsiveStyle)

  // 予約がキャンセル済かつ支払い前であればボタンを表示しない
  if (
    restaurantReservation?.status === 'canceled' &&
    restaurantReservation?.smart_payment?.status === 'not_authorized'
  ) {
    return null
  }

  if (restaurantReservation?.smart_payment == null) {
    return (
      <Button disabled style={[style.button, buttonStyle]}>
        <Text style={[style.text, { color: Colors.white, marginLeft: 4 }]}>
          {t('スマート決済なし')}
        </Text>
      </Button>
    )
  }

  switch (restaurantReservation?.smart_payment?.status) {
    case 'paid':
    case 'canceled':
    case 'failed':
    case 'require_three_d_secure':
      return (
        <SmartPaymentStatus
          restaurantReservation={restaurantReservation}
          restaurantId={restaurantId}
          buttonStyle={buttonStyle}
        />
      )
    case 'authorized':
    case 'not_authorized':
      return <SmartPaymentButton onPressSmartPayment={onPressSmartPayment} />
    default:
      return null
  }
}

export function RevisionSmartPaymentButton({
  restaurantId,
  restaurantReservation,
}: {
  restaurantId: number
  restaurantReservation: Pick<RestaurantReservation, 'id' | 'smart_payment'>
}) {
  const style = useResponsiveStyle(responsiveStyle)
  const navigation = useNavigation()
  const navigate = useNavigate()
  const onPressRevisionSmartPayment = () => {
    if (restaurantReservation.smart_payment == null) return
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/revision_smart_payment`
      )
    } else {
      navigation.navigate('RevisionSmartPayment', {
        restaurantReservationId: restaurantReservation.id,
      })
    }
  }
  return (
    <Button
      onPress={onPressRevisionSmartPayment}
      mode="text"
      style={{ paddingHorizontal: 0 }}
    >
      <FontAwesomeIcon
        icon={faExchange}
        size={16}
        color={Colors.primary}
        style={{ transform: [{ rotate: '90deg' }], marginRight: 2 }}
      />
      <Text style={[style.text, { color: Colors.primary }]}>
        {t('金額修正')}
      </Text>
    </Button>
  )
}

export function CancelSmartPayment({
  onPressCancel,
}: {
  onPressCancel: () => void
}) {
  return (
    <Button
      mode="text"
      style={{ borderColor: Colors.caution, columnGap: 6 }}
      onPress={onPressCancel}
    >
      <FontAwesomeIcon icon={faMinusCircle} size={20} color={Colors.caution} />
      <Text style={{ color: Colors.caution, fontSize: 16 }}>
        {t('スマート決済をやめる')}
      </Text>
    </Button>
  )
}
