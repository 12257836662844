import React, { useState } from 'react'
import { Colors } from '../../../../constants/Colors'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '../../../../types/ForR/GourmetSiteSetting'
import { t } from '../../../../modules/i18n'
import { View } from 'react-native'
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import dayjs from '../../../../modules/dayjs'
import SectionTitle from '../Shared/SectionTitle'
import { ExecuteHistory } from '../../../../types/ForR/SiteController'
import { Text } from '../../../Text'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { TouchableOpacity } from '../../../Touchables'

interface TouchableOpacityProps {
  source: GourmetSiteProvider
  latestUpdateHistory?: ExecuteHistory
  onPressSync: () => Promise<void>
}

export default function OutboundSyncView({
  source,
  latestUpdateHistory,
  onPressSync,
}: TouchableOpacityProps) {
  const [isSyncing, setIsSyncing] = useState(false)
  const provider = GOURMET_SITE_PROVIDER_SOURCE[source]

  return (
    <View>
      <SectionTitle
        title={t('{{provider}}との連携を更新', {
          provider: provider.authenticateLabel,
        })}
        helperText={t(
          '連携ステータスを更新します。連携ステータスが正常であるかチェックするためには、連携の更新を押して確認してください'
        )}
      />
      <View
        style={{
          backgroundColor: Colors.white,
          paddingVertical: 24,
          paddingHorizontal: 16,
          position: 'relative',
          borderRadius: 8,
          borderWidth: 0.5,
          borderColor: Colors.border,
        }}
      >
        <View style={{ flexDirection: 'column' }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              color: Colors.black,
            }}
          >
            最終更新者：{latestUpdateHistory?.business_user?.name ?? '-'}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              color: Colors.black,
            }}
          >
            最終更新日：
            {latestUpdateHistory?.executed_at != null
              ? dayjs(latestUpdateHistory.executed_at)
                  .tz()
                  .format('YYYY/MM/DD HH:mm')
              : '-'}
          </Text>
        </View>
        <TouchableOpacity
          onPress={async () => {
            try {
              setIsSyncing(true)
              await onPressSync()
            } finally {
              setIsSyncing(false)
            }
          }}
          style={{
            flexDirection: 'row',
            columnGap: 8,
            paddingHorizontal: 24,
            paddingVertical: 12,
            borderRadius: 24,
            backgroundColor: Colors.primaryBg,
            position: 'absolute',
            right: 16,
            top: 24,
          }}
          disabled={isSyncing}
        >
          <FontAwesomeIcon icon={faUndo} size={24} color={Colors.primary} />
          <Text
            style={{ color: Colors.primary, fontWeight: '600', fontSize: 16 }}
          >
            {isSyncing ? t('更新中...') : t('連携の更新')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const MAX_RETRY_COUNT = 10
export async function waitSyncFinish(
  prevLatestHistory: ExecuteHistory,
  fetcher: () => Promise<ExecuteHistory[] | null | undefined>
) {
  await new Promise((resolve, reject) => {
    let retryCount = 0
    const timer = setInterval(async () => {
      retryCount++
      const histories = await fetcher()
      const latestHistory = histories?.[0]
      if (latestHistory == null) return
      if (latestHistory.id === prevLatestHistory.id) return
      if (latestHistory.execute_status !== 'ongoing') {
        clearInterval(timer)
        resolve(histories)
        return
      }
      if (retryCount > MAX_RETRY_COUNT) {
        clearInterval(timer)
        reject(new Error('waitSyncFinish timeout'))
      }
    }, 3000)
  })
}
