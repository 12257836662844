import React from 'react'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Text } from '@hello-ai/ar_shared/src/components/Text'

export function ReservationStepperNextButton({
  onPressNext,
  text,
}: {
  onPressNext: () => void
  text: string
}) {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: Colors.primary,
        width: 248,
        height: 45,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={onPressNext}
    >
      <Text
        style={{
          color: 'white',
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
