import React from 'react'
import { PreviewData } from '@hello-ai/for_r_app/src/components/Message/Broadcast/MessagePreviewContent'
import BroadcastPreview from '@hello-ai/for_r_app/src/components/Message/Broadcast/Preview'
import { ModalCenter } from '@hello-ai/ar_shared/src/components/ModalCenter'
import { useRestaurant } from 'models/Restaurant'

interface BroadcastPreviewModalProps {
  restaurantId: number
  isModalVisible: boolean
  previewData?: PreviewData | null
  onClose: () => void
}

export default function BroadcastPreviewModal({
  isModalVisible,
  restaurantId,
  previewData,
  onClose,
}: BroadcastPreviewModalProps) {
  const { restaurant } = useRestaurant(restaurantId)

  if (restaurant == null) return null

  return (
    <ModalCenter isVisible={isModalVisible} onClose={onClose}>
      {previewData != null && (
        <BroadcastPreview restaurant={restaurant} previewData={previewData} />
      )}
    </ModalCenter>
  )
}
