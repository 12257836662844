import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import MessagePreviewContent, {
  PreviewData,
} from '@hello-ai/for_r_app/src/components/Message/Broadcast/MessagePreviewContent'
import { RequestBroadcastMessagePreviewData } from '@hello-ai/ar_shared/src/types/ForR/BroadcastMessage'
import { useBroadcastMessagePreview } from '../../../models/BroadcastMessage'
import WebView from 'react-native-webview'
import { convertImageWithinMessageList, useFormContext } from './Form'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

interface MailPreviewContentProps {
  restaurantId: number
  previewData: PreviewData
}

function MailPreviewContent({
  restaurantId,
  previewData,
}: MailPreviewContentProps) {
  const { downloadImageAsBase64String } = useFormContext()
  const [previewContent, setPreviewContent] =
    useState<RequestBroadcastMessagePreviewData | null>(null)
  const { preview } = useBroadcastMessagePreview(restaurantId, previewContent)

  useEffect(() => {
    let isMount = true
    const run = async () => {
      const data = {
        ...previewData,
        contents: await convertImageWithinMessageList(
          previewData.contents,
          downloadImageAsBase64String
        ),
      }
      if (isMount) {
        setPreviewContent(data)
      }
    }
    run()
    return () => {
      isMount = false
    }
  }, [previewData, downloadImageAsBase64String])

  if (preview == null) {
    return <Loading />
  }

  return (
    <View style={{ height: '90%', paddingBottom: 24 }}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: '300',
          color: Colors.black60,
          marginBottom: 4,
        }}
      >
        {t('件名')}
      </Text>
      <Text
        style={{
          fontSize: 18,
          fontWeight: '600',
          color: Colors.black,
          marginBottom: 16,
        }}
      >
        {preview?.title}
      </Text>
      <Text
        style={{
          fontSize: 12,
          fontWeight: '300',
          color: Colors.black60,
          marginBottom: 4,
        }}
      >
        {t('本文')}
      </Text>
      <View />
      <WebView source={{ html: preview?.body_html }} originWhitelist={['*']} />
    </View>
  )
}

interface BroadcastPreviewProps {
  restaurant: Restaurant
  previewData: PreviewData
}

export default function BroadcastPreview({
  restaurant,
  previewData,
}: BroadcastPreviewProps) {
  const [previewMode, setPreviewMode] = useState<'message' | 'mail'>('message')
  return (
    <View style={{ backgroundColor: 'white', height: '100%' }}>
      <View style={{ padding: 24 }}>
        <View style={{ marginBottom: 24 }}>
          <SegmentedControl
            buttons={[
              {
                onPress: () => {
                  setPreviewMode('message')
                },
                text: t('メッセージ'),
                selected: previewMode === 'message',
              },
              {
                onPress: () => setPreviewMode('mail'),
                text: t('メール'),
                selected: previewMode === 'mail',
              },
            ]}
          />
        </View>
        {previewMode === 'message' && (
          <MessagePreviewContent
            restaurant={restaurant}
            previewData={previewData.contents}
          />
        )}
        {previewMode === 'mail' && (
          <MailPreviewContent
            restaurantId={restaurant.id}
            previewData={previewData}
          />
        )}
      </View>
    </View>
  )
}
