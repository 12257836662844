import Loading from '@hello-ai/for_r_app/src/components/Shared/Loading'
import { signInWithLoginToken } from 'models/BusinessUser'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

/**
 * https://github.com/hello-ai/auto_reserve/issues/29197
 * ?login_token=xxxx が付与されているURLにアクセスした場合、自動でログイン処理を行うコンポーネント
 */
export function LoginTokenSignIn({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const loginToken = searchParams.get('login_token') ?? ''

  React.useEffect(() => {
    if (loginToken === '') {
      return
    }

    async function run() {
      const { error } = await signInWithLoginToken({ loginToken })

      if (error != null) {
        // エラー時に画面が<Loading />のままになるのを防ぐため、パラメータを削除しトップにリダイレクトさせる
        navigate('/')
        return
      }
      // 成功時はパラメータを除去し通常のRouteを表示
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete('login_token')
        return prevSearchParams
      })
    }

    run()
  }, [loginToken, navigate, setSearchParams])

  if (loginToken !== '') {
    return <Loading />
  }

  return <>{children}</>
}
