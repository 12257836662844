import React from 'react'

import { useRestaurantId } from 'modules/useRestaurantId'
import BroadcastFrom, {
  FormContext,
} from '@hello-ai/for_r_app/src/components/Message/Broadcast/Form'
import { downloadImageAsBase64String } from 'modules/image'
import { PreviewData } from '@hello-ai/for_r_app/src/components/Message/Broadcast/MessagePreviewContent'
import BroadcastPreviewModal from 'components/Restaurant/BroadcastPreviewModal'

export default function BroadcastNewScreen() {
  const restaurantId = useRestaurantId()
  const [previewData, setPreviewData] = React.useState<PreviewData | null>(null)
  const showPreview = (previewData: PreviewData) => {
    setPreviewData(previewData)
  }

  return (
    <FormContext.Provider value={{ downloadImageAsBase64String, showPreview }}>
      <BroadcastFrom restaurantId={restaurantId} />
      <BroadcastPreviewModal
        restaurantId={restaurantId}
        isModalVisible={previewData != null}
        previewData={previewData}
        onClose={() => setPreviewData(null)}
      />
    </FormContext.Provider>
  )
}
