import React, { ReactNode } from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

/**
 * 入力フィールドのように見えるが、実際にはフィールドではなくタップでイベント発火するコンポーネント
 * @param props.label - 表示するテキスト
 * @param props.onPress - タップ時のコールバック関数
 * @param props.style - コンポーネントのスタイル
 * @param props.mode - 'default' または 'select' を指定。'select' の場合はセレクトボックスライクに右端に選択矢印が表示される
 * @returns タップ可能な入力フィールド風のコンポーネント
 */
export function FakeInput({
  onPress,
  label,
  children,
  style,
  mode = 'default',
}: {
  onPress: () => void
  label?: string
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  mode?: 'default' | 'select'
}) {
  const { width, sm } = useResponsive()

  return (
    <TouchableOpacity
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 12,
          backgroundColor: Colors.field,
          borderRadius: 8,
          height: width > sm ? 64 : 48,
        },
        style,
      ]}
      onPress={onPress}
    >
      {children !== undefined ? (
        children
      ) : (
        <Text style={{ fontSize: width > sm ? 18 : 14, color: Colors.black }}>
          {label}
        </Text>
      )}
      {mode === 'select' && <ArrowLikeSelectInput />}
    </TouchableOpacity>
  )
}

/**
 * セレクトボックスのような見た目の矢印アイコンを表示するコンポーネント
 * @returns 上下の三角形で構成された矢印アイコン
 */
function ArrowLikeSelectInput() {
  return (
    <View
      style={{
        width: 10,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#ddd',
      }}
    >
      <View
        style={{
          width: 0,
          height: 0,
          borderLeftWidth: 3.8,
          borderRightWidth: 3.8,
          borderBottomWidth: 3.8,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: 'transparent',
          borderLeftColor: 'transparent',
          borderRightColor: 'transparent',
          borderBottomColor: '#000',
          marginBottom: 7,
          color: Colors.black,
        }}
      />
      <View
        style={{
          width: 0,
          height: 0,
          borderLeftWidth: 3.8,
          borderRightWidth: 3.8,
          borderTopWidth: 3.8,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: 'transparent',
          borderLeftColor: 'transparent',
          borderRightColor: 'transparent',
          borderTopColor: '#000',
          color: Colors.black,
        }}
      />
    </View>
  )
}
