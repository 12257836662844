import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { Trans } from 'react-i18next'

export function AddReservationForm({
  defaultPartySize,
  onPressClose,
  onPressReservation,
  onPressWalkIn,
}: {
  defaultPartySize: number
  onPressClose: () => void
  onPressWalkIn: (partySize: number) => Promise<void>
  onPressReservation: (partySize: number) => void
}) {
  const [disabled, setDisabled] = useState(false)
  const [partySize, setPartySize] = useState<number>(defaultPartySize)

  useEffect(() => {
    setPartySize(defaultPartySize)
  }, [defaultPartySize])

  return (
    <View style={{ paddingHorizontal: 24, paddingVertical: 12, gap: 20 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 24 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  color: Colors.black60,
                  fontSize: 18,
                }}
              >
                {t('人数を選択')}
              </Text>
            </View>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}
            >
              <TouchableOpacity
                onPressMinInterval={0}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: Colors.primary10,
                }}
                disabled={disabled || partySize <= 1}
                onPress={() => setPartySize((prev) => Math.max(1, prev - 1))}
              >
                <FontAwesomeIcon
                  icon={faMinus}
                  size={16}
                  color={Colors.primary}
                />
              </TouchableOpacity>
              <Text>
                {/* eslint-disable ar-i18n/require-translation-ja */}
                <Trans
                  key={'<0>{{count}}</0><1>人</1>'}
                  values={{
                    count: partySize,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 24,
                      fontWeight: '600',
                      color: Colors.black,
                    }}
                  >{`${partySize}`}</Text>
                  <Text
                    style={{ marginLeft: 4, fontSize: 14, color: Colors.black }}
                  >
                    人
                  </Text>
                </Trans>
                {/* eslint-enable ar-i18n/require-translation-ja */}
              </Text>
              <TouchableOpacity
                onPressMinInterval={0}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: Colors.primary10,
                }}
                disabled={disabled || partySize > 100}
                onPress={() => setPartySize((prev) => Math.min(100, prev + 1))}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size={16}
                  color={Colors.primary}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <TouchableOpacity disabled={disabled} onPress={onPressClose}>
          <FontAwesomeIcon icon={faClose} size={24} color={Colors.primary} />
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: 'row', gap: 16 }}>
        <Button
          disabled={disabled}
          loading={disabled}
          mode="outline"
          variant="primary"
          style={{
            flex: 1,
            height: 48,
          }}
          onPress={() => {
            setDisabled(true)
            onPressWalkIn(partySize).finally(() => {
              setDisabled(false)
            })
          }}
        >
          {t('ウォークイン')}
        </Button>
        <Button
          mode="contained"
          variant="primary"
          style={{
            flex: 1,
            height: 48,
          }}
          disabled={disabled}
          onPress={() => onPressReservation(partySize)}
        >
          {t('予約')}
        </Button>
      </View>
    </View>
  )
}
