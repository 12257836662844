function blobOrFileToBase64(src: Blob | File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.readAsDataURL(src)
  })
}

export async function downloadImageAsBase64String(remoteUrl: string) {
  try {
    const blob = await fetch(remoteUrl).then((e) => e.blob())
    const data = await blobOrFileToBase64(blob)
    return {
      data,
    }
  } catch (error) {
    return {
      error,
    }
  }
}
