import React, { useState } from 'react'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import {
  useImportMailAddress,
  useLastImportedMail,
  createImportMailSettings,
  useImportMailSettings,
} from '../../models/ImportMailSettings'
import { RegisterEmailStepForm } from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/RegisterEmailStepForm'
import { ConfirmEmailStepForm } from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/ConfirmEmailStepForm'
import { AuthenticateEmailStepForm } from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/AuthenticateEmailStepForm'
import ProviderLoginForm from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/ProviderLoginForm'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import {
  CreateOutboundSettingsParam,
  createOutboundSettings,
  signInCheck,
  updateOutboundSettings,
  useOutboundSettings,
} from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'
import {
  OutboundSettingData,
  isExistOutboundSetting,
} from '@hello-ai/ar_shared/src/types/ForR/OutboundSetting'
import {
  GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS,
  IKKYU_SITE_ACCOUNT_SETTINGS_STEPPERS,
} from '@hello-ai/ar_shared/src/constants/ForR/GourmetSiteSetting/StepForm'
import { Step } from '@hello-ai/ar_shared/src/components/Stepper'

const StepsBySite: Record<GourmetSiteProvider, Step[]> = {
  hotpepper: [],
  gnavi: GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS,
  tabelog: GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS,
  tabelog_note: GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS,
  ikkyu: IKKYU_SITE_ACCOUNT_SETTINGS_STEPPERS,
}

type StepKeyBySite = `${GourmetSiteProvider}-${number}`

interface GourmetSiteAccountSettingInitViewProps {
  restaurantId: number
  initialSource: GourmetSiteProvider
  onCompleted: (outboundSetting: Pick<OutboundSettingData, 'site'>) => void
  handleOnOpenAdminUrl: (url: string) => void
}

export default function GourmetSiteAccountSettingInitView({
  restaurantId,
  initialSource,
  onCompleted,
  handleOnOpenAdminUrl,
}: GourmetSiteAccountSettingInitViewProps) {
  const [outboundSettingParam, setOutboundSettingParam] =
    useState<CreateOutboundSettingsParam | null>(null)
  const source = outboundSettingParam?.site ?? initialSource
  const { outboundSettingsData } = useOutboundSettings(restaurantId, source)

  const { importMailSettingsData } = useImportMailSettings(restaurantId)
  const { importMailAddressData } = useImportMailAddress(restaurantId)
  const { lastImportedMailData, mutate: mutateLastImportedMailData } =
    useLastImportedMail(restaurantId, source)
  const mailAddress = importMailAddressData?.mail_address

  const [currentStepNumber, setCurrentStepNumber] = useState(1)
  const sourceLabel = GOURMET_SITE_PROVIDER_SOURCE[source].authenticateLabel
  const sourceAdminUrl = GOURMET_SITE_PROVIDER_SOURCE[source].adminUrl

  const handleOnPressReload = () => {
    mutateLastImportedMailData()
  }

  const token = useToken()
  const { mutate: mutateOutboundSetting } = useOutboundSettings(
    restaurantId,
    source
  )
  const handleOnConfirmSetting = async () => {
    if (token == null) return
    if (outboundSettingParam == null) return
    const existImportMailSetting = importMailSettingsData?.settings.find(
      (data) => data.source === source
    )
    if (existImportMailSetting?.set_at == null) {
      const { error } = await createImportMailSettings({
        restaurantId,
        token,
        source,
      })
      if (error != null) return
    }
    if (isExistOutboundSetting(outboundSettingsData)) {
      const { error } = await updateOutboundSettings(
        token,
        restaurantId,
        outboundSettingsData.id,
        outboundSettingParam
      )
      if (error != null) return
    } else {
      const { error } = await createOutboundSettings(
        token,
        restaurantId,
        outboundSettingParam
      )
      if (error != null) return
    }
    await mutateOutboundSetting()
    onCompleted(outboundSettingParam)
  }

  const onPressNext = () => {
    setCurrentStepNumber(currentStepNumber + 1)
  }

  const onPressGoBack = () => {
    setCurrentStepNumber(currentStepNumber - 1)
  }

  const steps = StepsBySite[source]

  const renderStepForm = () => {
    const stepKey: StepKeyBySite = `${source}-${currentStepNumber}`
    switch (stepKey) {
      case 'tabelog-1':
      case 'tabelog_note-1':
      case 'ikkyu-1':
      case 'hotpepper-1':
      case 'gnavi-1':
        return (
          <ProviderLoginForm
            steps={steps}
            provider={source}
            currentStepNumber={currentStepNumber}
            onPressNext={async (authenticationForSite) => {
              if (token == null) return
              const { error, response } = await signInCheck(
                token,
                restaurantId,
                authenticationForSite
              )
              if (error != null) return
              const site = response?.data.result
              if (site == null) {
                onError(new Error(t('ログインに失敗しました')))
                return
              }
              if (authenticationForSite.site === 'ikkyu' && site === 'ikkyu') {
                setOutboundSettingParam({
                  ...authenticationForSite,
                  enabled: true,
                })
              } else if (
                (authenticationForSite.site === 'tabelog_note' ||
                  authenticationForSite.site === 'tabelog' ||
                  authenticationForSite.site === 'hotpepper') &&
                (site === 'tabelog' ||
                  site === 'tabelog_note' ||
                  site === 'hotpepper')
              ) {
                setOutboundSettingParam({
                  ...authenticationForSite,
                  site,
                  enabled: true,
                })
              } else if (
                authenticationForSite.site === 'gnavi' &&
                site === 'gnavi'
              ) {
                setOutboundSettingParam({
                  ...authenticationForSite,
                  site,
                  enabled: true,
                })
              }
              onPressNext()
            }}
          />
        )
      case 'tabelog-2':
      case 'tabelog_note-2':
      case 'ikkyu-2':
      case 'hotpepper-2':
      case 'gnavi-2':
        return (
          <RegisterEmailStepForm
            steps={steps}
            currentStepNumber={currentStepNumber}
            site={source}
            sourceLabel={sourceLabel}
            mailAddress={mailAddress}
            handleOnOpenAdminUrl={() => handleOnOpenAdminUrl(sourceAdminUrl)}
            onPressNext={onPressNext}
            onPressGoBack={onPressGoBack}
          />
        )
      case 'tabelog-3':
      case 'tabelog_note-3':
      case 'hotpepper-3':
      case 'gnavi-3':
        return (
          <AuthenticateEmailStepForm
            site={source}
            steps={steps}
            currentStepNumber={currentStepNumber}
            sourceLabel={sourceLabel}
            handleOnPressReload={handleOnPressReload}
            lastImportedMailData={lastImportedMailData}
            onPressNext={onPressNext}
            onPressGoBack={onPressGoBack}
            onPressAdminUrl={() => {
              window.open(sourceAdminUrl, '_blank')
            }}
          />
        )
      case 'tabelog-4':
      case 'tabelog_note-4':
      case 'ikkyu-3':
      case 'hotpepper-4':
      case 'gnavi-4':
        return (
          <ConfirmEmailStepForm
            provider={source}
            steps={steps}
            currentStepNumber={currentStepNumber}
            sourceLabel={sourceLabel}
            onPressNext={handleOnConfirmSetting}
            onPressGoBack={onPressGoBack}
          />
        )
      default: {
        throw new Error('Invalid step')
      }
    }
  }

  return renderStepForm()
}
