import React from 'react'
import { View, Linking } from 'react-native'
import { Text } from '../../../Text'
import { Button } from '../../../Button'
import { Image } from '../../../Image'
import { Colors } from '../../../../constants/Colors'
import logo from '../../../../assets/images/logo.png'
import { t } from '../../../../modules/i18n'

export default function UnContractedView() {
  return (
    <View
      style={{
        rowGap: 24,
        alignItems: 'center',
        backgroundColor: Colors.white,
        paddingHorizontal: 32,
        paddingVertical: 48,
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: '600',
          lineHeight: 27,
          textAlign: 'center',
        }}
      >
        {t('ご利用にはお申し込みが必要です')}
      </Text>
      <Image
        style={{
          width: (2167 / 768) * 80,
          height: 80,
        }}
        source={logo}
      />
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          lineHeight: 27,
          textAlign: 'center',
        }}
      >
        {t(
          'グルメサイト連携機能を使うにはお申し込みが必要です。\n予約管理がこの機能ひとつで簡単になる他社グルメサイト\n連携を利用してみませんか？'
        )}
      </Text>
      <Button
        style={{
          height: 48,
          width: 280,
        }}
        onPress={() => {
          Linking.openURL('https://autoreserve.com/for_restaurants/contact')
        }}
      >
        {t('お問い合わせする')}
      </Button>
    </View>
  )
}
