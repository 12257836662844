import { createRpcService } from '../modules/rpc'
import useSWR, { fetcher, SWRConfiguration, swrKey } from '../modules/swr'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { TableSeatClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service.client'
import { Methods as TableSeatsMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/seats'
import { Methods as TableSeatMethods } from '@hello-ai/api_table/api_table/seats/_id@string'
import { client } from '../modules/api'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { TableSeat as OldTableSeatModel } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import axios, {
  setHeader,
  wrapResponse,
  wrapAspidaResponse,
} from '../modules/axios'
export * from '@hello-ai/ar_shared/src/types/ForR/TableSeat'

export const tableSeatService = createRpcService(TableSeatClient)

export type CreateParams = TableSeatsMethods['post']['reqBody']
export type UpdateParams = TableSeatMethods['put']['reqBody']
export type TableSeat = TableSeatMethods['get']['resBody']

export type CategoryName =
  | 'table_seat'
  | 'counter_seat'
  | 'terrace'
  | 'semi_private_room'
  | 'private_room'
  | 'tatami'
export type SmokingStatus = 'no_smoking' | 'smoking'

export function useTableSeat(
  tableSeatId: string | undefined,
  params: {},
  config?: SWRConfiguration<TableSeatMethods['get']['resBody']>
) {
  const token = useToken()
  setHeader({ token })

  const {
    data: tableSeat,
    error,
    mutate,
  } = useSWR(
    ...createAspidaSWRParameters(
      tableSeatId != null ? client.seats._id(tableSeatId) : null
    ),
    config
  )

  return {
    tableSeat,
    error,
    mutate,
  }
}

export function useTableSeats(
  restaurantId: number,
  params: {},
  config?: SWRConfiguration<OldTableSeatModel[]>
) {
  const token = useToken()

  const {
    data: tableSeats,
    error,
    mutate,
    isLoading,
  } = useSWR<OldTableSeatModel[]>(
    swrKey(token, `/restaurants/${restaurantId}/seats`, params),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher<OldTableSeatModel[]>([token, url, params]),
    config
  )

  return {
    tableSeats: tableSeats ?? [],
    error,
    mutate,
    isLoading,
  }
}

export function useTableSeatsBulk(
  restaurantId: number,
  params: {
    ids: string[]
  }
) {
  const token = useToken()

  const {
    data: tableSeats,
    error,
    mutate,
  } = useSWR<OldTableSeatModel[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/seats/bulk`, params),
    ([token, url]) => fetcher<OldTableSeatModel[]>([token, url, params])
  )

  return {
    tableSeats,
    error,
    mutate,
  }
}

export function createTableSeat(
  token: string,
  restaurantId: number,
  params: CreateParams
) {
  setHeader({ token })
  return wrapAspidaResponse(
    client.restaurants._restaurant_id(restaurantId).seats.post({ body: params })
  )
}

export function updateTableSeat(
  token: string,
  seatId: string,
  params: UpdateParams
) {
  setHeader({ token })
  return wrapAspidaResponse(client.seats._id(seatId).put({ body: params }))
}

export async function bulkUpdateTableSeatStatus(
  token: string,
  restaurantId: number,
  params: {
    ids: string[]
    status: 'published' | 'archived' | 'deleted'
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.patch(`/restaurants/${restaurantId}/seats/status_bulk_update`, params)
  )
  return { error }
}
