import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'

export const getVisitStatusStyle = (
  visitStatus: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations'][number]['visit_status']
) => {
  switch (visitStatus) {
    case 'reserved':
      return { backgroundColor: Colors.accent, color: Colors.white }
    case 'some_visited':
      return { backgroundColor: Colors.blue, color: Colors.white }
    case 'all_visited':
    case 'eaten':
      return { backgroundColor: Colors.accent40, color: Colors.black }
    case 'paid':
      return { backgroundColor: Colors.primary, color: Colors.white }
    case 'departed':
      return { backgroundColor: Colors.primary40, color: Colors.black }
    case 'no_show':
      return { backgroundColor: Colors.caution, color: Colors.white }
    default:
      return { backgroundColor: Colors.white, color: Colors.black }
  }
}
