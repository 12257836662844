import React from 'react'
import { View } from 'react-native'
import SmartPaymentHistoryView from '@hello-ai/for_r_app/src/components/Restaurant/SmartPaymentHistory'
import { useRestaurantId } from 'modules/useRestaurantId'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function SmartPaymentHistoryScreen() {
  const restaurantId = useRestaurantId()

  return (
    <View style={{ backgroundColor: Colors.bgBlack, flex: 1 }}>
      <SmartPaymentHistoryView restaurantId={restaurantId} />
    </View>
  )
}
