import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import Calculator from '../../Shared/Calculator'
import ModalCenter from '../../Shared/ModalCenter'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'
import React, { useState } from 'react'
import {
  InteractionManager,
  View,
  Platform,
  useWindowDimensions,
} from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { CancelSmartPayment } from './SmartPaymentStatusButton'
import ConfirmCancelSmartPaymentModal from './ConfirmCancelSmartPaymentModal'

interface PaymentCalculateModalProps {
  title: string
  description: string
  amountLabel: string
  submitLabel: string
  onCancel: () => void
  onSubmit: (amount: number) => Promise<void>
  onClose: () => void
  defaultAmount?: number
  fixedAmount?: number | null
  showCancelButton?: boolean
}

export default function PaymentCalculateModal({
  title,
  description,
  amountLabel,
  submitLabel,
  onCancel,
  onSubmit,
  onClose,
  defaultAmount = 0,
  fixedAmount = null,
  showCancelButton = true,
}: PaymentCalculateModalProps) {
  const { height: windowHeight } = useWindowDimensions()

  const { width, sm } = useResponsive()
  const [loading, setLoading] = useState(false)
  const [number, setNumber] = useState(`${fixedAmount ?? defaultAmount}`)
  const [isVisiblePaymentModal, setIsVisiblePaymentModal] = useState(true)
  const [isVisibleConfirmCancel, setIsVisibleConfirmCancel] = useState(false)

  const handleCancel = () => {
    setIsVisiblePaymentModal(false)
    InteractionManager.runAfterInteractions(() => {
      setIsVisibleConfirmCancel(true)
    })
  }

  const disableCalculator = fixedAmount != null

  const handleChangeNumber = (num: string) => {
    if (disableCalculator) return
    setNumber(num)
  }

  return (
    <>
      {Platform.OS !== 'web' && (
        <View
          style={{
            flex: 1,
            width: '100%',
            backgroundColor: Colors.bgLightBlack,
          }}
        />
      )}
      <ModalCenter
        isModalVisible={isVisiblePaymentModal}
        width={'98%'}
        height={windowHeight * 0.98}
        title={title}
        onClose={onClose}
        headerRight={
          showCancelButton
            ? () =>
                width >= sm && (
                  <CancelSmartPayment onPressCancel={handleCancel} />
                )
            : undefined
        }
      >
        <View
          style={{
            position: 'relative',
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              padding: width >= sm ? 32 : 16,
              marginTop: width >= sm ? 0 : 6,
              columnGap: 32,
              height: '100%',
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: width >= sm ? 32 : 16,
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: width >= sm ? 18 : 14,
                    textAlign: 'left',
                    width: width >= sm ? 'auto' : 150,
                  }}
                >
                  {amountLabel}
                </Text>
                <View
                  style={{
                    flexGrow: 1,
                    alignItems: 'flex-end',
                    borderBottomWidth: 0.5,
                    borderColor: Colors.border,
                  }}
                >
                  <Text
                    style={{
                      fontSize: width >= sm ? 50 : 22,
                      fontWeight: '600',
                      lineHeight: width >= sm ? 50 : 22,
                    }}
                  >
                    ¥{numberWithDelimiter(number)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  backgroundColor: Colors.primary10,
                  padding: 16,
                }}
              >
                <Text
                  style={{
                    fontSize: width >= sm ? 16 : 12,
                    fontWeight: '300',
                    lineHeight: width >= sm ? 24 : 18,
                    textAlign: 'left',
                  }}
                >
                  {description}
                </Text>
              </View>
              {showCancelButton && width < sm && (
                <View style={{ alignItems: 'flex-end', marginTop: 12 }}>
                  <Button
                    mode="text"
                    style={{ columnGap: 6, paddingHorizontal: 0 }}
                    onPress={handleCancel}
                  >
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size={16}
                      color={Colors.caution}
                    />
                    <Text style={{ color: Colors.caution, fontSize: 14 }}>
                      {t('スマート決済をやめる')}
                    </Text>
                  </Button>
                </View>
              )}
            </View>
            {width >= sm && (
              <View style={{ flex: 1, height: '100%' }}>
                <Calculator
                  value={number}
                  setValue={handleChangeNumber}
                  disabledNumber={disableCalculator}
                  disableDelKey={disableCalculator}
                  disableClearKey={disableCalculator}
                />
              </View>
            )}
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: width >= sm ? 124 : undefined,
              paddingTop: width >= sm ? 0 : 16,
              paddingHorizontal: width >= sm ? 32 : undefined,
              justifyContent: 'center',
              shadowColor: Colors.black,
              shadowOffset: {
                width: 0,
                height: -10,
              },
              shadowOpacity: 0.16,
              backgroundColor: width >= sm ? Colors.field : Colors.white,
            }}
          >
            {width < sm && (
              <View
                style={{ marginBottom: 12, paddingHorizontal: 16, height: 252 }}
              >
                <Calculator
                  value={number}
                  setValue={handleChangeNumber}
                  disabledNumber={disableCalculator}
                  disableDelKey={disableCalculator}
                  disableClearKey={disableCalculator}
                />
              </View>
            )}
            <View
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: 32,
                },
                width < sm && {
                  backgroundColor: Colors.bgBlack,
                },
              ]}
            >
              {width >= sm && (
                <View style={{ flex: 1, height: width >= sm ? 72 : 48 }} />
              )}
              <View
                style={[
                  width < sm && {
                    marginVertical: 16,
                    marginHorizontal: 54,
                  },
                  {
                    flex: 1,
                    height: width >= sm ? 72 : 48,
                  },
                ]}
              >
                <Button
                  loading={loading}
                  style={{ flex: 1, height: width >= sm ? 72 : 48 }}
                  onPress={async () => {
                    try {
                      setLoading(true)
                      const num = parseInt(number)
                      await onSubmit(num)
                    } finally {
                      setLoading(false)
                    }
                  }}
                  disabled={[0, defaultAmount].includes(Number(number))}
                >
                  {submitLabel}
                </Button>
              </View>
            </View>
          </View>
        </View>
      </ModalCenter>
      <ConfirmCancelSmartPaymentModal
        isModalVisible={isVisibleConfirmCancel}
        onPressCancel={() => {
          setIsVisibleConfirmCancel(false)
          InteractionManager.runAfterInteractions(() => {
            setIsVisiblePaymentModal(true)
          })
        }}
        onPressSubmit={onCancel}
      />
    </>
  )
}
