import { flatMap } from 'lodash'
import React, { useMemo, useState } from 'react'

import { ScrollView, View } from 'react-native'

import { useCustomersBulk } from '../../../models/Customer'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import {
  ReservationStepper,
  ReservationStepperBackButton,
  ReservationStepperNextButton,
} from '@hello-ai/ar_shared/src/components/ReservationStepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'
import { ReservationRep, ReservationRepContainer } from '../RepView'
import {
  Customer,
  ReservationCoursesAndMemo,
  ReservationDetails,
} from '../ReservationDetails'
import { ReservationFormStepProps } from './types'
import {
  useRestaurantCourses,
  RestaurantCourse as RestaurantCourseModel,
} from '../../../models/RestaurantCourse'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'

export function Confirmation({
  restaurantId,
  currentStep,
  skipSteps,
  requestReservation,
  state,
  onPressGoBack,
  onPressNext,
}: ReservationFormStepProps & { restaurantId: number }) {
  const { width, sm } = useResponsive()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    dateString,
    startTime,
    endTime,
    adultPartySize,
    childPartySize,
    selectedSeatIds,
    selectedCustomerIds,
    selectedReservationCourses,
    allergy,
    memo,
    reservationRep,
  } = state

  const date = dayjs(dateString)

  const { customers } = useCustomersBulk(restaurantId, {
    ids: selectedCustomerIds,
  })

  const { restaurantCourses: tableCourses } = useRestaurantCourses(restaurantId)

  const reservationCourses = useMemo(() => {
    if (tableCourses === undefined) return []
    const reservationCourses: Array<{
      partySize: number
      restaurantCourse: RestaurantCourseModel | undefined
    }> = flatMap(
      selectedReservationCourses,
      ({ partySize, restaurantCourseId }) => {
        if (partySize === 0 || restaurantCourseId == null) return []

        return {
          partySize,
          restaurantCourse: tableCourses.find(
            ({ id }: { id: number }) => id === restaurantCourseId
          ),
        }
      }
    )
    return reservationCourses
  }, [selectedReservationCourses, tableCourses])

  const submit = async () => {
    setIsSubmitting(true)
    await onPressNext({})
  }

  const onPressNextButton = (
    <AsyncButton
      style={{
        width: 248,
        height: 45,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      textStyle={{
        color: 'white',
        fontWeight: '600',
        fontSize: 14,
      }}
      onPress={async () => {
        await submit()
      }}
    >
      {t('確定')}
    </AsyncButton>
  )

  const onPressGoBackButton = (
    <ReservationStepperBackButton
      onPressGoBack={onPressGoBack}
      text={t('詳細の入力に戻る')}
    />
  )

  return (
    <ReservationStepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={submit}
      onPressGoBackButton={onPressGoBackButton}
      onPressNextButton={onPressNextButton}
      isDisabledNextPress={isSubmitting}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          paddingHorizontal: width < sm ? 16 : 160,
          paddingVertical: width < sm ? 24 : 48,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
            }}
          >
            {t('予約内容の確認')}
          </Text>
          {reservationRep && (
            <ReservationRepContainer>
              <ReservationRep
                label={t('受付担当者')}
                name={reservationRep.name}
              />
            </ReservationRepContainer>
          )}
        </View>
        <ShadowBox
          style={{
            marginTop: 24,
            marginBottom: width > sm ? 80 : 0,
          }}
        >
          <ReservationDetails
            restaurantId={restaurantId}
            date={date}
            startTime={startTime}
            endTime={endTime}
            adultPartySize={adultPartySize}
            childPartySize={childPartySize}
            tableSeatIds={selectedSeatIds}
            reservationChildDetails={undefined}
          />

          {customers &&
            customers.map((customer) => {
              let displayName: string | undefined
              if (
                requestReservation != null &&
                customer.id === requestReservation.customer?.id &&
                requestReservation.name != null
              ) {
                displayName = t('{{name}}様', { name: requestReservation.name })
              }
              return (
                <View
                  key={customer.id}
                  style={{
                    borderTopWidth: 1,
                    borderColor: Colors.border,
                    marginTop: 24,
                    paddingTop: 24,
                  }}
                >
                  <Customer customer={customer} displayName={displayName} />
                </View>
              )
            })}
          <View
            style={{
              borderTopWidth: 1,
              borderColor: Colors.border,
              marginTop: 24,
              paddingTop: 24,
            }}
          >
            <ReservationCoursesAndMemo
              restaurantId={restaurantId}
              reservationCourses={reservationCourses.map(
                (reservationCourse) => {
                  return {
                    partySize: reservationCourse.partySize,
                    restaurantCourse: reservationCourse.restaurantCourse,
                    restaurantCourseQuestionAnswers: [], // TODO: 予約作成でコースの質問に対応したら実装
                  }
                }
              )}
              allergy={allergy}
              memo={memo}
            />
          </View>
        </ShadowBox>
      </ScrollView>
    </ReservationStepper>
  )
}
