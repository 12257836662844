import { TableProductOptionBox } from '../../models/TableProductOptionBox'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import React, { useMemo, useReducer, useEffect } from 'react'
import { goBack } from '../../modules/history'
import { ScrollView, View } from 'react-native'
import { times } from 'lodash'
import { SelectedImage, SelectImage } from '../Shared/SelectImage'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { TableProductOption } from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'
import { useNavigate } from 'react-router-dom'
import produce from 'immer'
import { useRestaurantId } from 'modules/useRestaurantId'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Trans } from 'react-i18next'

type OptionBoxesState = Exclude<
  TableProductOptionBox,
  'table_product_options'
> & {
  table_product_options: Array<
    TableProductOption & {
      _destroy: '1' | null
      newImage: any | null
      removeImage: any | null
    }
  >
}

type OptionState = TableProductOption & {
  _destroy: '1' | null
  newImage: any | null
  removeImage: any | null
}

const initialOptionState: OptionState = {
  id: '',
  name: '',
  description: '',
  prices: {},
  additional_description: '',
  image: null,
  price_delta: 0,
  position: 0,
  newImage: null,
  removeImage: null,
  _destroy: null,
  in_stock: true,
}

const initialOptionBoxState: OptionBoxesState = {
  id: '',
  description: '',
  created_at: '',
  product_option_box_relationship_id: '',
  required: false,
  name: '',
  position: 0,
  additional_description: '',
  min_quantity: 1,
  max_quantity: 1,
  table_product_options: [initialOptionState],
}

function filterOptions(options: any[]) {
  return options.filter(({ _destroy }) => _destroy !== '1')
}

function optionBoxesReducer(state: OptionBoxesState, action: any): any {
  return produce(state, (draft) => {
    const { payload } = action
    switch (action.type) {
      case 'INITIALIZE':
        draft = payload
        break
      case 'CHANGE_OPTION_BOX_REQUIRED': {
        const { required } = payload
        draft.required = required
        break
      }
      case 'CHANGE_OPTION_BOX_NAME': {
        const { name } = payload
        draft.name = name
        break
      }
      case 'CHANGE_OPTION_BOX_ADDITIONAL_DESCRIPTION': {
        const { additionalDescription } = payload
        draft.additional_description = additionalDescription
        break
      }
      case 'CHANGE_OPTION_BOX_TYPE': {
        const { type } = payload
        if (type === 'radio') {
          draft.min_quantity = 1
          draft.max_quantity = 1
        } else {
          draft.min_quantity = 1
          draft.max_quantity = 2
        }
        break
      }
      case 'SET_OPTION_BOX_MIN_QUANTITY': {
        const { minQuantity } = payload
        draft.min_quantity = minQuantity
        break
      }
      case 'SET_OPTION_BOX_MAX_QUANTITY': {
        const { maxQuantity } = payload
        draft.max_quantity = maxQuantity
        break
      }
      case 'ADD_OPTION': {
        draft.table_product_options.push(initialOptionState)
        break
      }
      case 'REMOVE_OPTION': {
        const { optionIndex } = payload

        if (draft.table_product_options[optionIndex].id) {
          draft.table_product_options[optionIndex]._destroy = '1'
        } else {
          draft.table_product_options.splice(optionIndex, 1)
        }
        const maxQuantity = filterOptions(draft.table_product_options).length

        // オプションを削除すると、選択可能な最小・最大個数が変化するので、超えないように調整する
        if (draft.min_quantity > maxQuantity) {
          draft.min_quantity = maxQuantity
        }

        if (draft.max_quantity > maxQuantity) {
          draft.max_quantity = maxQuantity
        }

        break
      }
      case 'CHANGE_OPTION_NAME': {
        const { optionIndex, name } = payload
        draft.table_product_options[optionIndex].name = name
        break
      }
      case 'CHANGE_OPTION_DESCRIPTION': {
        const { optionIndex, description } = payload
        draft.table_product_options[optionIndex].description = description
        break
      }
      case 'SET_NEW_IMAGE': {
        const { optionIndex, image } = payload
        draft.table_product_options[optionIndex].newImage = image
        break
      }
      case 'CHANGE_REMOVE_IMAGE': {
        const { optionIndex, removeImage } = payload
        draft.table_product_options[optionIndex].removeImage = removeImage
        break
      }
      case 'CHANGE_OPTION_PRICE': {
        const { optionIndex, price } = payload
        draft.table_product_options[optionIndex].price_delta = price
        break
      }
      case 'CHANGE_OPTION_PRICE_CALCULATION': {
        const { optionIndex, value } = payload
        draft.table_product_options[optionIndex].price_delta = value

        break
      }
      case 'CHANGE_OPTION_POSITION': {
        const { optionIndex, position } = payload
        draft.table_product_options[optionIndex].position = position
        break
      }
    }
    return draft
  })
}

const initCopyOptionBox = (optionBox: TableProductOptionBox) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, table_product_options, name, ...rest } = optionBox
  return {
    ...rest,
    name: t('{{text}}のコピー', { text: name }),
    table_product_options: table_product_options.map((opt) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = opt
      return rest
    }),
  }
}

function TableProductOptionBoxFormContent({
  tableProductOptionBox,
  createTableProductOptionBox,
  updateTableProductOptionBox,
  onPressCopy,
  isCopy,
}: {
  tableProductOptionBox?: TableProductOptionBox
  updateTableProductOptionBox: ({
    tableProductOptionBoxId,
    params,
  }: {
    tableProductOptionBoxId: string
    params: any
  }) => void
  createTableProductOptionBox: ({ params }: { params: any }) => void
  onPressCopy: (tableProductOptionBoxId: string) => void
  isCopy: boolean
}) {
  const { width, sm } = useResponsive()
  const navigate = useNavigate()
  const restaurantId = useRestaurantId()
  const [optionBox, optionBoxDispatch] = useReducer(
    optionBoxesReducer,
    tableProductOptionBox ?? initialOptionBoxState
  )

  const onArchived = async () => {
    if (tableProductOptionBox?.id == null) {
      return
    }

    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          await updateTableProductOptionBox({
            tableProductOptionBoxId: tableProductOptionBox.id,
            params: {
              status: 'archived',
            },
          })
          goBack('..')
        },
      },
    ])
  }

  const onPress = async () => {
    const { table_product_options, ...rest } = optionBox
    const optionBoxParams = {
      ...rest,
      table_product_options_attributes: table_product_options.map(
        (opt: OptionState) => {
          const newOpt = { ...opt }
          if (newOpt.newImage != null) {
            newOpt.image = newOpt.newImage
          } else {
            if (newOpt.removeImage != null) {
              newOpt.image = null
            }
          }
          return newOpt
        }
      ),
    }

    if (optionBox.name == null) {
      Alert.alert(t('オプション名を入力してください'))
      return
    }

    if (filterOptions(optionBox.table_product_options).length === 0) {
      Alert.alert(t('オプションの選択肢を追加してください'))
      return
    }

    const optionsValid = filterOptions(optionBox.table_product_options).every(
      (option) => {
        if (option.name === '') {
          return false
        }
        if (option.price_delta === '') {
          return false
        }
        return option.position !== ''
      }
    )

    if (!optionsValid) {
      Alert.alert(t('オプションの全ての項目を埋めてください'))
      return
    }

    if (tableProductOptionBox?.id != null && !isCopy) {
      await updateTableProductOptionBox({
        tableProductOptionBoxId: tableProductOptionBox.id,
        params: optionBoxParams,
      })
      goBack('..')
      displayToastInfo(t('更新しました'))
      return
    }

    await createTableProductOptionBox({
      params: { ...optionBoxParams, position: '1' }, // APIのエラー治ったら消す
    })

    navigate(`/restaurants/${restaurantId}/product_option_boxes`)
    displayToastInfo(t('追加しました'))
  }

  const optionBoxType = useMemo(() => {
    return optionBox.min_quantity === 1 && optionBox.max_quantity === 1
      ? 'radio'
      : 'check'
  }, [optionBox])

  const alertRemoveOption = (optionIndex: number) => {
    if (filterOptions(optionBox.table_product_options).length === 1) {
      Alert.alert(t('選択肢は必ず一つ存在する必要があります'))
      return
    }
    optionBoxDispatch({
      type: 'REMOVE_OPTION',
      payload: {
        optionIndex,
      },
    })
  }

  useEffect(() => {
    if (!tableProductOptionBox) return

    if (isCopy) {
      optionBoxDispatch({
        type: 'INITIALIZE',
        payload: initCopyOptionBox(tableProductOptionBox),
      })
    } else {
      optionBoxDispatch({
        type: 'INITIALIZE',
        payload: tableProductOptionBox,
      })
    }
  }, [isCopy, tableProductOptionBox])

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={[
        width < sm
          ? {
              paddingHorizontal: 16,
              paddingVertical: 20,
            }
          : {
              paddingHorizontal: 36,

              paddingVertical: 40,
            },
      ]}
    >
      <ShadowBox
        style={[
          {
            marginTop: 24,
            padding: 32,
          },
        ]}
      >
        <View
          style={[
            {
              marginTop: 24,
            },
            width >= sm && {
              flexDirection: 'row',
              alignItems: 'center',
            },
          ]}
        >
          <TextInput
            placeholder={t('オプション名')}
            value={optionBox.name}
            onChangeText={(text) =>
              optionBoxDispatch({
                type: 'CHANGE_OPTION_BOX_NAME',
                payload: { name: text },
              })
            }
            autoCapitalize="none"
            style={{ flex: 1 }}
          />
        </View>
        <TextInput
          placeholder={t('オプション説明')}
          value={optionBox.additional_description}
          onChangeText={(text) =>
            optionBoxDispatch({
              type: 'CHANGE_OPTION_BOX_ADDITIONAL_DESCRIPTION',
              payload: { additionalDescription: text },
            })
          }
          autoCapitalize="none"
          style={{ marginTop: 24 }}
        />
        <View style={{ marginTop: 24, flexDirection: 'row' }}>
          <TouchableOpacity
            style={{
              borderWidth: optionBoxType === 'radio' ? 2 : 1,
              borderColor:
                optionBoxType === 'radio' ? Colors.accent : Colors.border,
              justifyContent: 'center',
              alignItems: 'center',
              height: 52,
              flex: 1,
              marginRight: 10,
              borderRadius: 4,
            }}
            onPress={() =>
              optionBoxDispatch({
                type: 'CHANGE_OPTION_BOX_TYPE',
                payload: { type: 'radio' },
              })
            }
          >
            <Text style={[optionBoxType === 'radio' && { fontWeight: '600' }]}>
              {t('単一選択')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              borderWidth: optionBoxType === 'check' ? 2 : 1,
              borderColor:
                optionBoxType === 'check' ? Colors.accent : Colors.border,
              justifyContent: 'center',
              alignItems: 'center',
              height: 52,
              flex: 1,
              marginLeft: 10,
              borderRadius: 4,
            }}
            onPress={() =>
              optionBoxDispatch({
                type: 'CHANGE_OPTION_BOX_TYPE',
                payload: { type: 'check' },
              })
            }
          >
            <Text style={[optionBoxType === 'check' && { fontWeight: '600' }]}>
              {t('複数選択')}
            </Text>
          </TouchableOpacity>
        </View>
        {optionBoxType === 'check' && (
          <View
            style={[
              {
                marginTop: 24,
              },
              width >= sm && {
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              {t('選択する個数')}
            </Text>
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                },
                width < sm && {
                  marginTop: 16,
                },
              ]}
            >
              {/* eslint-disable ar-i18n/require-translation-ja */}
              <Trans i18nKey="<0></0><1>個から</1><2></2><3>個</3>">
                <SelectInput
                  selectedValue={optionBox.min_quantity}
                  setValue={(value) =>
                    optionBoxDispatch({
                      type: 'SET_OPTION_BOX_MIN_QUANTITY',
                      payload: { minQuantity: value },
                    })
                  }
                  items={times(
                    filterOptions(optionBox.table_product_options).length + 1,
                    (i) => {
                      return {
                        label: i.toString(),
                        value: i,
                      }
                    }
                  )}
                />
                <Text style={{ marginHorizontal: 8 }}>{'個から'}</Text>
                <SelectInput
                  selectedValue={optionBox.max_quantity}
                  setValue={(value) =>
                    optionBoxDispatch({
                      type: 'SET_OPTION_BOX_MAX_QUANTITY',
                      payload: { maxQuantity: value },
                    })
                  }
                  items={times(
                    filterOptions(optionBox.table_product_options).length + 1,
                    (i) => {
                      return {
                        label: i.toString(),
                        value: i,
                      }
                    }
                  )}
                />
                <Text style={{ marginLeft: 8 }}>{'個'}</Text>
              </Trans>
              {/* eslint-enable ar-i18n/require-translation-ja */}
            </View>
          </View>
        )}
        {optionBox.table_product_options.map(
          (option: OptionState, optionIndex: number) => {
            if (option._destroy === '1') return null
            // optionIndexを保持するため外側でfilterしない
            return (
              <View
                style={[
                  {
                    marginTop: 24,
                  },

                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
                key={`option${optionIndex}`}
              >
                <View
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  {option.newImage != null ||
                  (option.image != null && option.removeImage == null) ? (
                    <SelectedImage
                      source={{
                        uri: option.newImage ?? option.image.sm,
                      }}
                      onRemovePress={() => {
                        optionBoxDispatch({
                          type: 'SET_NEW_IMAGE',
                          payload: {
                            optionIndex,
                            image: null,
                          },
                        })

                        optionBoxDispatch({
                          type: 'CHANGE_REMOVE_IMAGE',
                          payload: {
                            optionIndex,
                            removeImage: true,
                          },
                        })
                      }}
                    />
                  ) : (
                    <SelectImage
                      onSelectImage={(image) => {
                        optionBoxDispatch({
                          type: 'SET_NEW_IMAGE',
                          payload: {
                            optionIndex,
                            image,
                          },
                        })

                        optionBoxDispatch({
                          type: 'CHANGE_REMOVE_IMAGE',
                          payload: {
                            optionIndex,
                            removeImage: false,
                          },
                        })
                      }}
                    />
                  )}
                </View>
                <View
                  style={[
                    { flex: 1 },
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 20,
                        },
                  ]}
                >
                  <TextInput
                    placeholder={t('選択肢名')}
                    value={option.name}
                    onChangeText={(text) =>
                      optionBoxDispatch({
                        type: 'CHANGE_OPTION_NAME',
                        payload: {
                          optionIndex,
                          name: text,
                        },
                      })
                    }
                    autoCapitalize="none"
                  />
                  <View
                    style={[
                      {
                        marginTop: 20,
                      },
                      width >= sm && {
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                    ]}
                  >
                    <View
                      style={[
                        {
                          flexDirection: 'row',
                          alignItems: 'center',
                        },
                        width >= sm && {
                          flex: 3,
                        },
                      ]}
                    >
                      <View
                        style={[
                          {
                            flex: width < sm ? 0.5 : 0.8,
                            marginRight: width < sm ? 10 : 20,
                          },
                        ]}
                      >
                        <SegmentedControl
                          buttons={[
                            { label: t('＋円'), value: true },
                            { label: t('ー円'), value: false },
                          ].map(({ label, value }) => {
                            return {
                              text: label,
                              selected: value
                                ? option.price_delta >= 0
                                : option.price_delta < 0,
                              onPress: () => {
                                optionBoxDispatch({
                                  type: 'CHANGE_OPTION_PRICE_CALCULATION',
                                  payload: {
                                    optionIndex,
                                    value:
                                      Math.abs(option.price_delta) *
                                      (value ? 1 : -1),
                                  },
                                })
                              },
                            }
                          })}
                        />
                      </View>
                      <TextInput
                        placeholder={t('選択肢価格')}
                        value={
                          (option.price_delta || '') !== ''
                            ? Math.abs(option.price_delta).toString()
                            : ''
                        }
                        onChangeText={(text) =>
                          optionBoxDispatch({
                            type: 'CHANGE_OPTION_PRICE',
                            payload: {
                              optionIndex,
                              price: text,
                            },
                          })
                        }
                        autoCapitalize="none"
                        keyboardType="number-pad"
                        style={{ flex: width < sm ? 1 : 2 }}
                      />
                    </View>
                    <TextInput
                      placeholder={t('選択肢順番')}
                      value={String(option.position)}
                      onChangeText={(text) =>
                        optionBoxDispatch({
                          type: 'CHANGE_OPTION_POSITION',
                          payload: {
                            optionIndex,
                            position: text,
                          },
                        })
                      }
                      autoCapitalize="none"
                      style={[
                        { flex: 1 },
                        width < sm
                          ? {
                              marginTop: 20,
                            }
                          : { marginLeft: 20 },
                      ]}
                      keyboardType="number-pad"
                    />
                  </View>
                  <TextInput
                    placeholder={t('選択肢説明')}
                    value={option.description}
                    onChangeText={(text) =>
                      optionBoxDispatch({
                        type: 'CHANGE_OPTION_DESCRIPTION',
                        payload: {
                          optionIndex,
                          description: text,
                        },
                      })
                    }
                    autoCapitalize="none"
                    style={{
                      marginTop: 24,
                      // flex: 1,
                    }}
                  />
                </View>
                {width < sm ? (
                  <Button
                    style={{
                      marginTop: 20,
                      height: 48,
                      borderColor: Colors.border,
                      backgroundColor: 'transparent',
                    }}
                    onPress={() => {
                      alertRemoveOption(optionIndex)
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.black,
                        fontWeight: 'normal',
                      }}
                    >
                      {t('選択肢を削除する')}
                    </Text>
                  </Button>
                ) : (
                  <TouchableOpacity
                    style={[{ marginLeft: 20 }]}
                    onPress={() => alertRemoveOption(optionIndex)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size={24}
                      color={Colors.secondaryBlack}
                    />
                  </TouchableOpacity>
                )}
              </View>
            )
          }
        )}

        <Button
          style={{
            marginTop: 40,
            height: 48,
            borderColor: Colors.primary,
            backgroundColor: 'transparent',
          }}
          onPress={() =>
            optionBoxDispatch({
              type: 'ADD_OPTION',
            })
          }
        >
          <Text
            style={{
              color: Colors.primary,
              fontWeight: 'normal',
            }}
          >
            {t('選択肢を追加')}
          </Text>
        </Button>
        <View
          style={{
            borderTopWidth: 1,
            borderColor: Colors.border,
            marginTop: 40,
            paddingTop: 24,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderLeftWidth: 1,
              borderColor: Colors.border,
              paddingLeft: 24,
              marginLeft: 24,
            }}
          >
            <Text style={{ marginRight: 8, marginTop: 2 }}>{t('必須')}</Text>
            <Switch
              onValueChange={() =>
                optionBoxDispatch({
                  type: 'CHANGE_OPTION_BOX_REQUIRED',
                  payload: {
                    required: !(optionBox.required as boolean),
                  },
                })
              }
              value={optionBox.required}
            />
          </View>
        </View>
      </ShadowBox>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {tableProductOptionBox?.id != null && !isCopy && (
          <Button
            style={{
              flex: 1,
              marginRight: 20,
              height: 48,
              borderColor: Colors.caution,
              backgroundColor: 'transparent',
            }}
            onPress={async () => {
              await onArchived()
            }}
          >
            <Text
              style={{
                color: Colors.caution,
                fontWeight: 'normal',
              }}
            >
              {t('非表示にする')}
            </Text>
          </Button>
        )}
        {tableProductOptionBox?.id != null && !isCopy && (
          <Button
            style={{
              flex: 1,
              marginRight: 20,
              height: 48,
              borderColor: Colors.primary,
              backgroundColor: 'transparent',
            }}
            onPress={() => onPressCopy(tableProductOptionBox?.id)}
          >
            <Text
              style={{
                color: Colors.primary,
                fontWeight: 'normal',
              }}
            >
              {t('コピーする')}
            </Text>
          </Button>
        )}
        <Button
          style={{
            flex: 1,
            height: 48,
          }}
          onPress={async () => {
            await onPress()
          }}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: '600',
            }}
          >
            {tableProductOptionBox?.id != null && !isCopy
              ? t('更新する')
              : t('追加する')}
          </Text>
        </Button>
      </View>
    </ScrollView>
  )
}

export default TableProductOptionBoxFormContent
