import React, { useEffect, useState } from 'react'
import { Colors } from '../../../../constants/Colors'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { View } from 'react-native'
import { Text } from '../../../Text'
import { Popover } from '../../../Popover'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { TouchableOpacity } from '../../../Touchables'

interface SectionTitleProps {
  title: string
  helperText?: string
}

export default function SectionTitle(props: SectionTitleProps) {
  const [isOpenPop, setIsOpenPop] = useState(false)

  useEffect(() => {
    if (isOpenPop === false) return
    const timer = setTimeout(() => {
      setIsOpenPop(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [isOpenPop])

  return (
    <View
      style={{
        marginBottom: 16,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text
        style={[
          {
            fontSize: 22,
            fontWeight: '600',
            lineHeight: 33,
            color: Colors.black,
          },
          props.helperText != null && { marginRight: 16 },
        ]}
      >
        {props.title}
      </Text>
      {props.helperText != null && (
        <Popover
          isOpen={isOpenPop}
          onClose={() => setIsOpenPop(false)}
          anchor={
            <TouchableOpacity onPress={() => setIsOpenPop(true)}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size={24}
                color={Colors.primary}
              />
            </TouchableOpacity>
          }
        >
          <Text
            style={{
              width: 400,
              fontWeight: '300',
              color: Colors.black,
              padding: 24,
              backgroundColor: Colors.white,
            }}
          >
            {props.helperText}
          </Text>
        </Popover>
      )}
    </View>
  )
}
