import React from 'react'
import { ScrollView, View } from 'react-native'

import { AspectImage } from '@hello-ai/ar_shared/src/components/AspectImage'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

interface IconProps {
  restaurant: Pick<Restaurant, 'name' | 'logo'>
  width: number
  size: keyof NonNullable<Restaurant['logo']>
}

function Icon({
  restaurant,
  width,
  size = 'lg',
}: React.PropsWithChildren<IconProps>) {
  if (restaurant.logo) {
    return (
      <Image
        source={{ uri: restaurant.logo[size] }}
        style={{
          width,
          height: width,
          borderRadius: width / 2,
        }}
      />
    )
  } else {
    return (
      <View
        style={{
          backgroundColor: Colors.primary,
          width,
          height: width,
          borderRadius: width / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: 'white',
            fontWeight: '600',
            fontSize: 12,
            lineHeight: 26,
          }}
        >
          {restaurant.name.charAt(0)}
        </Text>
      </View>
    )
  }
}

export interface ButtonMessageItem {
  type: 'button'
  value: {
    text: string | null
    url: string | null
  } | null
}

interface BasicMessageItem {
  type: 'photo' | 'text'
  value: string
}

export type MessageItem = BasicMessageItem | ButtonMessageItem

export interface PreviewData {
  delivery_at: string | null
  contents: MessageItem[]
}

export const APP_MESSAGE_BUTTON_STYLE = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  backgroundColor: Colors.bgBlack,
  flex: 1,
  minWidth: 100,
}
interface PreviewContentProps {
  restaurant: IconProps['restaurant']
  previewData: MessageItem[]
}

export default function MessagePreviewContent({
  restaurant,
  previewData,
}: PreviewContentProps) {
  return (
    <View
      style={{
        alignSelf: 'center',
        borderColor: Colors.border,
        borderWidth: 0.5,
        height: '100%',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          borderColor: Colors.border,
          borderBottomWidth: 0.5,
          paddingVertical: 12,
          paddingHorizontal: 16,
        }}
      >
        <Icon restaurant={restaurant} width={32} size="sm" />
        <View style={{ justifyContent: 'center', marginLeft: 8 }}>
          <Text
            style={{ fontWeight: '600', fontSize: 14, color: Colors.black }}
          >
            {restaurant.name}
          </Text>
        </View>
      </View>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          marginLeft: 35,
          minHeight: '75%',
        }}
      >
        {previewData.map((content, index) => {
          return (
            <View key={index} style={{ position: 'relative' }}>
              {content.type === 'text' && (
                <View
                  style={[
                    {
                      width: 262,
                      minHeight: 80,
                      paddingHorizontal: 12,
                      paddingVertical: 8,
                      borderTopStartRadius: 20,
                      borderTopEndRadius: 20,
                      borderBottomStartRadius: 4,
                      backgroundColor: Colors.black6,
                      marginTop: 16,
                      marginBottom: 0,
                    },
                    previewData[index + 1]?.type !== 'button' && {
                      borderBottomEndRadius: 20,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontWeight: '300',
                      fontSize: 14,
                    }}
                  >
                    {content.value}
                  </Text>
                </View>
              )}
              {content.type === 'photo' && (
                <View style={{ marginTop: 16 }}>
                  <AspectImage
                    style={{ width: 180, aspectRatio: 4 / 3 }}
                    source={{ uri: content.value }}
                    resizeMode="contain"
                  />
                </View>
              )}
              {content.type === 'button' && (
                <Button
                  variant="secondary"
                  style={[
                    APP_MESSAGE_BUTTON_STYLE,
                    {
                      width:
                        previewData[index - 1]?.type === 'text' ? 262 : 180,
                    },
                  ]}
                >
                  {content.value?.text}
                </Button>
              )}
              {previewData.length - 1 === index && (
                <View style={{ position: 'absolute', left: -30, bottom: 0 }}>
                  <Icon restaurant={restaurant} width={24} size="sm" />
                </View>
              )}
            </View>
          )
        })}
      </ScrollView>
    </View>
  )
}
