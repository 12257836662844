import { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import useSWR, { swrKey, fetcher } from '../modules/swr'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useMemo, useState } from 'react'

interface SmartPaymentHistory {
  id: string
  amount: number
  history_type: 'payment' | 'extra_payment' | 'refund'
  reason: string
  restaurant_crew_member: {
    id: string
    name: string
    username: string
    phone_number: string
    email: string
  }
  status: 'succeeded' | 'failed'
}

export interface SmartPayment {
  id: string
  amount: number
  paid_at: string
  reason: string
  reservation: {
    uuid: string
    start_at: string
    customers: {
      id: string
      name: string
      username: string
      phone_number: string
      email: string
      reservation_first_name: string
      reservation_last_name: string
      phone_number_format: string
    }[]
    reservation_courses: {
      id: string
      party_size: number
      restaurant_course: {
        id: number
        title: string
        price: number
        tax_included: boolean
        tax_type: string
        price_unit: string
      }
    }[]
  }
  smart_payment_histories: SmartPaymentHistory[]
  status:
    | 'not_authorized'
    | 'authorized'
    | 'paid'
    | 'canceled'
    | 'failed'
    | 'require_three_d_secure'
}

interface RestaurantSmartPayment {
  amount: number
  smart_payments: SmartPayment[]
}

export function useRestaurantSmartPaymentHistory(
  restaurantId: number,
  params: {
    target_date: Dayjs
  }
) {
  const token = useToken()
  const _params = {
    target_date: params.target_date.format('YYYY-MM-DD'),
  }

  const { data: _data, error } = useSWR<
    RestaurantSmartPayment,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/smart_payments`,
      _params
    ),
    ([token, url]) => fetcher([token, url, _params])
  )

  const perPage = 4

  const [page, setPage] = useState(1)
  const pagination = useMemo(() => {
    const startIndex = (page - 1) * perPage
    const endIndex = startIndex + perPage
    const smartPayments = _data?.smart_payments.slice(startIndex, endIndex)
    const totalPages =
      _data == null
        ? undefined
        : Math.ceil(_data?.smart_payments.length / perPage)
    return {
      currentPage: page,
      totalPages,
      totalCount: _data?.smart_payments.length,
      smart_payments: smartPayments,
      setPage,
    }
  }, [_data, page])

  return {
    data: {
      ..._data,
      smart_payments: pagination?.smart_payments,
    },
    error,
    pagination,
  }
}
