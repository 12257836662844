import React, { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { Tabs, TabProps, TabContent } from 'components/Shared/Navigation/Tab'
import Reservations, {
  DateStateProvider,
} from 'pages/Restaurants/RestaurantReservations'

import { Route, Routes } from 'react-router'
import { Header } from '../../components/Shared/Navigation/Header'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DrawerHeaderToggle } from '../../components/Shared/Navigation/Drawer'
import { Restaurant, useRestaurant } from '../../models/Restaurant'
import { useRestaurantId } from '../../modules/useRestaurantId'
import { Unavailable } from '@hello-ai/for_r_app/src/components/Restaurant/Unavailable'
import config from '@hello-ai/for_r_app/src/modules/config'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { ListView } from '@hello-ai/for_r_app/src/components/Reservations/ListView'
import { ChartView } from '@hello-ai/for_r_app/src/components/Reservations/ChartView'
import { MapView } from '@hello-ai/for_r_app/src/components/Reservations/MapView'
import { useNavigate } from 'modules/router/useNavigate'
import { useSearchParams } from 'react-router-dom'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { HeaderSegmentedControl } from '@hello-ai/for_r_app/src/components/Reservations/HeaderSegmentedControl'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { HeaderDateSelectBar } from '@hello-ai/for_r_app/src/components/Reservations/HeaderDateSelectBar'
import { HeaderViewModeSegmentedControl } from '@hello-ai/for_r_app/src/components/Reservations/HeaderViewModeButton'
import { useRestaurantReservationActivityNotifications } from '@hello-ai/for_r_app/src/models/RestaurantReservation'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { HeaderRightButton } from '@hello-ai/for_r_app/src/components/Shared/HeaderRightButton'
import HeaderRightDailyNoteIconButton from '@hello-ai/for_r_app/src/components/Reservations/HeaderRightDailyNoteIconButton'
import DatePicker from 'components/Shared/DatePicker'

function HeaderTitle({
  date,
  onChangeDate,
}: {
  date: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
}) {
  return (
    <DatePicker
      triggerElement={({ onPress }) => (
        <TouchableOpacity
          style={{
            backgroundColor: Colors.primaryBg,
            paddingVertical: 10,
            paddingHorizontal: 16,
            borderRadius: 24,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
          }}
          onPress={onPress}
        >
          <TouchableOpacity
            onPress={() => onChangeDate(date.subtract(1, 'day'))}
            onPressMinInterval={0}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              size={18}
              color={Colors.primary}
            />
          </TouchableOpacity>
          <Text
            style={{
              marginHorizontal: 24,
              fontWeight: '600',
              fontSize: 18,
              color: Colors.primary,
            }}
          >
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            {date.format('YYYY年M月DD日(ddd)')}
          </Text>
          <TouchableOpacity
            onPress={() => onChangeDate(date.add(1, 'day'))}
            onPressMinInterval={0}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              size={18}
              color={Colors.primary}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      )}
      value={date}
      onChange={(date) => {
        if (date == null) return
        onChangeDate(dayjs(date.toDate()))
      }}
    />
  )
}

export function ReservationBookUnavailable({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  const arWebOrigin = config.isStaging
    ? 'https://staging.autoreserve.com'
    : 'https://autoreserve.com'
  return (
    <>
      <Header
        title={t('予約')}
        headerLeft={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DrawerHeaderToggle />
            <View
              style={{
                marginLeft: 24,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  height: 24,
                  paddingHorizontal: 8,
                  backgroundColor: Colors.accentBg,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: Colors.accent,
                    fontWeight: '600',
                  }}
                >
                  {restaurant?.table_restaurant_setting?.web_reservation_enabled
                    ? t('ネット予約')
                    : t('予約代行')}
                </Text>
              </View>
            </View>
          </View>
        }
      />
      <Unavailable
        description={t(
          '予約管理も集客もカンタンにできる予約台帳を利用しませんか？'
        )}
        toService={`${arWebOrigin}/for_restaurants/reservation_book`}
        toContact={`${arWebOrigin}/for_restaurants/contact`}
      />
    </>
  )
}

export function ReservationsStandardOrHigher({
  restaurant,
  currentMode,
}: {
  restaurant: Restaurant
  currentMode: 'list' | 'chart' | 'map'
}) {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { width, sm, md } = useResponsive()
  const date =
    params.get('date') != null
      ? dayjs(params.get('date'), 'YYYY-MM-DD')
      : dayjs()
  const startTimeParam = params.get('startTime')
  const startTime =
    startTimeParam != null ? parseInt(startTimeParam) ?? undefined : undefined

  const { data } = useRestaurantReservationActivityNotifications(
    restaurant.id,
    'unread',
    1
  )

  function HorizontalDivider() {
    return (
      <View
        style={{
          height: 24,
          width: 1,
          backgroundColor: Colors.border,
        }}
      />
    )
  }

  const onChangeDate = useCallback(
    (date: dayjs.Dayjs) => {
      navigate(
        `/restaurants/${restaurant.id}/reservations?date=${date.format(
          'YYYY-MM-DD'
        )}&m=${currentMode}`
      )
    },
    [navigate, restaurant.id, currentMode]
  )

  return (
    <DateStateProvider value={[date, onChangeDate]}>
      <Header
        title={
          width < sm && (
            <HeaderSegmentedControl
              viewMode={currentMode}
              onPressChart={() => {
                navigate(
                  `/restaurants/${
                    restaurant.id
                  }/reservations?date=${date.format('YYYY-MM-DD')}&m=chart`
                )
              }}
              onPressList={() => {
                navigate(
                  `/restaurants/${
                    restaurant.id
                  }/reservations?date=${date.format('YYYY-MM-DD')}&m=list`
                )
              }}
            />
          )
        }
        headerLeft={
          <View
            style={{
              marginLeft: width > sm ? 24 : 16,
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {width < md && <DrawerHeaderToggle />}
            {width >= sm && (
              <>
                {currentMode === 'map' ? (
                  <Text
                    style={{
                      flex: 1,
                      textAlign: 'right',
                      fontWeight: '600',
                      fontSize: 18,
                      color: Colors.primary,
                    }}
                  >
                    {width < sm
                      ? dayjs().format('YYYY/M/D(ddd)')
                      : // eslint-disable-next-line ar-i18n/require-translation-ja
                        dayjs().format('YYYY年M月D日(ddd)')}
                  </Text>
                ) : (
                  <>
                    <HeaderDateSelectBar
                      restaurantId={restaurant.id}
                      date={date}
                      onChangeDate={onChangeDate}
                    />
                    <View
                      style={{
                        marginLeft: 24,
                        width: 1,
                        height: '100%',
                        backgroundColor: Colors.border,
                      }}
                    />
                    <Button
                      mode="outline"
                      style={{
                        marginLeft: 24,
                        width: 120,
                        paddingVertical: 6,
                        paddingHorizontal: 16,
                        marginRight: 8,
                      }}
                      textStyle={{
                        fontSize: 14,
                        fontWeight: '300',
                        lineHeight: 24,
                      }}
                      onPress={() => {
                        onChangeDate(dayjs().tz())
                      }}
                    >
                      {t('今日を表示')}
                    </Button>
                  </>
                )}
              </>
            )}
          </View>
        }
        headerRight={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 12,
              marginRight: width > sm ? 24 : 16,
            }}
          >
            {width >= sm && (
              <HeaderViewModeSegmentedControl
                viewMode={currentMode}
                onPress={(value) => {
                  navigate(
                    `/restaurants/${restaurant.id}/reservations?date=${date.format('YYYY-MM-DD')}&m=${value}`
                  )
                }}
              />
            )}
            {width >= sm && <HorizontalDivider />}
            <HeaderRightDailyNoteIconButton
              restaurantId={restaurant.id}
              date={date}
            />
            {width >= sm && <HorizontalDivider />}
            <HeaderRightButton
              style={{ paddingRight: 0 }}
              badgeCount={data?.headerData?.totalCount}
              icon={
                <FontAwesomeIcon
                  icon={faBell}
                  size={width < sm ? 20 : 32}
                  color={Colors.primary}
                />
              }
              onPress={() =>
                navigate(
                  `/restaurants/${restaurant.id}/reservations/notifications`
                )
              }
            />
          </View>
        }
      />
      {width <= sm && (
        <View
          style={{
            paddingVertical: 8,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
          }}
        >
          <HeaderDateSelectBar
            restaurantId={restaurant.id}
            date={date}
            onChangeDate={onChangeDate}
          />
        </View>
      )}
      {currentMode === 'list' ? (
        <ListView
          restaurantId={restaurant.id}
          date={date}
          webReservationType={
            restaurant?.table_restaurant_setting?.web_reservation_type ??
            'normal'
          }
          dateString={date.format('YYYY-MM-DD')}
          onPressRequestRestaurantReservation={(requestReservation) => {
            if (requestReservation.reservation?.reservation_change_request) {
              navigate(
                `/restaurants/${restaurant.id}/reservations/change_requests/${requestReservation.reservation.uuid}`
              )
            } else {
              navigate(
                `/restaurants/${restaurant.id}/reservations/requests/${requestReservation.id}`
              )
            }
          }}
          onPressRestaurantReservation={(id) =>
            navigate(`/restaurants/${restaurant.id}/reservations/${id}`)
          }
          onPressCustomer={(id) => {
            navigate(`/restaurants/${restaurant.id}/customers/${id}`)
          }}
          onPressAddReservationButton={() =>
            navigate(
              `/restaurants/${
                restaurant.id
              }/reservations/new?dateString=${date.format('YYYY-MM-DD')}`
            )
          }
        />
      ) : currentMode === 'map' ? (
        <MapView restaurantId={restaurant.id} />
      ) : (
        <ChartView
          restaurantId={restaurant.id}
          startTime={startTime}
          date={date}
          dateString={date.format('YYYY-MM-DD')}
          onPressAddReservationButton={() =>
            navigate(
              `/restaurants/${
                restaurant.id
              }/reservations/new?dateString=${date.format('YYYY-MM-DD')}`
            )
          }
        />
      )}
    </DateStateProvider>
  )
}

export default function ReservationsTab() {
  const restaurantId = useRestaurantId()
  const [date, setDate] = useState(() => dayjs())
  const dateState = useMemo<[dayjs.Dayjs, (date: dayjs.Dayjs) => void]>(
    () => [date, setDate],
    [date]
  )

  const { restaurant } = useRestaurant(restaurantId)
  const ReservationTabs: TabProps[] = useMemo(() => {
    switch (restaurant?.table_restaurant_setting?.web_reservation_type) {
      case 'normal':
        return [
          {
            title: t('成立'),
            fullPath: '/restaurants/:id/reservations',
            relativePath: '/',
            element: <Reservations type="reserved" />,
          },
          {
            title: t('キャンセル'),
            fullPath: '/restaurants/:id/reservations/canceled',
            relativePath: '/canceled',
            element: <Reservations type="canceled" />,
          },
        ]
      case 'request':
        return [
          {
            title: t('成立'),
            fullPath: '/restaurants/:id/reservations',
            relativePath: '/',
            element: <Reservations type="reserved" />,
          },
          {
            title: t('リクエスト'),
            fullPath: '/restaurants/:id/reservations/request',
            relativePath: '/request',
            element: <Reservations type="request" />,
          },
          {
            title: t('キャンセル'),
            fullPath: '/restaurants/:id/reservations/canceled',
            relativePath: '/canceled',
            element: <Reservations type="canceled" />,
          },
        ]
      default:
        return []
    }
  }, [restaurant?.table_restaurant_setting?.web_reservation_type])

  return (
    <DateStateProvider value={dateState}>
      <View style={{ flex: 1 }}>
        <Header
          title={<HeaderTitle date={date} onChangeDate={setDate} />}
          headerLeft={
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DrawerHeaderToggle />
              <View
                style={{
                  marginLeft: 24,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    height: 24,
                    paddingHorizontal: 8,
                    backgroundColor: Colors.accentBg,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.accent,
                      fontWeight: '600',
                    }}
                  >
                    {restaurant?.table_restaurant_setting
                      ?.web_reservation_enabled
                      ? t('ネット予約')
                      : t('予約代行')}
                  </Text>
                </View>
              </View>
            </View>
          }
        />
        <Tabs tabs={ReservationTabs} />
        <TabContent>
          <Routes>
            {ReservationTabs.map((tab) => {
              return (
                <Route
                  key={tab.fullPath}
                  path={tab.relativePath}
                  element={tab.element}
                />
              )
            })}
          </Routes>
        </TabContent>
      </View>
    </DateStateProvider>
  )
}
