import React, { useMemo, useState } from 'react'
import { StyleProp, TextStyle, ViewStyle, View, Platform } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import { useConfig } from '@hello-ai/ar_shared/src/components/Config'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

const monthItems = [...Array(12)].map((_, i) => {
  return {
    value: i + 1,
    label: t('{{month}}月', { month: `${i + 1}` }),
  }
})

const defaultMaxYear = dayjs().year()
const defaultMinYear = defaultMaxYear - 10

function YearMonthInputPickerModal({
  isModalVisible,
  onClose,
  selectedValue,
  onValueChange,
  minYear = defaultMinYear,
  maxYear = defaultMaxYear,
}: {
  isModalVisible: boolean
  onClose: () => void
  selectedValue: dayjs.Dayjs
  onValueChange: (value: dayjs.Dayjs) => void
  minYear?: number
  maxYear?: number
}) {
  const { ModalComponent: Modal } = useConfig()
  const yearItems = useMemo(() => {
    if (maxYear <= minYear) {
      return [{ value: minYear, label: t('{{year}}年', { year: minYear }) }]
    }
    return [...Array(maxYear - minYear + 1)].map((_, i) => {
      return {
        value: minYear + i,
        label: t('{{year}}年', { year: `${minYear + i}` }),
      }
    })
  }, [maxYear, minYear])
  const [year, setYear] = useState(selectedValue.year())
  const [month, setMonth] = useState(selectedValue.month() + 1)

  return (
    <Modal
      isVisible={isModalVisible}
      onBackButtonPress={onClose}
      onBackdropPress={onClose}
      style={{ justifyContent: 'flex-end', margin: 0 }}
      contentContainerStyle={{
        maxHeight: '50%',
        backgroundColor: 'white',
      }}
    >
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          Platform.OS === 'web'
            ? {
                backgroundColor: 'white',
              }
            : {},
        ]}
      >
        <Button mode="text" onPress={onClose}>
          {t('キャンセル')}
        </Button>
        <Button
          mode="text"
          textBold
          onPress={() => {
            onValueChange(dayjs(`${year}-${(month + '').padStart(2, '0')}-01`))
          }}
        >
          {t('完了')}
        </Button>
      </View>
      <View
        style={[
          { flexDirection: 'row' },
          Platform.OS === 'web' ? { height: 100 } : {},
        ]}
      >
        <Picker<number>
          selectedValue={yearItems.findIndex((item) => item.value === year)}
          style={[
            { paddingVertical: 0, flex: 1 },
            Platform.OS === 'web' ? { fontSize: 14 } : {},
          ]}
          onValueChange={(index) => {
            const newYear = yearItems[index].value
            setYear(newYear)
          }}
        >
          {yearItems.map((item, index) => {
            return <Picker.Item key={index} label={item.label} value={index} />
          })}
        </Picker>
        <Picker<number>
          selectedValue={monthItems.findIndex((item) => item.value === month)}
          style={[
            { paddingVertical: 0, flex: 1 },
            Platform.OS === 'web' ? { fontSize: 14 } : {},
          ]}
          onValueChange={(index) => {
            const newMonth = monthItems[index].value
            setMonth(newMonth)
          }}
        >
          {monthItems.map((item, index) => {
            return <Picker.Item key={index} label={item.label} value={index} />
          })}
        </Picker>
      </View>
    </Modal>
  )
}

export function YearMonthPicker({
  value,
  onChange,
  style,
  labelStyle,
  disabled,
  minYear = defaultMinYear,
  maxYear = defaultMaxYear,
  renderLabel,
}: {
  value: dayjs.Dayjs
  disabled?: boolean
  onChange: (value: dayjs.Dayjs) => void
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  minYear?: number
  maxYear?: number
  renderLabel?: (value: dayjs.Dayjs) => React.ReactNode
}) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const onPress = () => {
    setIsModalVisible(true)
  }
  const onValueChange = (value: dayjs.Dayjs) => {
    onChange(value)
    setIsModalVisible(false)
  }
  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        style={[
          {
            backgroundColor: Colors.field,
            borderRadius: 8,
            height: 48,
            paddingHorizontal: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: disabled ? 0.3 : 1,
          },
          style,
        ]}
      >
        {renderLabel ? (
          renderLabel(value)
        ) : (
          <Text style={[labelStyle]}>{value.format(t('YYYY年MM月'))}</Text>
        )}
      </TouchableOpacity>
      <YearMonthInputPickerModal
        isModalVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        selectedValue={value}
        onValueChange={onValueChange}
        minYear={minYear}
        maxYear={maxYear}
      />
    </>
  )
}
