import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useState,
} from 'react'
import produce from 'immer'
import { camelCase, mapKeys, snakeCase, omitBy, isUndefined } from 'lodash'
import { ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { TableUnlimitedOrderPlan } from 'models/TableUnlimitedOrderPlan'
import { TableProduct } from 'models/TableProduct'
import { TableMenuPage } from 'models/TableMenuPage'
import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { goBack } from 'modules/history'

import { SelectedImage, SelectImage } from 'components/Shared/SelectImage'

import { SelectMenuPages } from 'components/Shared/SelectMenuPages'

import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { TableProductOptionBox } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  confirmSaveIncompleteChangesAsync,
  getSortedLocales,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  LocaleValue,
  TranslationFormItem,
  TranslationFormList,
} from '@hello-ai/for_r_app/src/components/Translation'
import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { ReplaceAll } from '@hello-ai/ar_shared/src/types/utils'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { createTranslation } from 'models/Translation'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'

function SectionTitle({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        alignSelf: 'center',
        width: '200px',
      }}
    >
      {children}
    </View>
  )
}

interface OptionState {
  id: string | null
  name: string | null
  table_product_option_box_id: string | null
  position: string
  _destroy: '1' | null
  _new?: boolean
}

function SelectOptionBox({
  position,
  name,
  onChangePosition,
  onPressDelete,
}: {
  position: string
  name: string | null
  onChangePosition: (position: string) => void
  onPressDelete: () => void
}) {
  const { width, sm } = useResponsive()
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={{ flex: 1, marginRight: 24 }}>{name}</Text>
      <TextInput
        style={{ width: width < sm ? 80 : 160 }}
        placeholder={t('選択肢順番')}
        value={String(position)}
        onChangeText={(text) => onChangePosition(text)}
        autoCapitalize="none"
        keyboardType="number-pad"
      />
      <TouchableOpacity
        style={[{ marginLeft: 20 }]}
        onPress={() => onPressDelete()}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          size={24}
          color={Colors.secondaryBlack}
        />
      </TouchableOpacity>
    </View>
  )
}

const initProductPrices = (tableProduct: TableProduct, isCopy?: boolean) => {
  const defaultTableValue = {
    provideMethod: 'table',
    price: '',
    priceType: 'fixed',
    taxType: 'default',
    enabled: true,
    taxIncluded: true,
  }

  const table = camelize(tableProduct?.prices?.table ?? {})

  return {
    table: {
      ...defaultTableValue,
      ...table,
      id: isCopy ? null : table.id,
    },
  }
}

function productPricesReducer(state: any, action: any): any {
  return produce(state, (draft: any) => {
    const { payload } = action
    switch (action.type) {
      case 'INITIALIZE':
        draft = initProductPrices(payload.data, payload.isCopy)
        break
      case 'CHANGE_PRODUCT_PRICE': {
        const { provideMethod, price } = payload
        draft[provideMethod].price = price
        break
      }
      case 'CHANGE_PRODUCT_PRICE_ENABLED': {
        const { provideMethod, enabled } = payload
        draft[provideMethod].enabled = enabled
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TYPE': {
        const { provideMethod, priceType } = payload
        draft[provideMethod].priceType = priceType
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TAX_TYPE': {
        const { provideMethod, taxType } = payload
        draft[provideMethod].taxType = taxType
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TAX_INCLUDED': {
        const { provideMethod, taxIncluded } = payload
        draft[provideMethod].taxIncluded = taxIncluded
        break
      }
    }
    return draft
  })
}

function camelize(obj: any) {
  return mapKeys(obj, (value, key) => camelCase(key))
}

function snakeize(obj: any) {
  return mapKeys(obj, (value, key) => snakeCase(key))
}

function filterOptions(options: OptionState[]) {
  return options.filter(({ _destroy }) => _destroy !== '1')
}

function ProductPriceTaxIncludedField({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (v: boolean) => void
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={[
        {
          marginTop: 20,
        },
        width >= sm && {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      ]}
    >
      <Text style={{ flex: 1 }}>{t('税込・税抜設定')}</Text>
      <View
        style={[
          { flex: 1 },
          width < sm && {
            marginTop: 16,
          },
        ]}
      >
        <SegmentedControl
          buttons={[
            { label: t('税込'), value: true },
            { label: t('税抜'), value: false },
          ].map(({ label, value }) => {
            return {
              text: label,
              selected: checked === value,
              onPress: () => onChange(value),
            }
          })}
        />
      </View>
    </View>
  )
}

interface Props {
  tableProduct?: TableProduct
  isCopy: boolean
  createProduct: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  updateProduct: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  onPressCopy: ({ tableProductId }: { tableProductId: string }) => void
  tableMenuPages?: TableMenuPage[]
  isLoadingMenuPages: boolean
  tableUnlimitedOrderPlans?: TableUnlimitedOrderPlan[]
  isLoadingUnlimitedPlans: boolean
  onPressOpenOptionBoxModal: (isVisible: boolean) => void
}

const TableProductFormContent = forwardRef(
  (
    {
      tableProduct,
      isCopy,
      createProduct,
      updateProduct,
      tableMenuPages,
      isLoadingMenuPages,
      tableUnlimitedOrderPlans,
      isLoadingUnlimitedPlans,
      onPressCopy,
      onPressOpenOptionBoxModal,
    }: Props,
    ref
  ) => {
    const { width, sm } = useResponsive()
    const token = useToken()
    const navigate = useNavigate()
    const restaurantId = useRestaurantId()
    const tableProductId = tableProduct?.id

    const preferredLocale = useRestaurantCountryLanguage()

    const sortedLocales = getSortedLocales(preferredLocale)

    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [removeImage, setRemoveImage] = useState(false)
    const defaultName = useMemo(() => {
      const name: Partial<LocaleValue> = {}

      supportedLocales.forEach((locale) => {
        const text =
          tableProduct?.[
            `name_${locale
              .replaceAll('-', '_')
              .toLowerCase()}` as `name_${Lowercase<
              ReplaceAll<typeof locale, '-', '_'>
            >}`
          ] ?? ''

        name[locale] = isCopy
          ? t('{{text}}のコピー', {
              text,
              lng: locale,
            })
          : text
      })

      return name as LocaleValue
    }, [isCopy, tableProduct])

    const [name, setName] = useFormState<LocaleValue>(defaultName)

    const [cost, setCost] = useFormState(String(tableProduct?.cost ?? ''))
    const [required, setRequired] = useFormState(tableProduct?.order_required)
    const [position, setPosition] = useFormState<number | string | undefined>(
      tableProduct?.position
    )
    const [canOrderByUser, setCanOrderByUser] = useFormState(
      tableProduct?.can_order_by_user ?? true
    )
    const [kitchenPrintName, setKitchenPrintName] = useFormState(
      isCopy
        ? t('{{text}}のコピー', { text: tableProduct?.kitchen_print_name })
        : tableProduct?.kitchen_print_name ?? ''
    )

    const defaultDescription = useMemo(() => {
      const description: Partial<LocaleValue> = {}

      supportedLocales.forEach((locale) => {
        const text =
          tableProduct?.[
            `description_${locale
              .replaceAll('-', '_')
              .toLowerCase()}` as `description_${Lowercase<
              ReplaceAll<typeof locale, '-', '_'>
            >}`
          ] ?? ''

        description[locale] = isCopy
          ? t('{{text}}のコピー', {
              text,
              lng: locale,
            })
          : text
      })

      return description as LocaleValue
    }, [isCopy, tableProduct])

    const [description, setDescription] =
      useFormState<LocaleValue>(defaultDescription)

    const [displaySize, setDisplaySize] = useFormState(
      tableProduct?.display_size ?? 'default'
    )

    const [shouldPrintReceipt, setShouldPrintReceipt] = useFormState(
      tableProduct?.should_print_receipt != null
        ? tableProduct?.should_print_receipt
        : false
    )

    const [stockType, setStockType] = useFormState(
      tableProduct?.table_product_stock_setting?.stock_type ?? 'none'
    )
    const [dailyDefaultQuantity, setDailyDefaultQuantity] = useFormState(
      tableProduct?.table_product_stock_setting?.daily_default_quantity ?? null
    )

    const [menuPages, setMenuPages] = useFormState(
      tableProduct?.table_menu_pages
        ? tableProduct?.table_menu_pages.map((page) => page.id)
        : []
    )

    const [unlimitedOrderPlans, setUnlimitedOrderPlans] = useFormState(
      tableProduct?.table_unlimited_order_plans
        ? tableProduct?.table_unlimited_order_plans.map(
            (unlimitedPlan) => unlimitedPlan.id
          )
        : []
    )

    const [
      tableProductOptionBoxAttributes,
      setTableProductOptionBoxAttributes,
    ] = useFormState<OptionState[]>(
      (tableProduct?.table_product_option_boxes ?? []).map((b) => {
        return {
          id: b.product_option_box_relationship_id,
          name: b.name,
          table_product_option_box_id: b.id,
          position: String(b.position), // TextInputに渡すためにStringに変換
          _destroy: null,
          _new: false,
        }
      })
    )

    const [productPrices, productPricesDispatch] = useReducer(
      productPricesReducer,
      tableProduct as any,
      (tableProduct) => initProductPrices(tableProduct, isCopy) as any
    ) as any

    useEffect(() => {
      productPricesDispatch({
        // TODO: 操作済みの場合は初期化しない方がいいかも
        type: 'INITIALIZE',
        payload: { data: tableProduct, isCopy },
      })
    }, [tableProduct, isCopy])

    const onPress = async () => {
      if (name[preferredLocale] === '') {
        Alert.alert(t('商品名を入力してください'))
        return
      }

      if (position === '') {
        Alert.alert(t('注文の優先順に数字を入力してください'))
        return
      }

      if (
        productPrices.table.enabled === true &&
        productPrices.table.priceType !== 'ask' &&
        productPrices.table.price === ''
      ) {
        Alert.alert(t('店内の料金を入力してください'))
        return
      }

      if (
        filterOptions(tableProductOptionBoxAttributes).some((box) => {
          return (
            box.position === '' ||
            (box.table_product_option_box_id ?? '') === ''
          )
        })
      ) {
        Alert.alert(t('オプションの項目を選択・順番を入力してください'))
        return
      }

      if (menuPages.length === 0) {
        Alert.alert(t('カテゴリーを選択してください'))
        return
      }

      // 新規追加されたが削除したものは送らない。
      const table_product_option_box_relationships_attributes =
        tableProductOptionBoxAttributes
          .filter((box) => {
            return !(box._new && box._destroy === '1')
          })
          .map((box) => {
            // 新規のものはidを送らない
            // コピーの場合もidを送らない
            if (isCopy || box._new) {
              return {
                table_product_option_box_id: box.table_product_option_box_id,
                position: Number(box.position),
              }
            }
            return {
              id: box.id,
              table_product_option_box_id: box.table_product_option_box_id,
              position: Number(box.position),
              _destroy: box._destroy,
            }
          })

      const params: any = {
        ...Object.fromEntries(
          supportedLocales.map((locale) => {
            return [
              `name_${locale.replaceAll('-', '_').toLowerCase()}`,
              name[locale],
            ]
          })
        ),
        ...Object.fromEntries(
          supportedLocales.map((locale) => {
            return [
              `description_${locale.replaceAll('-', '_').toLowerCase()}`,
              description[locale],
            ]
          })
        ),
        cost: cost.length > 0 ? Number(cost) : null,
        kitchen_print_name: kitchenPrintName,
        order_required: required,
        display_size: displaySize,
        should_print_receipt: shouldPrintReceipt,
        can_order_by_user: canOrderByUser,
        position,
        table_product_prices: [
          {
            ...snakeize(productPrices.table),
            price:
              productPrices.table.price !== '' ? productPrices.table.price : 0, // 本来は0と空白は分けて保存すべきなのだがバリデーションで弾かれるので一旦0を入れている
          },
        ],
        table_unlimited_order_plan_ids: unlimitedOrderPlans,
        table_menu_page_ids: menuPages,
        table_product_stock_setting_attributes: omitBy(
          {
            id: isCopy
              ? undefined
              : tableProduct?.table_product_stock_setting?.id ?? undefined,
            stock_type: stockType,
            daily_default_quantity:
              stockType === 'daily_reset'
                ? dailyDefaultQuantity ?? 0
                : undefined,
          },
          isUndefined
        ),
        table_product_option_box_relationships_attributes,
      }

      if (selectedImage != null) {
        params.image = selectedImage
      } else {
        if (removeImage) {
          params.image = null
        }
      }

      if (tableProductId != null && !isCopy) {
        if (token == null) {
          return
        }

        if (
          hasIncompleteChanges(name, defaultName) ||
          hasIncompleteChanges(description, defaultDescription)
        ) {
          if (!(await confirmSaveIncompleteChangesAsync(Alert.alert))) {
            return
          }
        }

        await updateProduct({ token, params })
        goBack('..')
      } else {
        if (token == null) {
          return
        }
        await createProduct({ token, params })
        navigate(`/restaurants/${restaurantId}/products`)
        displayToastInfo(t('追加しました'))
      }
    }

    const alertArchive = () => {
      const params = {
        status: 'archived',
      }
      Alert.alert(t('本当に非表示にしますか？'), '', [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            if (tableProductId == null || token == null) {
              return
            }

            await updateProduct({
              token,
              params,
            })
            goBack('..')
          },
        },
      ])
    }

    const alertPublish = () => {
      const params = {
        status: 'published',
      }
      Alert.alert(t('本当に公開状態に戻しますか？'), '', [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            if (tableProductId == null || token == null) {
              return
            }
            await updateProduct({
              token,
              params,
            })
            goBack('..')
          },
        },
      ])
    }

    useImperativeHandle(ref, () => ({
      onPressOptionBox(productOptionBox: TableProductOptionBox) {
        setTableProductOptionBoxAttributes((prev) => {
          const newTableProductOptionBoxAttributes = [...prev]
          // 設定していない項目があったら、新規で追加しない。
          if (
            filterOptions(newTableProductOptionBoxAttributes).some((box) => {
              return box.table_product_option_box_id === productOptionBox.id
            })
          ) {
            Alert.alert(t('既に追加されています'))
            return newTableProductOptionBoxAttributes
          }
          const maxPosition = Math.max(
            ...newTableProductOptionBoxAttributes
              .filter((p) => !p._destroy)
              .map((box) => Number(box.position)),
            prev.filter((p) => !p._destroy).length
          )
          newTableProductOptionBoxAttributes.push({
            id: null,
            name: productOptionBox.name,
            table_product_option_box_id: productOptionBox.id,
            position: String(maxPosition + 1),
            _destroy: null,
            _new: true,
          })
          return newTableProductOptionBoxAttributes
        })
      },
    }))

    if (isLoadingMenuPages || isLoadingUnlimitedPlans) {
      return <Loading />
    }

    return (
      <ScrollView
        contentContainerStyle={[
          width < sm
            ? {
                paddingHorizontal: 16,
                paddingVertical: 20,
              }
            : {
                paddingHorizontal: 36,

                paddingVertical: 40,
              },
        ]}
        style={{ backgroundColor: Colors.bgBlack }}
      >
        <View style={{}}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('商品')}</Text>
          <ShadowBox
            style={[
              {
                marginTop: 24,
                padding: 32,
              },
            ]}
          >
            <TranslationFormList
              formLabel={<FormLabel value={t('商品名')} required />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={name}
              onChangeValues={setName}
              createTranslation={(params) => createTranslation(token!, params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={name[locale]}
                    onChangeText={(text) =>
                      setName((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />

            <TranslationFormList
              formLabel={<FormLabel value={t('説明')} />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={description}
              onChangeValues={setDescription}
              createTranslation={(params) => createTranslation(token!, params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={description[locale]}
                    onChangeText={(text) =>
                      setDescription((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                    multiline
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider style={{ marginVertical: 24 }} />
            <View
              style={{
                flexDirection: width < sm ? 'column' : 'row',
                alignItems: width < sm ? 'flex-start' : 'center',
                marginTop: 24,
              }}
            >
              <SectionTitle>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  {t('キッチン伝票印字名')}
                </Text>
              </SectionTitle>
              <TextInput
                value={kitchenPrintName}
                onChangeText={(text) => setKitchenPrintName(text)}
                autoCapitalize="none"
                style={[
                  width >= sm && { flex: 1 },
                  width < sm && { marginTop: 16 },
                ]}
              />
            </View>
            <Divider style={{ marginVertical: 24 }} />
            <View
              style={{
                flexDirection: width < sm ? 'column' : 'row',
                alignItems: width < sm ? 'flex-start' : 'center',
                justifyContent: 'space-between',
                marginTop: 24,
              }}
            >
              <SectionTitle>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  {t('画像')}
                </Text>
              </SectionTitle>
              <View
                style={[
                  { alignSelf: 'flex-start' },
                  width < sm && { marginTop: 16 },
                ]}
              >
                {(tableProduct?.image && !removeImage) ??
                selectedImage != null ? (
                  <View style={{ width: 120, height: 120 }}>
                    <SelectedImage
                      source={{
                        uri: selectedImage ?? tableProduct?.image?.sm,
                      }}
                      onRemovePress={() => {
                        setSelectedImage(null)
                        setRemoveImage(true)
                      }}
                    />
                  </View>
                ) : (
                  <SelectImage
                    onSelectImage={(image) => {
                      setSelectedImage(image)
                      setRemoveImage(false)
                    }}
                  />
                )}
              </View>
              <View
                style={[
                  {},
                  width >= sm && {
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={{ flex: 1, textAlign: 'center' }}>
                  {t('画像の大きさ')}
                </Text>
                <View
                  style={[
                    { flex: 1 },
                    width < sm && {
                      marginTop: 16,
                    },
                  ]}
                >
                  <SegmentedControl
                    buttons={[
                      {
                        text: t('標準'),
                        selected: displaySize === 'default',
                        onPress: () => {
                          setDisplaySize('default')
                        },
                      },
                      {
                        text: t('大きく表示'),
                        selected: displaySize === 'large',
                        onPress: () => {
                          setDisplaySize('large')
                        },
                      },
                    ]}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                paddingTop: 32,
                marginTop: 32,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
              }}
            >
              <View
                style={{
                  flexDirection: width < sm ? 'column' : 'row',
                  alignItems: width < sm ? 'flex-start' : 'center',
                }}
              >
                <SectionTitle>
                  <Text style={{ fontSize: 18, fontWeight: '600' }}>
                    {t('カテゴリー')}
                  </Text>
                </SectionTitle>
                {isLoadingMenuPages ? (
                  <Loading />
                ) : (
                  <View style={{ flex: 1 }}>
                    <SelectMenuPages
                      style={{}}
                      menuPages={tableMenuPages ?? []}
                      menuPageIds={menuPages}
                      setMenuPages={setMenuPages}
                    />
                  </View>
                )}
              </View>
              <View
                style={{
                  paddingTop: 32,
                  marginTop: 32,
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: width < sm ? 'column' : 'row',
                    alignItems: width < sm ? 'flex-start' : 'center',
                  }}
                >
                  <SectionTitle>
                    <Text style={{ fontWeight: '600' }}>
                      {t('放題プランに追加')}
                    </Text>
                  </SectionTitle>
                  <View style={[{ flex: 1 }, width < sm && { marginTop: 16 }]}>
                    <Text>
                      {t(
                        '放題プランに追加すると、商品の価格は0円で表示されます'
                      )}
                    </Text>
                    <View style={{ marginTop: 20 }}>
                      {isLoadingUnlimitedPlans ? (
                        <Loading />
                      ) : tableUnlimitedOrderPlans ? (
                        <CheckboxGroup
                          value={unlimitedOrderPlans}
                          onChange={setUnlimitedOrderPlans}
                        >
                          {tableUnlimitedOrderPlans.map(
                            (tableUnlimitedOrderPlan) => {
                              return (
                                <Checkbox
                                  key={tableUnlimitedOrderPlan.id}
                                  value={tableUnlimitedOrderPlan.id}
                                  checkboxLabel={
                                    <CheckboxLabel
                                      value={tableUnlimitedOrderPlan.name}
                                    />
                                  }
                                />
                              )
                            }
                          )}
                        </CheckboxGroup>
                      ) : (
                        <></>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                paddingTop: 32,
                marginTop: 22,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
              }}
            >
              <View
                style={{
                  flexDirection: width < sm ? 'column' : 'row',
                  alignItems: width < sm ? 'flex-start' : 'center',
                }}
              >
                <SectionTitle>
                  <Text style={{ fontWeight: '600' }}>
                    {t('ユーザーに表示')}
                  </Text>
                </SectionTitle>
                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <Text>
                    {t(
                      'お店からのみ注文できる状態にする場合は、オフにしてください'
                    )}
                  </Text>
                  <Switch
                    onValueChange={(value) => {
                      setCanOrderByUser(value)
                    }}
                    value={canOrderByUser}
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                borderTopWidth: 0.5,
                borderColor: Colors.border,
                marginTop: 32,
                paddingTop: 22,
              }}
            >
              <View
                style={{
                  flexDirection: width < sm ? 'column' : 'row',
                  alignItems: width < sm ? 'flex-start' : 'center',
                }}
              >
                <SectionTitle>
                  <Text style={{ fontWeight: '600' }}>{t('注文必須')}</Text>
                </SectionTitle>
                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <Text>
                    {t(
                      'オンにするとお客様の来店時に必ずこの商品を注文することになります。お通し等にご使用ください'
                    )}
                  </Text>
                  <Switch
                    onValueChange={(value) => setRequired(value)}
                    value={required}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: width < sm ? 'column' : 'row',
                alignItems: width < sm ? 'flex-start' : 'center',
                paddingTop: 32,
                marginTop: 22,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
              }}
            >
              <SectionTitle>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  {t('注文の優先順')}
                </Text>
              </SectionTitle>
              <View
                style={[
                  { flex: 1 },
                  width < sm && {
                    marginTop: 16,
                  },
                ]}
              >
                <TextInput
                  style={[width < sm && { marginTop: 16, width: '100%' }]}
                  keyboardType="number-pad"
                  value={position !== undefined ? String(position) : ''}
                  onChangeText={(position) => {
                    if (/^[\d]+$/.test(position)) {
                      setPosition(Number(position))
                    } else {
                      setPosition('')
                    }
                  }}
                />
                <Text
                  style={{
                    marginTop: 16,
                  }}
                >
                  {t(
                    '数字を入力すると、数字が小さな順にキッチン伝票に表示されます。他の注文よりも優先したい商品には、小さな数字を設定してください。'
                  )}
                </Text>
              </View>
            </View>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('価格')}</Text>
          <ShadowBox
            style={[
              {
                marginTop: 24,
                padding: 32,
              },
            ]}
          >
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontWeight: '600' }}>{t('販売価格')}</Text>
              </View>
              <View
                style={[
                  {
                    marginTop: 20,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={{ flex: 1 }}>{t('税種別')}</Text>
                <View
                  style={[
                    { flex: 1 },

                    width < sm && {
                      marginTop: 16,
                    },
                  ]}
                >
                  <SegmentedControl
                    buttons={[
                      {
                        text: t('標準税率(10%)'),
                        selected: productPrices.table.taxType === 'default',
                        onPress: () => {
                          productPricesDispatch({
                            type: 'CHANGE_PRODUCT_PRICE_TAX_TYPE',
                            payload: {
                              provideMethod: 'table',
                              taxType: 'default',
                            },
                          })
                        },
                      },
                      {
                        text: t('軽減税率(8%)'),
                        selected: productPrices.table.taxType === 'reduced',
                        onPress: () => {
                          productPricesDispatch({
                            type: 'CHANGE_PRODUCT_PRICE_TAX_TYPE',
                            payload: {
                              provideMethod: 'table',
                              taxType: 'reduced',
                            },
                          })
                        },
                      },
                    ]}
                  />
                </View>
              </View>
              <ProductPriceTaxIncludedField
                checked={productPrices.table.taxIncluded}
                onChange={(value) => {
                  productPricesDispatch({
                    type: 'CHANGE_PRODUCT_PRICE_TAX_INCLUDED',
                    payload: {
                      provideMethod: 'table',
                      taxIncluded: value,
                    },
                  })
                }}
              />
              <View
                style={[
                  {
                    marginTop: 20,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={{ flex: 1 }}>{t('料金設定')}</Text>
                <View
                  style={[
                    { flex: 1 },
                    width < sm && {
                      marginTop: 16,
                    },
                  ]}
                >
                  <SegmentedControl
                    buttons={[
                      {
                        text: t('金額を設定'),
                        selected: productPrices.table.priceType === 'fixed',
                        onPress: () => {
                          productPricesDispatch({
                            type: 'CHANGE_PRODUCT_PRICE_TYPE',
                            payload: {
                              provideMethod: 'table',
                              priceType: 'fixed',
                            },
                          })
                        },
                      },
                      {
                        text: 'ASK',
                        selected: productPrices.table.priceType === 'ask',
                        onPress: () => {
                          productPricesDispatch({
                            type: 'CHANGE_PRODUCT_PRICE_TYPE',
                            payload: {
                              provideMethod: 'table',
                              priceType: 'ask',
                            },
                          })
                        },
                      },
                    ]}
                  />
                </View>
              </View>
              <View
                style={{
                  marginTop: 20,
                  flex: 1,
                }}
              >
                {productPrices.table.priceType === 'fixed' ? (
                  <TextInput
                    style={[
                      width >= sm && {
                        marginLeft: '50%',
                        width: '50%',
                      },
                    ]}
                    placeholder={t('料金')}
                    value={`${productPrices.table.price as number}`}
                    onChangeText={(text) =>
                      productPricesDispatch({
                        type: 'CHANGE_PRODUCT_PRICE',
                        payload: { provideMethod: 'table', price: text },
                      })
                    }
                    autoCapitalize="none"
                    keyboardType="number-pad"
                  />
                ) : (
                  <Text
                    style={[
                      {
                        lineHeight: 22,
                      },
                      width >= sm && {
                        marginLeft: '50%',
                        width: '50%',
                      },
                    ]}
                  >
                    {t(
                      '金額をASKにすると、お客様への金額の表示がASKになり、実際に注文を受けた後に、スタッフが商品の金額を追加するフローになります。これはおすすめの白ワインなど日によって金額が異なる際にご利用いただける機能となっております。ASK機能をご利用の場合は、オプション機能はご利用いただけません。'
                    )}
                  </Text>
                )}
              </View>
              <View
                style={{
                  paddingTop: 32,
                  marginTop: 32,
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontWeight: '600' }}>{t('商品原価')}</Text>
                <TextInput
                  style={[
                    width >= sm && {
                      width: '50%',
                    },
                    width < sm && {
                      width: '60%',
                    },
                  ]}
                  placeholder={t('価格')}
                  value={`${cost}`}
                  onChangeText={(text) => setCost(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                />
              </View>

              <View
                style={{
                  paddingTop: 32,
                  marginTop: 32,
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                }}
              >
                <Text style={{ fontWeight: '600' }}>{t('レシート印字')}</Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text>
                    {t(
                      '0円の商品をレシートに印字したくない場合は、オフにしてください。会計価格が1円以上の時は、設定に関わらず印字されます。'
                    )}
                  </Text>
                  <Switch
                    onValueChange={(value) => setShouldPrintReceipt(value)}
                    value={shouldPrintReceipt}
                  />
                </View>
              </View>
            </View>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>
            {t('在庫管理')}
          </Text>
          <View
            style={[
              {
                marginTop: 24,
                padding: 32,
                gap: 36,
                borderRadius: 8,
                shadowRadius: 4,
                elevation: 4,
                shadowColor: '#000',
                shadowOpacity: 0.1,
                shadowOffset: { width: 0, height: 2 },
                backgroundColor: 'white',
              },
            ]}
          >
            <Text style={{ fontWeight: '600' }}>{t('在庫管理の有無')}</Text>
            <RadioGroup
              mode="vertical"
              value={stockType !== 'none'}
              onChange={(value) => {
                if (value && stockType === 'none') {
                  setStockType('manual')
                } else if (!value) {
                  setStockType('none')
                  setDailyDefaultQuantity(null)
                }
              }}
            >
              {[
                {
                  label: t('在庫管理する'),
                  description: t(
                    '商品に対して在庫数を設定します。販売数に応じて在庫が減少し、在庫がなくなると自動で売り切れになります。'
                  ),
                  value: true,
                },
                {
                  label: t('在庫管理しない'),
                  description: t(
                    '商品に在庫数を設定せず、販売中・売り切れを手動で切り替えができます。'
                  ),
                  value: false,
                },
              ].map(({ label, description, value }) => (
                <Radio
                  key={`${value}`}
                  value={value}
                  radioLabel={
                    <View>
                      <RadioLabel
                        style={[{ fontSize: 18, color: Colors.black }]}
                        value={label}
                      />
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: '300',
                          lineHeight: 21,
                          color: Colors.black60,
                        }}
                      >
                        {description}
                      </Text>
                    </View>
                  }
                  radioLabelContainerStyle={[{ flex: 1, paddingHorizontal: 6 }]}
                />
              ))}
            </RadioGroup>
            {stockType !== 'none' && (
              <>
                <View style={{ height: 1, backgroundColor: Colors.border }} />
                <Text style={{ fontSize: 22, fontWeight: '600' }}>
                  {t('管理方法')}
                </Text>
                <RadioGroup
                  mode="vertical"
                  value={stockType}
                  onChange={(value) => {
                    if (value === stockType) return
                    setStockType(value)
                    switch (value) {
                      case 'daily_reset':
                        break
                      case 'manual':
                        setDailyDefaultQuantity(null)
                        break
                    }
                  }}
                >
                  {[
                    {
                      label: t('手動'),
                      description: t('在庫数の変更を手動で行う設定です。'),
                      value: 'manual',
                    },
                    {
                      label: t('毎日リセット'),
                      description: t(
                        '営業日切り替えのタイミングで、以下で設定する「切替時のリセット数」まで自動で在庫がリセットされる設定です。（リセット数を「10」にした場合、営業日切替時に自動で在庫が10になります）'
                      ),
                      value: 'daily_reset',
                    },
                  ].map(({ label, description, value }) => (
                    <Radio
                      key={`${value}`}
                      value={value}
                      radioLabel={
                        <View>
                          <RadioLabel
                            style={[{ fontSize: 18, color: Colors.black }]}
                            value={label}
                          />
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '300',
                              lineHeight: 21,
                              color: Colors.black60,
                            }}
                          >
                            {description}
                          </Text>
                        </View>
                      }
                      radioLabelContainerStyle={[
                        { flex: 1, paddingHorizontal: 6 },
                      ]}
                    />
                  ))}
                </RadioGroup>
                <View style={{ gap: 20 }}>
                  {stockType === 'daily_reset' && (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{ fontWeight: '600' }}>
                        {t('切替時のリセット数')}
                      </Text>
                      <TextInput
                        style={[
                          width >= sm && {
                            width: '50%',
                          },
                          width < sm && {
                            width: '30%',
                          },
                        ]}
                        placeholder={t('営業日切替時のリセット数を入力')}
                        value={`${dailyDefaultQuantity ?? ''}`}
                        onChangeText={(text) => {
                          if (/^[\d]+$/.test(text)) {
                            setDailyDefaultQuantity(Number(text))
                          } else {
                            setDailyDefaultQuantity(null)
                          }
                        }}
                        autoCapitalize="none"
                        keyboardType="number-pad"
                      />
                    </View>
                  )}
                </View>
              </>
            )}
          </View>
        </View>
        {productPrices.table.priceType === 'fixed' && (
          <View style={{ marginTop: 40 }}>
            <Text style={{ fontSize: 22, fontWeight: '600' }}>
              {t('オプション')}
            </Text>

            <ShadowBox
              style={[
                {
                  marginTop: 24,
                  padding: 32,
                },
              ]}
            >
              {tableProductOptionBoxAttributes.map((box, index) => {
                if (box._destroy != null) {
                  return null
                }
                return (
                  <View
                    key={`optionBox${index}`}
                    style={{
                      marginBottom: 24,
                    }}
                  >
                    <SelectOptionBox
                      name={box.name}
                      position={box.position}
                      onChangePosition={(position) => {
                        setTableProductOptionBoxAttributes((prev) => {
                          const newTableProductOptionBoxAttributes = [...prev]
                          newTableProductOptionBoxAttributes[index].position =
                            position
                          return newTableProductOptionBoxAttributes
                        })
                      }}
                      onPressDelete={() => {
                        setTableProductOptionBoxAttributes((prev) => {
                          const newTableProductOptionBoxAttributes = [...prev]
                          newTableProductOptionBoxAttributes[index]._destroy =
                            '1'
                          return newTableProductOptionBoxAttributes
                        })
                      }}
                    />
                  </View>
                )
              })}
              <Button
                style={{
                  height: 52,
                  borderColor: Colors.primary,
                  backgroundColor: 'transparent',
                }}
                onPress={() => {
                  onPressOpenOptionBoxModal(true)
                }}
              >
                <Text
                  style={{
                    color: Colors.primary,
                    fontWeight: 'normal',
                  }}
                >
                  {t('オプションを追加')}
                </Text>
              </Button>
            </ShadowBox>
          </View>
        )}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 40,
          }}
        >
          {tableProductId != null && !isCopy && (
            <Button
              height={48}
              mode="outline"
              variant="danger-secondary"
              style={{
                flex: 1,
                marginRight: 20,
              }}
              onPress={
                tableProduct?.status === 'archived'
                  ? () => alertPublish()
                  : () => alertArchive()
              }
            >
              {tableProduct?.status === 'archived'
                ? t('非表示から戻す')
                : t('非表示にする')}
            </Button>
          )}
          {tableProductId != null && !isCopy && (
            <Button
              height={48}
              mode="outline"
              variant="primary"
              style={{
                flex: 1,
                marginRight: 20,
                backgroundColor: 'transparent',
              }}
              onPress={() => onPressCopy({ tableProductId })}
            >
              {t('コピーする')}
            </Button>
          )}
          <Button
            height={48}
            style={{
              flex: 1,
            }}
            onPress={onPress}
          >
            {tableProductId != null && !isCopy ? t('更新する') : t('追加する')}
          </Button>
        </View>
      </ScrollView>
    )
  }
)

export default TableProductFormContent
