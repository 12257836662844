import React from 'react'
import { View } from 'react-native'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faHorizontalRule } from '@fortawesome/pro-solid-svg-icons/faHorizontalRule'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'

import numberWithDelimiter from '../../modules/numberWithDelimiter'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'

import { useComponentSize } from '../../modules/useComponentSize'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Trans } from 'react-i18next'

export function Price({
  value,
  fontSize = 28,
}: {
  value: number
  fontSize?: number
}) {
  const price = numberWithDelimiter(value ?? '-')
  return (
    <Text>
      {/* eslint-disable ar-i18n/require-translation-ja */}
      <Trans i18nKey={'<0>{{price}}</0><1>円</1>'} values={{ price }}>
        <Text style={{ fontSize, fontWeight: '600' }}>{`${price}`}</Text>
        <Text style={{ fontSize: 14 }}>{'円'}</Text>
      </Trans>
      {/* eslint-enable ar-i18n/require-translation-ja */}
    </Text>
  )
}

export function Card({
  height,
  icon,
  title,
  totalAmount,
  amountDelta = 0,
  margin = 0,
}: {
  height: number
  icon: IconDefinition
  title: string
  totalAmount: number
  amountDelta?: number
  margin: number
}) {
  const [size, , onLayout] = useComponentSize()
  const amountFontSize = Math.min(Math.max(Math.floor(size.width / 14), 14), 28)
  const color =
    amountDelta === 0
      ? Colors.black
      : amountDelta > 0
        ? Colors.accent
        : Colors.caution
  return (
    <ShadowBox
      style={[
        {
          height,
          flex: 1,
          alignSelf: 'stretch',
          margin,
          alignItems: 'center',
          flexDirection: 'row',
          minWidth: 200,
        },
      ]}
      onLayout={onLayout}
    >
      <View
        style={{
          minWidth: 40,
          minHeight: 40,
        }}
      >
        <FontAwesomeIcon size={40} color={Colors.primary} icon={icon} />
      </View>
      <View
        style={{
          marginLeft: 24,
          justifyContent: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: '600',
            color: Colors.black,
          }}
        >
          {title}
        </Text>
        <View style={{ marginTop: 3 }}>
          <Price value={totalAmount} fontSize={amountFontSize} />
        </View>
        <Text style={{ fontSize: 12, marginTop: 5 }}>
          <Text style={{ color: Colors.secondaryBlack }}>{t('前月比')} </Text>
          <Text
            style={{
              color,
            }}
          >
            {t('{{price}}円', {
              price: numberWithDelimiter(amountDelta !== 0 ? amountDelta : '-'),
            })}
          </Text>
          <FontAwesomeIcon
            size={10}
            color={color}
            icon={
              amountDelta === 0
                ? faHorizontalRule
                : amountDelta > 0
                  ? faCaretUp
                  : faCaretDown
            }
          />
        </Text>
      </View>
    </ShadowBox>
  )
}
