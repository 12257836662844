import React, { useMemo, useState } from 'react'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { View, Dimensions, Platform } from 'react-native'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { CheckboxIcon } from '@hello-ai/ar_shared/src/components/Checkbox'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import ConfirmDeleteArchivedProductModal from '../TableProduct/ConfirmDeleteArchivedProductModal'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import {
  bulkUpdateTableMenuStatus,
  useTableMenuPages,
} from '../../../models/TableMenuPage'
import GroupCautionMessage from '../GroupCautionMessage'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  root: {
    flex: 1,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  scrollViewContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    height:
      Dimensions.get('window').height -
      (width < sm
        ? // NOTE: header + footer + padding + table header
          Platform.OS === 'web'
          ? 280
          : 320 // NOTE: Edge部分が見切れるのでリストは少し低めに設定する
        : // NOTE: header + footer + padding + table header
          238),
    paddingTop: width < sm ? 20 : 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  tableContainer: {
    rowGap: width < sm ? 20 : 16,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: width < sm ? 20 : 16,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  rowContent: {
    flexDirection: width < sm ? 'column' : 'row',
    marginLeft: width < sm ? 12 : 24,
  },
  pagination: {
    paddingVertical: 16,
    marginHorizontal: 0,
    justifyContent: 'center',
    columnGap: 20,
  },
  footerContainer: {
    flexDirection: width < sm ? 'column-reverse' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 12,
    columnGap: 16,
    backgroundColor: Colors.white,
    paddingVertical: width < sm ? 16 : 24,
    paddingHorizontal: width < sm ? 16 : 12,
  },
  footerButton: {
    width: width < sm ? '100%' : undefined,
    height: 48,
  },
  spNone: {
    display: width < sm ? 'none' : 'flex',
  },
}))

const PER_PAGE = 10

interface ArchivedListViewProps {
  restaurantId: number
}

export default function ArchivedListView({
  restaurantId,
}: ArchivedListViewProps) {
  const style = useResponsiveStyle(responsiveStyle)
  const token = useToken()

  const { tableMenuPages, mutate } = useTableMenuPages({
    restaurantId,
    params: {
      status: 'archived',
    },
  })

  const [selectTableMenuIds, setSelectTableMenuIds] = useState<Set<string>>(
    new Set()
  )

  const allTableMenus = useMemo(() => tableMenuPages ?? [], [tableMenuPages])
  const checkedAll = useMemo(() => {
    return (
      selectTableMenuIds.size > 0 &&
      selectTableMenuIds.size === allTableMenus.length
    )
  }, [selectTableMenuIds, allTableMenus])

  const [page, setPage] = useState(1)
  const tableMenus = useMemo(() => {
    return allTableMenus.slice((page - 1) * PER_PAGE, page * PER_PAGE)
  }, [allTableMenus, page])
  const [isVisibleConfirmCancel, setIsVisibleConfirmCancel] = useState(false)

  const hasGroupManagedItem = (tableMenuPages ?? []).some((item) => {
    return item.group_managed
  })

  return (
    <View style={style.root}>
      {hasGroupManagedItem && (
        <View
          style={{
            marginBottom: 20,
          }}
        >
          <GroupCautionMessage />
        </View>
      )}
      <View style={style.container}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
            marginBottom: 16,
          }}
          disabled={allTableMenus.length === 0}
          onPress={() => {
            if (checkedAll) {
              setSelectTableMenuIds(new Set())
            } else {
              setSelectTableMenuIds(
                new Set(allTableMenus?.map((tableMenu) => tableMenu.id) ?? [])
              )
            }
          }}
        >
          <CheckboxIcon checked={checkedAll} />
          <Text>{t('すべて選択')}</Text>
        </TouchableOpacity>
        <View style={style.scrollViewContainer}>
          <KeyboardAwareScrollView contentContainerStyle={style.tableContainer}>
            {tableMenus != null && tableMenus.length === 0 && (
              <Text
                style={{
                  paddingTop: 16,
                  marginBottom: 24,
                  paddingHorizontal: 24,
                  fontSize: 22,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当するカテゴリはありません')}
              </Text>
            )}
            {tableMenus?.map((tableMenu) => {
              const checked = selectTableMenuIds.has(tableMenu.id)
              return (
                <TouchableOpacity
                  key={tableMenu.id}
                  style={style.rowContainer}
                  onPress={() => {
                    setSelectTableMenuIds((prev) => {
                      if (!checked) {
                        return new Set(prev).add(tableMenu.id)
                      }
                      prev.delete(tableMenu.id)
                      return new Set(prev)
                    })
                  }}
                >
                  <CheckboxIcon checked={checked} />
                  <View style={style.rowContent}>
                    <Text style={{ fontSize: 18, fontWeight: '600' }}>
                      {tableMenu.name}
                    </Text>
                  </View>
                </TouchableOpacity>
              )
            })}
          </KeyboardAwareScrollView>
          <Pagination
            currentPage={page}
            totalPage={Math.ceil(allTableMenus.length / PER_PAGE)}
            setPage={(page) => {
              setPage(page)
            }}
            style={style.pagination}
          />
        </View>
      </View>
      <View style={style.footerContainer}>
        <Button
          mode="outline"
          variant="danger-secondary"
          style={[style.footerButton]}
          disabled={selectTableMenuIds.size === 0}
          onPress={() => {
            setIsVisibleConfirmCancel(true)
          }}
        >
          {t('チェックしたリストを完全に削除する')}
        </Button>
        <AsyncButton
          style={style.footerButton}
          disabled={selectTableMenuIds.size === 0}
          onPress={async () => {
            if (token == null) return
            const { error } = await bulkUpdateTableMenuStatus(
              token,
              restaurantId,
              {
                ids: Array.from(selectTableMenuIds),
                status: 'published',
              }
            )
            if (error != null) {
              onError(error)
              return
            }
            setSelectTableMenuIds(new Set())
            await mutate()
          }}
        >
          {t('チェックしたリストを元に戻す')}
        </AsyncButton>
      </View>
      <ConfirmDeleteArchivedProductModal
        title={t('{{count}}件のリストを完全に削除します', {
          count: selectTableMenuIds.size,
        })}
        isModalVisible={isVisibleConfirmCancel}
        onPressCancel={() => setIsVisibleConfirmCancel(false)}
        onPressSubmit={async () => {
          if (token == null) {
            return
          }
          const { error } = await bulkUpdateTableMenuStatus(
            token,
            restaurantId,
            {
              ids: Array.from(selectTableMenuIds),
              status: 'deleted',
            }
          )
          if (error != null) {
            onError(error)
            return
          }
          await mutate()
          setSelectTableMenuIds(new Set())
          setIsVisibleConfirmCancel(false)
        }}
      />
    </View>
  )
}
