import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import {
  HeaderBack,
  HeaderRightButton,
} from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import RestaurantCourses from 'pages/Restaurants/RestaurantCourses'
import RestaurantCourseForm from 'pages/Restaurants/RestaurantCourses/Form'
import RestaurantCourseEditOrder from 'pages/Restaurants/RestaurantCourses/EditOrder'

import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import RestaurantCoursesArchived from 'pages/Restaurants/RestaurantCourses/Archived'
import { useNavigate } from 'modules/router/useNavigate'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useRestaurantId } from 'modules/useRestaurantId'
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'

function ArchivedHeaderRight() {
  const navigate = useNavigate()
  const restaurantId = useRestaurantId()
  const { width, sm } = useResponsive()

  return (
    <HeaderRightButton
      icon={
        <FontAwesomeIcon icon={faEyeSlash} size={18} color={Colors.primary} />
      }
      onPress={() => {
        navigate(`/restaurants/${restaurantId}/courses/archived`)
      }}
      label={width < sm ? null : t('非表示にしたコース')}
    />
  )
}

const routes: StackRoutes = {
  Index: {
    title: t('コース'),
    fullPath: `/restaurants/:id/courses`,
    relativePath: `/`,
    exact: true,
    element: <RestaurantCourses />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
      headerRight: <ArchivedHeaderRight />,
    },
  },
  Show: {
    title: t('コースを作成'),
    fullPath: `/restaurants/:id/courses/new`,
    relativePath: `/new`,
    element: <RestaurantCourseForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('コースを編集'),
    fullPath: `/restaurants/:id/courses/:restaurantCourseId`,
    relativePath: `/:restaurantCourseId`,
    element: <RestaurantCourseForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  EditOrder: {
    title: t('コースの並べ替え'),
    fullPath: `/restaurants/:id/courses/edit_order`,
    relativePath: `/edit_order`,
    element: <RestaurantCourseEditOrder />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Archived: {
    title: t('非表示にしたコース'),
    fullPath: `/restaurants/:id/courses/archived`,
    relativePath: `/archived`,
    element: <RestaurantCoursesArchived />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function RestaurantCoursesStack() {
  return <Stack routes={routes} />
}
