import React from 'react'

import { useRestaurantId } from 'modules/useRestaurantId'
import BroadcastListView from '@hello-ai/for_r_app/src/components/Message/Broadcast/BroadcastListView'

export default function BroadcastListScreen() {
  const restaurantId = useRestaurantId()

  return <BroadcastListView restaurantId={restaurantId} />
}
