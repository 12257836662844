import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import ModalCenter from '../Shared/ModalCenter'
import React from 'react'
import { View } from 'react-native'

interface ConfirmDeleteArchivedModalProps {
  isModalVisible: boolean
  title: string
  onPressCancel: () => void
  onPressSubmit: () => void
}

export default function ConfirmDeleteArchivedtModal({
  isModalVisible,
  title,
  onPressCancel,
  onPressSubmit,
}: ConfirmDeleteArchivedModalProps) {
  const { width, sm } = useResponsive()
  return (
    <ModalCenter
      title={title}
      isModalVisible={isModalVisible}
      width={600}
      minHeight={200}
      onClose={onPressCancel}
    >
      <View style={{ padding: 24 }}>
        <Text>
          {t(
            '完全に削除したリストは復元することができません。よろしいですか？'
          )}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: 16,
          paddingVertical: 24,
          borderTopWidth: 0.5,
          borderColor: Colors.border,
        }}
      >
        <Button
          mode="outline"
          style={{
            width: width >= sm ? 200 : undefined,
            height: 48,
          }}
          onPress={onPressCancel}
        >
          {t('キャンセル')}
        </Button>
        <AsyncButton
          style={{
            backgroundColor: Colors.caution,
            height: 48,
            borderWidth: 0,
          }}
          textStyle={{ color: Colors.white }}
          onPress={onPressSubmit}
        >
          {t('完全に削除する')}
        </AsyncButton>
      </View>
    </ModalCenter>
  )
}
