import React, { RefObject, useMemo, useRef } from 'react'
import {
  TouchableOpacity as RNTouchableOpacity,
  ScrollView,
  View,
} from 'react-native'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { faGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import { TableMenu } from 'models/TableMenu'
import {
  TableMenuPage as TableMenuPageModel,
  useTableMenuPage,
} from 'models/TableMenuPage'
import { TableProduct as TableProductModel } from 'models/TableProduct'

import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { ReplaceAll } from '@hello-ai/ar_shared/src/types/utils'
import {
  LocaleValue,
  TranslationFormItem,
  TranslationFormList,
  confirmSaveIncompleteChangesAsync,
  getSortedLocales,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
} from '@hello-ai/for_r_app/src/components/Translation'
import { useToken } from 'models/Auth'
import { createTranslation } from 'models/Translation'
import { goBack } from 'modules/history'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'
import DragList from 'react-native-draglist'

const space = 40

const defaultParentTableMenu = {
  label: t('親カテゴリーなし'),
  value: 'default',
}

function TableProduct({
  tableProduct,
  index,
  onPress,
  onDragStart,
  onDragEnd,
  isActive,
  isSortable,
  isOwner,
  scrollRef,
}: {
  tableProduct?: TableProductModel
  index?: number
  onPress: () => void
  onDragStart: () => void
  onDragEnd: () => void
  isActive: boolean
  isSortable: boolean
  isOwner: boolean
  scrollRef: RefObject<HTMLDivElement>
}) {
  if (tableProduct == null) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onPress={
        !isSortable && (!tableProduct.group_managed || isOwner)
          ? onPress
          : undefined
      }
      {...(isSortable ? toDragHandlers(onDragStart, onDragEnd, scrollRef) : {})}
    >
      <View style={{ flex: 1, marginRight: 12 }}>
        <Text style={{ fontWeight: '600' }}>{tableProduct.name}</Text>
        <Text style={{ marginTop: 6 }}>
          {t('{{price}}円', { price: numberWithDelimiter(tableProduct.price) })}
        </Text>
      </View>
      {isSortable && (
        <FontAwesomeIcon
          icon={faGripLines}
          size={20}
          color={Colors.disabledBlack}
        />
      )}
    </RNTouchableOpacity>
  )
}

function TableMenuPage({
  tableMenuPage,
  index,
  onPress,
  onDragStart,
  onDragEnd,
  isActive,
  isSortable,
  isOwner,
  scrollRef,
}: {
  tableMenuPage?: TableMenuPageModel
  index?: number
  onPress: () => void
  onDragStart: () => void
  onDragEnd: () => void
  isActive: boolean
  isSortable: boolean
  isOwner: boolean
  scrollRef: RefObject<HTMLDivElement>
}) {
  if (tableMenuPage == null) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onPress={
        !isSortable && (!tableMenuPage.group_managed || isOwner)
          ? onPress
          : undefined
      }
      {...(isSortable ? toDragHandlers(onDragStart, onDragEnd, scrollRef) : {})}
    >
      <View style={{ flex: 1, marginRight: 12 }}>
        <Text style={{ fontWeight: '600' }}>{tableMenuPage?.name}</Text>
      </View>
      {isSortable && (
        <FontAwesomeIcon
          icon={faGripLines}
          size={20}
          color={Colors.disabledBlack}
        />
      )}
    </RNTouchableOpacity>
  )
}

export default function TableMenuPageFormContent({
  tableMenuPage,
  createMenuPage,
  updateMenuPage,
  onPressProduct,
  onPressMenuPage,
  tableMenuPages,
  isLoadingMenuPages,
  tableMenus,
  isLoadingMenus,
  isOwner,
}: {
  tableMenuPage?: ReturnType<typeof useTableMenuPage>['tableMenuPage']
  createMenuPage: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  updateMenuPage: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  onPressProduct: (id: string) => void
  onPressMenuPage: (id: string) => void
  tableMenuPages?: TableMenuPageModel[]
  isLoadingMenuPages: boolean
  tableMenus?: TableMenu[]
  isLoadingMenus: boolean
  isOwner: boolean
}) {
  const scrollRef = useRef(null)
  const { width, sm } = useResponsive()
  const token = useToken()
  const tableMenuPageId = tableMenuPage?.id

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  const defaultName = useMemo(() => {
    const name: Partial<LocaleValue> = {}
    supportedLocales.forEach((locale) => {
      name[locale] =
        tableMenuPage?.[
          `name_${locale
            .replaceAll('-', '_')
            .toLowerCase()}` as `name_${Lowercase<
            ReplaceAll<typeof locale, '-', '_'>
          >}`
        ] ?? ''
    })

    return name as LocaleValue
  }, [tableMenuPage])
  const [name, setName] = useFormState<LocaleValue>(defaultName)

  const defaultDescription = useMemo(() => {
    const description: Partial<LocaleValue> = {}
    supportedLocales.forEach((locale) => {
      description[locale] =
        tableMenuPage?.[
          `description_${locale
            .replaceAll('-', '_')
            .toLowerCase()}` as `description_${Lowercase<
            ReplaceAll<typeof locale, '-', '_'>
          >}`
        ] ?? ''
    })

    return description as LocaleValue
  }, [tableMenuPage])

  const [description, setDescription] =
    useFormState<LocaleValue>(defaultDescription)

  const [menus, setMenus] = useFormState(
    tableMenuPage?.table_menus
      ? tableMenuPage.table_menus.map((menu) => menu.id)
      : []
  )
  const [data, setData] = useFormState(
    tableMenuPage?.table_products
      ? tableMenuPage.table_products.map((product) => product.id)
      : []
  )

  const [tableMenuPageIds, setTableMenuPageIds] = useFormState(
    tableMenuPage?.table_menu_pages
      ? tableMenuPage.table_menu_pages.map((menu_page) => menu_page.id)
      : []
  )

  const [parentTableMenuId, setParentTableMenuId] = useFormState(
    tableMenuPage?.parent_table_menu_page?.id ?? defaultParentTableMenu.value
  )

  const [isSortable, setIsSortable] = useFormState(false)

  const hasItems = data != null && data.length > 0
  const hasSubCategories =
    tableMenuPageIds != null && tableMenuPageIds.length > 0

  const [tabName, setTabName] = useFormState(
    hasItems ? 'item' : hasSubCategories ? 'category' : 'item'
  )

  const tableMenuPageItems = useMemo(() => {
    if (isLoadingMenuPages || !tableMenuPages) {
      return [defaultParentTableMenu]
    }
    const childTableMenuPageIds: string[] = ([] as string[]).concat(
      ...tableMenuPages
        .filter((menuPage) => (menuPage?.table_menu_pages ?? []).length > 0)
        .map((menuPage) =>
          (menuPage?.table_menu_pages ?? []).map(
            (childMenuPage) => childMenuPage.id
          )
        )
    )

    const menuPages = tableMenuPages
      .filter((page) => {
        return (
          page.id !== tableMenuPageId &&
          !childTableMenuPageIds.includes(page.id)
        )
      })
      .map((page) => {
        return {
          value: page.id,
          label: page.name,
        }
      })
    return [defaultParentTableMenu, ...menuPages]
  }, [isLoadingMenuPages, tableMenuPages, tableMenuPageId])

  const disabledSelectParentTableMenu = useMemo(() => {
    if (!tableMenuPage) {
      return false
    }
    return (tableMenuPage.table_menu_pages ?? []).length > 0
  }, [tableMenuPage])

  const tabItems = useMemo(() => {
    const items = []
    if (hasSubCategories) {
      items.push({
        label: t('子カテゴリー'),
        value: 'category',
      })
    }

    if (hasItems) {
      items.push({
        label: t('商品'),
        value: 'item',
      })
    }
    return items
  }, [hasItems, hasSubCategories])

  const onPress = async () => {
    if (name[preferredLocale] === '') {
      Alert.alert(t('カテゴリー名を入力してください'))
      return
    }
    const params = {
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `name_${locale.replaceAll('-', '_').toLowerCase()}`,
            name[locale],
          ]
        })
      ),
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `description_${locale.replaceAll('-', '_').toLowerCase()}`,
            description[locale],
          ]
        })
      ),
      table_menu_ids: menus,
      table_product_ids: data,
      table_menu_page_ids: tableMenuPageIds,
      parent_table_menu_page_id:
        parentTableMenuId === 'default' ? null : parentTableMenuId,
    }

    if (token == null) {
      return
    }

    if (tableMenuPageId != null) {
      if (
        hasIncompleteChanges(name, defaultName) ||
        hasIncompleteChanges(description, defaultDescription)
      ) {
        if (!(await confirmSaveIncompleteChangesAsync(Alert.alert))) {
          return
        }
      }

      await updateMenuPage({
        token,
        params,
      })
      goBack('..')
    } else {
      await createMenuPage({
        token,
        params,
      })
      goBack('..')
    }
  }

  const onPressUpdateItemOrder = async () => {
    if (tableMenuPageId == null || token == null) {
      return
    }
    const params = {
      table_product_ids: data,
      table_menu_page_ids: tableMenuPageIds,
    }
    await updateMenuPage({
      token,
      params,
    })
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (tableMenuPageId == null || token == null) {
            return
          }
          await updateMenuPage({
            token,
            params,
          })

          goBack('..')
        },
      },
    ])
  }

  const paddingHorizontal = width < sm ? 16 : 36

  return (
    <ScrollView
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        overflow: 'scroll',
        height: '100%',
      }}
      contentContainerStyle={{
        paddingVertical: space,
      }}
    >
      <DragList
        key={tabName === 'item' ? data.join() : tableMenuPageIds.join()}
        data={tabName === 'item' ? data : tableMenuPageIds}
        keyExtractor={(item) => String(item)}
        renderItem={({ item, index, isActive, onDragStart, onDragEnd }) => (
          <View style={{ paddingHorizontal }}>
            {tabName === 'item' ? (
              <TableProduct
                isOwner={isOwner}
                tableProduct={tableMenuPage?.table_products.find(
                  (product) => product.id === item
                )}
                index={index}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onPress={() => onPressProduct(item)}
                isActive={isActive}
                isSortable={isSortable}
                scrollRef={scrollRef}
              />
            ) : (
              <TableMenuPage
                isOwner={isOwner}
                tableMenuPage={tableMenuPage?.table_menu_pages?.find(
                  (menuPage) => menuPage.id === item
                )}
                index={index}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onPress={() => onPressMenuPage(item)}
                isActive={isActive}
                isSortable={isSortable}
                scrollRef={scrollRef}
              />
            )}
          </View>
        )}
        ListHeaderComponent={
          <View style={{ paddingHorizontal }}>
            <TranslationFormList
              formLabel={<FormLabel value={t('カテゴリー名')} required />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={name}
              onChangeValues={setName}
              createTranslation={(params) => createTranslation(token!, params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={name[locale]}
                    onChangeText={(text) =>
                      setName((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <TranslationFormList
              formLabel={<FormLabel value={t('説明')} />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={description}
              onChangeValues={setDescription}
              createTranslation={(params) => createTranslation(token!, params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={description[locale]}
                    onChangeText={(text) =>
                      setDescription((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                    multiline
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider style={{ marginVertical: 24 }} />
            <View style={{ marginTop: space }}>
              <Text style={{ fontWeight: '600' }}>{t('メニュー')}</Text>
              <View style={{ marginTop: 20 }}>
                {isLoadingMenus ? (
                  <Loading />
                ) : (
                  <CheckboxGroup value={menus} onChange={setMenus}>
                    {tableMenus?.map((tableMenu) => {
                      return (
                        <Checkbox
                          key={tableMenu.id}
                          value={tableMenu.id}
                          checkboxLabel={
                            <CheckboxLabel value={tableMenu.name} />
                          }
                        />
                      )
                    })}
                  </CheckboxGroup>
                )}
              </View>
            </View>
            <View style={{ marginTop: space }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: '600' }}>{t('親カテゴリー')}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <SelectInput
                    selectedValue={parentTableMenuId}
                    setValue={(value) => {
                      setParentTableMenuId(value)
                    }}
                    items={tableMenuPageItems}
                    disabled={disabledSelectParentTableMenu}
                  />
                </View>
              </View>
            </View>
            {tabItems.length === 1 && (
              <View style={{ marginTop: space }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontWeight: '600' }}>
                    {t('{{text}}の並び替え', { text: tabItems[0].label })}
                  </Text>
                  <Button
                    width={140}
                    height={48}
                    style={[
                      {
                        backgroundColor: isSortable ? Colors.primary : 'white',
                      },
                    ]}
                    onPress={() => {
                      if (isSortable) onPressUpdateItemOrder()
                      setIsSortable((prev) => !prev)
                    }}
                  >
                    <Text
                      style={{
                        color: !isSortable ? Colors.primary : 'white',
                        fontWeight: '600',
                      }}
                    >
                      {isSortable ? t('更新') : t('並び替え')}
                    </Text>
                  </Button>
                </View>
              </View>
            )}

            {tabItems.length > 1 && (
              <View>
                <View style={{ marginTop: space, flexDirection: 'column' }}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: width < sm ? 'column' : 'row',
                      justifyContent: 'space-between',
                      alignItems: width < sm ? 'flex-start' : 'center',
                    }}
                  >
                    <View
                      style={[
                        {
                          flex: 0.5,
                          flexDirection: width < sm ? 'column' : 'row',
                          justifyContent: 'space-between',
                          alignItems: width < sm ? 'flex-start' : 'center',
                        },
                      ]}
                    >
                      <Text style={{ fontWeight: '600' }}>{t('並び替え')}</Text>
                      <View
                        style={[
                          width >= sm && { flex: 1, marginLeft: 12 },
                          width < sm && {
                            width: 240,
                            marginTop: 12,
                          },
                        ]}
                      >
                        <SegmentedControl
                          buttons={tabItems.map((item) => {
                            return {
                              text: item.label,
                              selected: item.value === tabName,
                              onPress: () => {
                                setTabName(item.value)
                              },
                            }
                          })}
                        />
                      </View>
                    </View>
                    <Button
                      width={140}
                      height={48}
                      style={[
                        {
                          backgroundColor: isSortable
                            ? Colors.primary
                            : 'white',
                        },
                        width < sm && {
                          marginTop: 12,
                        },
                      ]}
                      textStyle={{
                        color: !isSortable ? Colors.primary : 'white',
                      }}
                      onPress={() => {
                        if (isSortable) onPressUpdateItemOrder()
                        setIsSortable((prev) => !prev)
                      }}
                    >
                      <Text
                        style={{
                          color: !isSortable ? Colors.primary : 'white',
                          fontWeight: '600',
                        }}
                      >
                        {isSortable ? t('更新') : t('並び替え')}
                      </Text>
                    </Button>
                  </View>
                </View>
              </View>
            )}
          </View>
        }
        ListFooterComponent={
          <View style={{ paddingHorizontal }}>
            <View style={{ flexDirection: 'row', marginTop: space }}>
              {tableMenuPageId != null && (
                <Button
                  height={56}
                  mode="outline"
                  variant="danger-secondary"
                  style={{
                    flex: 1,
                    marginRight: 32,
                  }}
                  onPress={() => alertArchive()}
                >
                  {t('アーカイブ')}
                </Button>
              )}
              <Button
                height={56}
                style={{
                  flex: 1,
                }}
                onPress={() => onPress()}
              >
                {tableMenuPageId != null ? t('更新する') : t('追加する')}
              </Button>
            </View>
          </View>
        }
        onReordered={(fromIndex, toIndex) => {
          if (tabName === 'item') {
            setData((prev) => {
              const removed = prev[fromIndex]
              return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
            })
          } else {
            setTableMenuPageIds((prev) => {
              const removed = prev[fromIndex]
              return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
            })
          }
        }}
      />
    </ScrollView>
  )
}
